@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-0.ttf');
    src: url('./game-icons-0.woff2') format('woff2'),
        url('./game-icons-0.ttf') format('truetype');
    unicode-range: U+FF000-FF07F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-1.ttf');
    src: url('./game-icons-1.woff2') format('woff2'),
        url('./game-icons-1.ttf') format('truetype');
    unicode-range: U+FF080-FF0FF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-2.ttf');
    src: url('./game-icons-2.woff2') format('woff2'),
        url('./game-icons-2.ttf') format('truetype');
    unicode-range: U+FF100-FF17F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-3.ttf');
    src: url('./game-icons-3.woff2') format('woff2'),
        url('./game-icons-3.ttf') format('truetype');
    unicode-range: U+FF180-FF1FF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-4.ttf');
    src: url('./game-icons-4.woff2') format('woff2'),
        url('./game-icons-4.ttf') format('truetype');
    unicode-range: U+FF200-FF27F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-5.ttf');
    src: url('./game-icons-5.woff2') format('woff2'),
        url('./game-icons-5.ttf') format('truetype');
    unicode-range: U+FF280-FF2FF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-6.ttf');
    src: url('./game-icons-6.woff2') format('woff2'),
        url('./game-icons-6.ttf') format('truetype');
    unicode-range: U+FF300-FF37F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-7.ttf');
    src: url('./game-icons-7.woff2') format('woff2'),
        url('./game-icons-7.ttf') format('truetype');
    unicode-range: U+FF380-FF3FF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-8.ttf');
    src: url('./game-icons-8.woff2') format('woff2'),
        url('./game-icons-8.ttf') format('truetype');
    unicode-range: U+FF400-FF47F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-9.ttf');
    src: url('./game-icons-9.woff2') format('woff2'),
        url('./game-icons-9.ttf') format('truetype');
    unicode-range: U+FF480-FF4FF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-10.ttf');
    src: url('./game-icons-10.woff2') format('woff2'),
        url('./game-icons-10.ttf') format('truetype');
    unicode-range: U+FF500-FF57F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-11.ttf');
    src: url('./game-icons-11.woff2') format('woff2'),
        url('./game-icons-11.ttf') format('truetype');
    unicode-range: U+FF580-FF5FF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-12.ttf');
    src: url('./game-icons-12.woff2') format('woff2'),
        url('./game-icons-12.ttf') format('truetype');
    unicode-range: U+FF600-FF67F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-13.ttf');
    src: url('./game-icons-13.woff2') format('woff2'),
        url('./game-icons-13.ttf') format('truetype');
    unicode-range: U+FF680-FF6FF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-14.ttf');
    src: url('./game-icons-14.woff2') format('woff2'),
        url('./game-icons-14.ttf') format('truetype');
    unicode-range: U+FF700-FF77F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-15.ttf');
    src: url('./game-icons-15.woff2') format('woff2'),
        url('./game-icons-15.ttf') format('truetype');
    unicode-range: U+FF780-FF7FF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-16.ttf');
    src: url('./game-icons-16.woff2') format('woff2'),
        url('./game-icons-16.ttf') format('truetype');
    unicode-range: U+FF800-FF87F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-17.ttf');
    src: url('./game-icons-17.woff2') format('woff2'),
        url('./game-icons-17.ttf') format('truetype');
    unicode-range: U+FF880-FF8FF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-18.ttf');
    src: url('./game-icons-18.woff2') format('woff2'),
        url('./game-icons-18.ttf') format('truetype');
    unicode-range: U+FF900-FF97F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-19.ttf');
    src: url('./game-icons-19.woff2') format('woff2'),
        url('./game-icons-19.ttf') format('truetype');
    unicode-range: U+FF980-FF9FF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-20.ttf');
    src: url('./game-icons-20.woff2') format('woff2'),
        url('./game-icons-20.ttf') format('truetype');
    unicode-range: U+FFA00-FFA7F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-21.ttf');
    src: url('./game-icons-21.woff2') format('woff2'),
        url('./game-icons-21.ttf') format('truetype');
    unicode-range: U+FFA80-FFAFF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-22.ttf');
    src: url('./game-icons-22.woff2') format('woff2'),
        url('./game-icons-22.ttf') format('truetype');
    unicode-range: U+FFB00-FFB7F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-23.ttf');
    src: url('./game-icons-23.woff2') format('woff2'),
        url('./game-icons-23.ttf') format('truetype');
    unicode-range: U+FFB80-FFBFF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-24.ttf');
    src: url('./game-icons-24.woff2') format('woff2'),
        url('./game-icons-24.ttf') format('truetype');
    unicode-range: U+FFC00-FFC7F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-25.ttf');
    src: url('./game-icons-25.woff2') format('woff2'),
        url('./game-icons-25.ttf') format('truetype');
    unicode-range: U+FFC80-FFCFF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-26.ttf');
    src: url('./game-icons-26.woff2') format('woff2'),
        url('./game-icons-26.ttf') format('truetype');
    unicode-range: U+FFD00-FFD7F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-27.ttf');
    src: url('./game-icons-27.woff2') format('woff2'),
        url('./game-icons-27.ttf') format('truetype');
    unicode-range: U+FFD80-FFDFF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-28.ttf');
    src: url('./game-icons-28.woff2') format('woff2'),
        url('./game-icons-28.ttf') format('truetype');
    unicode-range: U+FFE00-FFE7F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-29.ttf');
    src: url('./game-icons-29.woff2') format('woff2'),
        url('./game-icons-29.ttf') format('truetype');
    unicode-range: U+FFE80-FFEFF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-30.ttf');
    src: url('./game-icons-30.woff2') format('woff2'),
        url('./game-icons-30.ttf') format('truetype');
    unicode-range: U+FFF00-FFF7F, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-31.ttf');
    src: url('./game-icons-31.woff2') format('woff2'),
        url('./game-icons-31.ttf') format('truetype');
    unicode-range: U+FFF80-FFFFF, U+20;
}
@font-face {
    font-family: "game-icons";
    font-weight: normal;
    font-style: normal;
    src: url('./game-icons-32.ttf');
    src: url('./game-icons-32.woff2') format('woff2'),
        url('./game-icons-32.ttf') format('truetype');
    unicode-range: U+100000-10000C, U+20;
}

.game-icon {
    font-family: "game-icons";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
}
.game-icon-police-badge:before {
    content: "\FF000";
}
.game-icon-card-10-clubs:before {
    content: "\FF001";
}
.game-icon-card-10-diamonds:before {
    content: "\FF002";
}
.game-icon-card-10-hearts:before {
    content: "\FF003";
}
.game-icon-card-10-spades:before {
    content: "\FF004";
}
.game-icon-card-2-clubs:before {
    content: "\FF005";
}
.game-icon-card-2-diamonds:before {
    content: "\FF006";
}
.game-icon-card-2-hearts:before {
    content: "\FF007";
}
.game-icon-card-2-spades:before {
    content: "\FF008";
}
.game-icon-card-3-clubs:before {
    content: "\FF009";
}
.game-icon-card-3-diamonds:before {
    content: "\FF00A";
}
.game-icon-card-3-hearts:before {
    content: "\FF00B";
}
.game-icon-card-3-spades:before {
    content: "\FF00C";
}
.game-icon-card-4-clubs:before {
    content: "\FF00D";
}
.game-icon-card-4-diamonds:before {
    content: "\FF00E";
}
.game-icon-card-4-hearts:before {
    content: "\FF00F";
}
.game-icon-card-4-spades:before {
    content: "\FF010";
}
.game-icon-card-5-clubs:before {
    content: "\FF011";
}
.game-icon-card-5-diamonds:before {
    content: "\FF012";
}
.game-icon-card-5-hearts:before {
    content: "\FF013";
}
.game-icon-card-5-spades:before {
    content: "\FF014";
}
.game-icon-card-6-clubs:before {
    content: "\FF015";
}
.game-icon-card-6-diamonds:before {
    content: "\FF016";
}
.game-icon-card-6-hearts:before {
    content: "\FF017";
}
.game-icon-card-6-spades:before {
    content: "\FF018";
}
.game-icon-card-7-clubs:before {
    content: "\FF019";
}
.game-icon-card-7-diamonds:before {
    content: "\FF01A";
}
.game-icon-card-7-hearts:before {
    content: "\FF01B";
}
.game-icon-card-7-spades:before {
    content: "\FF01C";
}
.game-icon-card-8-clubs:before {
    content: "\FF01D";
}
.game-icon-card-8-diamonds:before {
    content: "\FF01E";
}
.game-icon-card-8-hearts:before {
    content: "\FF01F";
}
.game-icon-card-8-spades:before {
    content: "\FF020";
}
.game-icon-card-9-clubs:before {
    content: "\FF021";
}
.game-icon-card-9-diamonds:before {
    content: "\FF022";
}
.game-icon-card-9-hearts:before {
    content: "\FF023";
}
.game-icon-card-9-spades:before {
    content: "\FF024";
}
.game-icon-card-ace-clubs:before {
    content: "\FF025";
}
.game-icon-card-ace-diamonds:before {
    content: "\FF026";
}
.game-icon-card-ace-hearts:before {
    content: "\FF027";
}
.game-icon-card-ace-spades:before {
    content: "\FF028";
}
.game-icon-card-jack-clubs:before {
    content: "\FF029";
}
.game-icon-card-jack-diamonds:before {
    content: "\FF02A";
}
.game-icon-card-jack-hearts:before {
    content: "\FF02B";
}
.game-icon-card-jack-spades:before {
    content: "\FF02C";
}
.game-icon-card-king-clubs:before {
    content: "\FF02D";
}
.game-icon-card-king-diamonds:before {
    content: "\FF02E";
}
.game-icon-card-king-hearts:before {
    content: "\FF02F";
}
.game-icon-card-king-spades:before {
    content: "\FF030";
}
.game-icon-card-queen-clubs:before {
    content: "\FF031";
}
.game-icon-card-queen-diamonds:before {
    content: "\FF032";
}
.game-icon-card-queen-hearts:before {
    content: "\FF033";
}
.game-icon-card-queen-spades:before {
    content: "\FF034";
}
.game-icon-brutal-helm:before {
    content: "\FF035";
}
.game-icon-crossbow:before {
    content: "\FF036";
}
.game-icon-flame:before {
    content: "\FF037";
}
.game-icon-mite-alt:before {
    content: "\FF038";
}
.game-icon-spider-alt:before {
    content: "\FF039";
}
.game-icon-spider-face:before {
    content: "\FF03A";
}
.game-icon-accordion:before {
    content: "\FF03B";
}
.game-icon-anteater:before {
    content: "\FF03C";
}
.game-icon-archaeopteryx-fossil:before {
    content: "\FF03D";
}
.game-icon-astrolabe:before {
    content: "\FF03E";
}
.game-icon-axolotl:before {
    content: "\FF03F";
}
.game-icon-bassoon:before {
    content: "\FF040";
}
.game-icon-birch-trees:before {
    content: "\FF041";
}
.game-icon-blender:before {
    content: "\FF042";
}
.game-icon-boar:before {
    content: "\FF043";
}
.game-icon-boba:before {
    content: "\FF044";
}
.game-icon-bok-choy:before {
    content: "\FF045";
}
.game-icon-bowl-of-rice:before {
    content: "\FF046";
}
.game-icon-bunny-slippers:before {
    content: "\FF047";
}
.game-icon-bus-stop:before {
    content: "\FF048";
}
.game-icon-capybara:before {
    content: "\FF049";
}
.game-icon-charcuterie:before {
    content: "\FF04A";
}
.game-icon-clarinet:before {
    content: "\FF04B";
}
.game-icon-clothesline:before {
    content: "\FF04C";
}
.game-icon-coinflip:before {
    content: "\FF04D";
}
.game-icon-cootie-catcher:before {
    content: "\FF04E";
}
.game-icon-deer-head:before {
    content: "\FF04F";
}
.game-icon-deer:before {
    content: "\FF050";
}
.game-icon-drum-kit:before {
    content: "\FF051";
}
.game-icon-dumpling-bao:before {
    content: "\FF052";
}
.game-icon-dumpling:before {
    content: "\FF053";
}
.game-icon-earbuds:before {
    content: "\FF054";
}
.game-icon-easel:before {
    content: "\FF055";
}
.game-icon-fox:before {
    content: "\FF056";
}
.game-icon-french-horn:before {
    content: "\FF057";
}
.game-icon-froe-and-mallet:before {
    content: "\FF058";
}
.game-icon-grand-piano:before {
    content: "\FF059";
}
.game-icon-hedgehog:before {
    content: "\FF05A";
}
.game-icon-heraldic-sun:before {
    content: "\FF05B";
}
.game-icon-heron:before {
    content: "\FF05C";
}
.game-icon-hyena-head:before {
    content: "\FF05D";
}
.game-icon-mason-jar:before {
    content: "\FF05E";
}
.game-icon-mountain-climbing:before {
    content: "\FF05F";
}
.game-icon-pangolin:before {
    content: "\FF060";
}
.game-icon-paper-bag-crumpled:before {
    content: "\FF061";
}
.game-icon-paper-bag-folded:before {
    content: "\FF062";
}
.game-icon-paper-bag-open:before {
    content: "\FF063";
}
.game-icon-paper-crane:before {
    content: "\FF064";
}
.game-icon-paper-frog:before {
    content: "\FF065";
}
.game-icon-pianist:before {
    content: "\FF066";
}
.game-icon-pipe-organ:before {
    content: "\FF067";
}
.game-icon-porcupine:before {
    content: "\FF068";
}
.game-icon-pressure-cooker:before {
    content: "\FF069";
}
.game-icon-rice-cooker:before {
    content: "\FF06A";
}
.game-icon-rolling-suitcase:before {
    content: "\FF06B";
}
.game-icon-rotary-phone:before {
    content: "\FF06C";
}
.game-icon-saucepan:before {
    content: "\FF06D";
}
.game-icon-sauropod-skeleton:before {
    content: "\FF06E";
}
.game-icon-sewing-machine:before {
    content: "\FF06F";
}
.game-icon-sloth:before {
    content: "\FF070";
}
.game-icon-soldering-iron:before {
    content: "\FF071";
}
.game-icon-spinning-wheel:before {
    content: "\FF072";
}
.game-icon-stapler-heavy-duty:before {
    content: "\FF073";
}
.game-icon-stapler-pneumatic:before {
    content: "\FF074";
}
.game-icon-stapler:before {
    content: "\FF075";
}
.game-icon-toothbrush:before {
    content: "\FF076";
}
.game-icon-treehouse:before {
    content: "\FF077";
}
.game-icon-trombone:before {
    content: "\FF078";
}
.game-icon-tuba:before {
    content: "\FF079";
}
.game-icon-venus-flytrap:before {
    content: "\FF07A";
}
.game-icon-vhs:before {
    content: "\FF07B";
}
.game-icon-water-mill:before {
    content: "\FF07C";
}
.game-icon-willow-tree:before {
    content: "\FF07D";
}
.game-icon-windchimes:before {
    content: "\FF07E";
}
.game-icon-wok:before {
    content: "\FF07F";
}
.game-icon-annexation:before {
    content: "\FF080";
}
.game-icon-bad-gnome:before {
    content: "\FF081";
}
.game-icon-battleship:before {
    content: "\FF082";
}
.game-icon-boar-ensign:before {
    content: "\FF083";
}
.game-icon-carnyx:before {
    content: "\FF084";
}
.game-icon-carrier:before {
    content: "\FF085";
}
.game-icon-chariot:before {
    content: "\FF086";
}
.game-icon-cruiser:before {
    content: "\FF087";
}
.game-icon-dread:before {
    content: "\FF088";
}
.game-icon-dreadnought:before {
    content: "\FF089";
}
.game-icon-earth-worm:before {
    content: "\FF08A";
}
.game-icon-flying-trout:before {
    content: "\FF08B";
}
.game-icon-fomorian:before {
    content: "\FF08C";
}
.game-icon-great-war-tank:before {
    content: "\FF08D";
}
.game-icon-holy-oak:before {
    content: "\FF08E";
}
.game-icon-iron-hulled-warship:before {
    content: "\FF08F";
}
.game-icon-medusa-head:before {
    content: "\FF090";
}
.game-icon-nun-face:before {
    content: "\FF091";
}
.game-icon-occupy:before {
    content: "\FF092";
}
.game-icon-old-king:before {
    content: "\FF093";
}
.game-icon-oppidum:before {
    content: "\FF094";
}
.game-icon-polar-bear:before {
    content: "\FF095";
}
.game-icon-swordman:before {
    content: "\FF096";
}
.game-icon-swordwoman:before {
    content: "\FF097";
}
.game-icon-torc:before {
    content: "\FF098";
}
.game-icon-transparent-slime:before {
    content: "\FF099";
}
.game-icon-tree-face:before {
    content: "\FF09A";
}
.game-icon-tumulus:before {
    content: "\FF09B";
}
.game-icon-witch-face:before {
    content: "\FF09C";
}
.game-icon-broken-arrow:before {
    content: "\FF09D";
}
.game-icon-acrobatic:before {
    content: "\FF09E";
}
.game-icon-apothecary:before {
    content: "\FF09F";
}
.game-icon-big-gear:before {
    content: "\FF0A0";
}
.game-icon-catch:before {
    content: "\FF0A1";
}
.game-icon-cauldron:before {
    content: "\FF0A2";
}
.game-icon-chameleon-glyph:before {
    content: "\FF0A3";
}
.game-icon-death-juice:before {
    content: "\FF0A4";
}
.game-icon-distraction:before {
    content: "\FF0A5";
}
.game-icon-fish-cooked:before {
    content: "\FF0A6";
}
.game-icon-flying-shuriken:before {
    content: "\FF0A7";
}
.game-icon-fried-fish:before {
    content: "\FF0A8";
}
.game-icon-hooded-assassin:before {
    content: "\FF0A9";
}
.game-icon-hooded-figure:before {
    content: "\FF0AA";
}
.game-icon-kusarigama:before {
    content: "\FF0AB";
}
.game-icon-ninja-head:before {
    content: "\FF0AC";
}
.game-icon-ninja-heroic-stance:before {
    content: "\FF0AD";
}
.game-icon-north-star-shuriken:before {
    content: "\FF0AE";
}
.game-icon-on-sight:before {
    content: "\FF0AF";
}
.game-icon-plants-and-animals:before {
    content: "\FF0B0";
}
.game-icon-ram:before {
    content: "\FF0B1";
}
.game-icon-revolt:before {
    content: "\FF0B2";
}
.game-icon-robber-hand:before {
    content: "\FF0B3";
}
.game-icon-running-ninja:before {
    content: "\FF0B4";
}
.game-icon-severed-hand:before {
    content: "\FF0B5";
}
.game-icon-sharp-shuriken:before {
    content: "\FF0B6";
}
.game-icon-shuriken:before {
    content: "\FF0B7";
}
.game-icon-skull-sabertooth:before {
    content: "\FF0B8";
}
.game-icon-smoke-bomb:before {
    content: "\FF0B9";
}
.game-icon-star-shuriken:before {
    content: "\FF0BA";
}
.game-icon-tabi-boot:before {
    content: "\FF0BB";
}
.game-icon-three-pointed-shuriken:before {
    content: "\FF0BC";
}
.game-icon-whirlpool-shuriken:before {
    content: "\FF0BD";
}
.game-icon-3d-glasses:before {
    content: "\FF0BE";
}
.game-icon-3d-hammer:before {
    content: "\FF0BF";
}
.game-icon-3d-meeple:before {
    content: "\FF0C0";
}
.game-icon-3d-stairs:before {
    content: "\FF0C1";
}
.game-icon-abacus:before {
    content: "\FF0C2";
}
.game-icon-abbot-meeple:before {
    content: "\FF0C3";
}
.game-icon-abdominal-armor:before {
    content: "\FF0C4";
}
.game-icon-achilles-heel:before {
    content: "\FF0C5";
}
.game-icon-acoustic-megaphone:before {
    content: "\FF0C6";
}
.game-icon-africa:before {
    content: "\FF0C7";
}
.game-icon-agave:before {
    content: "\FF0C8";
}
.game-icon-ages:before {
    content: "\FF0C9";
}
.game-icon-air-balloon:before {
    content: "\FF0CA";
}
.game-icon-air-man:before {
    content: "\FF0CB";
}
.game-icon-airplane-arrival:before {
    content: "\FF0CC";
}
.game-icon-airplane-departure:before {
    content: "\FF0CD";
}
.game-icon-airtight-hatch:before {
    content: "\FF0CE";
}
.game-icon-akhet:before {
    content: "\FF0CF";
}
.game-icon-alarm-clock:before {
    content: "\FF0D0";
}
.game-icon-algae:before {
    content: "\FF0D1";
}
.game-icon-algeria:before {
    content: "\FF0D2";
}
.game-icon-alien-bug:before {
    content: "\FF0D3";
}
.game-icon-alien-egg:before {
    content: "\FF0D4";
}
.game-icon-all-seeing-eye:before {
    content: "\FF0D5";
}
.game-icon-almond:before {
    content: "\FF0D6";
}
.game-icon-amber-mosquito:before {
    content: "\FF0D7";
}
.game-icon-ambulance:before {
    content: "\FF0D8";
}
.game-icon-american-football-ball:before {
    content: "\FF0D9";
}
.game-icon-american-football-helmet:before {
    content: "\FF0DA";
}
.game-icon-american-football-player:before {
    content: "\FF0DB";
}
.game-icon-american-shield:before {
    content: "\FF0DC";
}
.game-icon-amphora:before {
    content: "\FF0DD";
}
.game-icon-ample-dress:before {
    content: "\FF0DE";
}
.game-icon-anarchy:before {
    content: "\FF0DF";
}
.game-icon-ancient-columns:before {
    content: "\FF0E0";
}
.game-icon-ancient-ruins:before {
    content: "\FF0E1";
}
.game-icon-ancient-screw:before {
    content: "\FF0E2";
}
.game-icon-ancient-sword:before {
    content: "\FF0E3";
}
.game-icon-angola:before {
    content: "\FF0E4";
}
.game-icon-angry-eyes:before {
    content: "\FF0E5";
}
.game-icon-animal-hide:before {
    content: "\FF0E6";
}
.game-icon-ant:before {
    content: "\FF0E7";
}
.game-icon-antarctica:before {
    content: "\FF0E8";
}
.game-icon-antibody:before {
    content: "\FF0E9";
}
.game-icon-anticlockwise-rotation:before {
    content: "\FF0EA";
}
.game-icon-ants:before {
    content: "\FF0EB";
}
.game-icon-anubis:before {
    content: "\FF0EC";
}
.game-icon-apollo-capsule:before {
    content: "\FF0ED";
}
.game-icon-apple-core:before {
    content: "\FF0EE";
}
.game-icon-aquarium:before {
    content: "\FF0EF";
}
.game-icon-aquarius:before {
    content: "\FF0F0";
}
.game-icon-aqueduct:before {
    content: "\FF0F1";
}
.game-icon-arabic-door:before {
    content: "\FF0F2";
}
.game-icon-arc-triomphe:before {
    content: "\FF0F3";
}
.game-icon-arch-bridge:before {
    content: "\FF0F4";
}
.game-icon-archer:before {
    content: "\FF0F5";
}
.game-icon-archive-register:before {
    content: "\FF0F6";
}
.game-icon-archive-research:before {
    content: "\FF0F7";
}
.game-icon-aries:before {
    content: "\FF0F8";
}
.game-icon-arm-bandage:before {
    content: "\FF0F9";
}
.game-icon-arm-sling:before {
    content: "\FF0FA";
}
.game-icon-arm:before {
    content: "\FF0FB";
}
.game-icon-armadillo:before {
    content: "\FF0FC";
}
.game-icon-armor-downgrade:before {
    content: "\FF0FD";
}
.game-icon-armor-punch:before {
    content: "\FF0FE";
}
.game-icon-armor-upgrade:before {
    content: "\FF0FF";
}
.game-icon-armored-boomerang:before {
    content: "\FF100";
}
.game-icon-arrest:before {
    content: "\FF101";
}
.game-icon-arrow-cursor:before {
    content: "\FF102";
}
.game-icon-arrow-wings:before {
    content: "\FF103";
}
.game-icon-ascending-block:before {
    content: "\FF104";
}
.game-icon-asian-lantern:before {
    content: "\FF105";
}
.game-icon-asparagus:before {
    content: "\FF106";
}
.game-icon-aspergillum:before {
    content: "\FF107";
}
.game-icon-asteroid:before {
    content: "\FF108";
}
.game-icon-astronaut-helmet:before {
    content: "\FF109";
}
.game-icon-atlas:before {
    content: "\FF10A";
}
.game-icon-atom-core:before {
    content: "\FF10B";
}
.game-icon-attached-shield:before {
    content: "\FF10C";
}
.game-icon-audio-cassette:before {
    content: "\FF10D";
}
.game-icon-australia:before {
    content: "\FF10E";
}
.game-icon-avocado:before {
    content: "\FF10F";
}
.game-icon-avoidance:before {
    content: "\FF110";
}
.game-icon-axe-in-log:before {
    content: "\FF111";
}
.game-icon-axe-sword:before {
    content: "\FF112";
}
.game-icon-aztec-calendar-sun:before {
    content: "\FF113";
}
.game-icon-azul-flake:before {
    content: "\FF114";
}
.game-icon-baby-bottle:before {
    content: "\FF115";
}
.game-icon-baby-face:before {
    content: "\FF116";
}
.game-icon-babyfoot-players:before {
    content: "\FF117";
}
.game-icon-backgammon:before {
    content: "\FF118";
}
.game-icon-backpack:before {
    content: "\FF119";
}
.game-icon-backward-time:before {
    content: "\FF11A";
}
.game-icon-bacon:before {
    content: "\FF11B";
}
.game-icon-bad-breath:before {
    content: "\FF11C";
}
.game-icon-bagpipes:before {
    content: "\FF11D";
}
.game-icon-ball-pyramid:before {
    content: "\FF11E";
}
.game-icon-ballerina-shoes:before {
    content: "\FF11F";
}
.game-icon-balloon-dog:before {
    content: "\FF120";
}
.game-icon-bamboo-fountain:before {
    content: "\FF121";
}
.game-icon-bamboo:before {
    content: "\FF122";
}
.game-icon-banana-bunch:before {
    content: "\FF123";
}
.game-icon-banana-peel:before {
    content: "\FF124";
}
.game-icon-banana-peeled:before {
    content: "\FF125";
}
.game-icon-banana:before {
    content: "\FF126";
}
.game-icon-bandana:before {
    content: "\FF127";
}
.game-icon-bandit:before {
    content: "\FF128";
}
.game-icon-banging-gavel:before {
    content: "\FF129";
}
.game-icon-banjo:before {
    content: "\FF12A";
}
.game-icon-bank:before {
    content: "\FF12B";
}
.game-icon-banknote:before {
    content: "\FF12C";
}
.game-icon-baobab:before {
    content: "\FF12D";
}
.game-icon-bar-stool:before {
    content: "\FF12E";
}
.game-icon-barbarian:before {
    content: "\FF12F";
}
.game-icon-barbecue:before {
    content: "\FF130";
}
.game-icon-barn:before {
    content: "\FF131";
}
.game-icon-barracks-tent:before {
    content: "\FF132";
}
.game-icon-barracks:before {
    content: "\FF133";
}
.game-icon-barrel-leak:before {
    content: "\FF134";
}
.game-icon-barrel:before {
    content: "\FF135";
}
.game-icon-barricade:before {
    content: "\FF136";
}
.game-icon-barrier:before {
    content: "\FF137";
}
.game-icon-base-dome:before {
    content: "\FF138";
}
.game-icon-baseball-bat:before {
    content: "\FF139";
}
.game-icon-baseball-glove:before {
    content: "\FF13A";
}
.game-icon-basket:before {
    content: "\FF13B";
}
.game-icon-basketball-ball:before {
    content: "\FF13C";
}
.game-icon-basketball-basket:before {
    content: "\FF13D";
}
.game-icon-basketball-jersey:before {
    content: "\FF13E";
}
.game-icon-basset-hound-head:before {
    content: "\FF13F";
}
.game-icon-bastet:before {
    content: "\FF140";
}
.game-icon-bat-leth:before {
    content: "\FF141";
}
.game-icon-bat-mask:before {
    content: "\FF142";
}
.game-icon-bat:before {
    content: "\FF143";
}
.game-icon-bathtub:before {
    content: "\FF144";
}
.game-icon-battle-mech:before {
    content: "\FF145";
}
.game-icon-beach-bag:before {
    content: "\FF146";
}
.game-icon-beach-ball:before {
    content: "\FF147";
}
.game-icon-beach-bucket:before {
    content: "\FF148";
}
.game-icon-beam-satellite:before {
    content: "\FF149";
}
.game-icon-bear-head:before {
    content: "\FF14A";
}
.game-icon-beaver:before {
    content: "\FF14B";
}
.game-icon-bed-lamp:before {
    content: "\FF14C";
}
.game-icon-bed:before {
    content: "\FF14D";
}
.game-icon-beehive:before {
    content: "\FF14E";
}
.game-icon-beer-bottle:before {
    content: "\FF14F";
}
.game-icon-beer-horn:before {
    content: "\FF150";
}
.game-icon-beet:before {
    content: "\FF151";
}
.game-icon-belgium:before {
    content: "\FF152";
}
.game-icon-bell-pepper:before {
    content: "\FF153";
}
.game-icon-bellows:before {
    content: "\FF154";
}
.game-icon-belt-armor:before {
    content: "\FF155";
}
.game-icon-berries-bowl:before {
    content: "\FF156";
}
.game-icon-berry-bush:before {
    content: "\FF157";
}
.game-icon-biceps:before {
    content: "\FF158";
}
.game-icon-billed-cap:before {
    content: "\FF159";
}
.game-icon-bindle:before {
    content: "\FF15A";
}
.game-icon-binoculars:before {
    content: "\FF15B";
}
.game-icon-bird-cage:before {
    content: "\FF15C";
}
.game-icon-bird-house:before {
    content: "\FF15D";
}
.game-icon-bison:before {
    content: "\FF15E";
}
.game-icon-black-belt:before {
    content: "\FF15F";
}
.game-icon-black-bridge:before {
    content: "\FF160";
}
.game-icon-black-knight-helm:before {
    content: "\FF161";
}
.game-icon-black-sea:before {
    content: "\FF162";
}
.game-icon-blacksmith:before {
    content: "\FF163";
}
.game-icon-blindfold:before {
    content: "\FF164";
}
.game-icon-block-house:before {
    content: "\FF165";
}
.game-icon-bo:before {
    content: "\FF166";
}
.game-icon-boat-engine:before {
    content: "\FF167";
}
.game-icon-boat-fishing:before {
    content: "\FF168";
}
.game-icon-boat-horizon:before {
    content: "\FF169";
}
.game-icon-boat-propeller:before {
    content: "\FF16A";
}
.game-icon-body-balance:before {
    content: "\FF16B";
}
.game-icon-body-height:before {
    content: "\FF16C";
}
.game-icon-bolivia:before {
    content: "\FF16D";
}
.game-icon-bolt-bomb:before {
    content: "\FF16E";
}
.game-icon-bolt-cutter:before {
    content: "\FF16F";
}
.game-icon-bolt-drop:before {
    content: "\FF170";
}
.game-icon-bolt-spell-cast:before {
    content: "\FF171";
}
.game-icon-bone-knife:before {
    content: "\FF172";
}
.game-icon-bone-mace:before {
    content: "\FF173";
}
.game-icon-bonsai-tree:before {
    content: "\FF174";
}
.game-icon-book-cover:before {
    content: "\FF175";
}
.game-icon-book-pile:before {
    content: "\FF176";
}
.game-icon-bookshelf:before {
    content: "\FF177";
}
.game-icon-boombox:before {
    content: "\FF178";
}
.game-icon-boomerang-cross:before {
    content: "\FF179";
}
.game-icon-boomerang:before {
    content: "\FF17A";
}
.game-icon-booze:before {
    content: "\FF17B";
}
.game-icon-boss-key:before {
    content: "\FF17C";
}
.game-icon-bottle-cap:before {
    content: "\FF17D";
}
.game-icon-bottled-shadow:before {
    content: "\FF17E";
}
.game-icon-boulder-dash:before {
    content: "\FF17F";
}
.game-icon-bouncing-spring:before {
    content: "\FF180";
}
.game-icon-bow-arrow:before {
    content: "\FF181";
}
.game-icon-bow-string:before {
    content: "\FF182";
}
.game-icon-bow-tie-ribbon:before {
    content: "\FF183";
}
.game-icon-bow-tie:before {
    content: "\FF184";
}
.game-icon-bowels:before {
    content: "\FF185";
}
.game-icon-bowen-knot:before {
    content: "\FF186";
}
.game-icon-bowling-alley:before {
    content: "\FF187";
}
.game-icon-bowling-pin:before {
    content: "\FF188";
}
.game-icon-bowling-strike:before {
    content: "\FF189";
}
.game-icon-box-cutter:before {
    content: "\FF18A";
}
.game-icon-box-unpacking:before {
    content: "\FF18B";
}
.game-icon-boxing-ring:before {
    content: "\FF18C";
}
.game-icon-bracer:before {
    content: "\FF18D";
}
.game-icon-brain-dump:before {
    content: "\FF18E";
}
.game-icon-brain-leak:before {
    content: "\FF18F";
}
.game-icon-brain-tentacle:before {
    content: "\FF190";
}
.game-icon-brasero:before {
    content: "\FF191";
}
.game-icon-brass-knuckles:before {
    content: "\FF192";
}
.game-icon-brazil-flag:before {
    content: "\FF193";
}
.game-icon-brazil:before {
    content: "\FF194";
}
.game-icon-bread-slice:before {
    content: "\FF195";
}
.game-icon-bread:before {
    content: "\FF196";
}
.game-icon-brick-pile:before {
    content: "\FF197";
}
.game-icon-brick-wall:before {
    content: "\FF198";
}
.game-icon-briefcase:before {
    content: "\FF199";
}
.game-icon-broccoli:before {
    content: "\FF19A";
}
.game-icon-broken-axe:before {
    content: "\FF19B";
}
.game-icon-broken-pottery:before {
    content: "\FF19C";
}
.game-icon-broken-wall:before {
    content: "\FF19D";
}
.game-icon-broom:before {
    content: "\FF19E";
}
.game-icon-brute:before {
    content: "\FF19F";
}
.game-icon-buffalo-head:before {
    content: "\FF1A0";
}
.game-icon-bug-net:before {
    content: "\FF1A1";
}
.game-icon-bulgaria:before {
    content: "\FF1A2";
}
.game-icon-bulldozer:before {
    content: "\FF1A3";
}
.game-icon-bullet-bill:before {
    content: "\FF1A4";
}
.game-icon-bullet-impacts:before {
    content: "\FF1A5";
}
.game-icon-bully-minion:before {
    content: "\FF1A6";
}
.game-icon-bunk-beds:before {
    content: "\FF1A7";
}
.game-icon-buoy:before {
    content: "\FF1A8";
}
.game-icon-burning-forest:before {
    content: "\FF1A9";
}
.game-icon-bus-doors:before {
    content: "\FF1AA";
}
.game-icon-bus:before {
    content: "\FF1AB";
}
.game-icon-butter-toast:before {
    content: "\FF1AC";
}
.game-icon-butter:before {
    content: "\FF1AD";
}
.game-icon-butterfly-flower:before {
    content: "\FF1AE";
}
.game-icon-butterfly-knife:before {
    content: "\FF1AF";
}
.game-icon-button-finger:before {
    content: "\FF1B0";
}
.game-icon-buy-card:before {
    content: "\FF1B1";
}
.game-icon-byzantin-temple:before {
    content: "\FF1B2";
}
.game-icon-cabbage:before {
    content: "\FF1B3";
}
.game-icon-cable-stayed-bridge:before {
    content: "\FF1B4";
}
.game-icon-cactus-pot:before {
    content: "\FF1B5";
}
.game-icon-cactus-tap:before {
    content: "\FF1B6";
}
.game-icon-cactus:before {
    content: "\FF1B7";
}
.game-icon-cadillac-helm:before {
    content: "\FF1B8";
}
.game-icon-caduceus:before {
    content: "\FF1B9";
}
.game-icon-caesar:before {
    content: "\FF1BA";
}
.game-icon-cage:before {
    content: "\FF1BB";
}
.game-icon-calavera:before {
    content: "\FF1BC";
}
.game-icon-calculator:before {
    content: "\FF1BD";
}
.game-icon-calendar-half-year:before {
    content: "\FF1BE";
}
.game-icon-calendar:before {
    content: "\FF1BF";
}
.game-icon-caltrops:before {
    content: "\FF1C0";
}
.game-icon-camargue-cross:before {
    content: "\FF1C1";
}
.game-icon-cambodia:before {
    content: "\FF1C2";
}
.game-icon-camel-head:before {
    content: "\FF1C3";
}
.game-icon-camel:before {
    content: "\FF1C4";
}
.game-icon-camp-cooking-pot:before {
    content: "\FF1C5";
}
.game-icon-camping-tent:before {
    content: "\FF1C6";
}
.game-icon-cancer:before {
    content: "\FF1C7";
}
.game-icon-candles:before {
    content: "\FF1C8";
}
.game-icon-candlestick-phone:before {
    content: "\FF1C9";
}
.game-icon-candy-canes:before {
    content: "\FF1CA";
}
.game-icon-canned-fish:before {
    content: "\FF1CB";
}
.game-icon-canoe:before {
    content: "\FF1CC";
}
.game-icon-cantua:before {
    content: "\FF1CD";
}
.game-icon-cape-armor:before {
    content: "\FF1CE";
}
.game-icon-cape:before {
    content: "\FF1CF";
}
.game-icon-capricorn:before {
    content: "\FF1D0";
}
.game-icon-captain-hat-profile:before {
    content: "\FF1D1";
}
.game-icon-car-battery:before {
    content: "\FF1D2";
}
.game-icon-car-door:before {
    content: "\FF1D3";
}
.game-icon-car-key:before {
    content: "\FF1D4";
}
.game-icon-car-seat:before {
    content: "\FF1D5";
}
.game-icon-car-wheel:before {
    content: "\FF1D6";
}
.game-icon-carabiner:before {
    content: "\FF1D7";
}
.game-icon-carambola:before {
    content: "\FF1D8";
}
.game-icon-caravan:before {
    content: "\FF1D9";
}
.game-icon-caravel:before {
    content: "\FF1DA";
}
.game-icon-card-burn:before {
    content: "\FF1DB";
}
.game-icon-card-exchange:before {
    content: "\FF1DC";
}
.game-icon-card-joker:before {
    content: "\FF1DD";
}
.game-icon-cardboard-box-closed:before {
    content: "\FF1DE";
}
.game-icon-cardboard-box:before {
    content: "\FF1DF";
}
.game-icon-cargo-crate:before {
    content: "\FF1E0";
}
.game-icon-cargo-ship:before {
    content: "\FF1E1";
}
.game-icon-carnival-mask:before {
    content: "\FF1E2";
}
.game-icon-carnivorous-plant:before {
    content: "\FF1E3";
}
.game-icon-carousel:before {
    content: "\FF1E4";
}
.game-icon-carrot:before {
    content: "\FF1E5";
}
.game-icon-cassowary-head:before {
    content: "\FF1E6";
}
.game-icon-castle-ruins:before {
    content: "\FF1E7";
}
.game-icon-castle:before {
    content: "\FF1E8";
}
.game-icon-caterpillar:before {
    content: "\FF1E9";
}
.game-icon-cavalry:before {
    content: "\FF1EA";
}
.game-icon-cave-entrance:before {
    content: "\FF1EB";
}
.game-icon-caveman:before {
    content: "\FF1EC";
}
.game-icon-cctv-camera:before {
    content: "\FF1ED";
}
.game-icon-ceiling-barnacle:before {
    content: "\FF1EE";
}
.game-icon-ceiling-light:before {
    content: "\FF1EF";
}
.game-icon-cellar-barrels:before {
    content: "\FF1F0";
}
.game-icon-centaur-heart:before {
    content: "\FF1F1";
}
.game-icon-centaur:before {
    content: "\FF1F2";
}
.game-icon-centurion-helmet:before {
    content: "\FF1F3";
}
.game-icon-ceremonial-mask:before {
    content: "\FF1F4";
}
.game-icon-chainsaw:before {
    content: "\FF1F5";
}
.game-icon-chakram:before {
    content: "\FF1F6";
}
.game-icon-chalk-outline-murder:before {
    content: "\FF1F7";
}
.game-icon-champagne-cork:before {
    content: "\FF1F8";
}
.game-icon-champions:before {
    content: "\FF1F9";
}
.game-icon-chanterelles:before {
    content: "\FF1FA";
}
.game-icon-character:before {
    content: "\FF1FB";
}
.game-icon-charging-bull:before {
    content: "\FF1FC";
}
.game-icon-charging:before {
    content: "\FF1FD";
}
.game-icon-chart:before {
    content: "\FF1FE";
}
.game-icon-chat-bubble:before {
    content: "\FF1FF";
}
.game-icon-check-mark:before {
    content: "\FF200";
}
.game-icon-checkered-flag:before {
    content: "\FF201";
}
.game-icon-checklist:before {
    content: "\FF202";
}
.game-icon-chef-toque:before {
    content: "\FF203";
}
.game-icon-chelsea-boot:before {
    content: "\FF204";
}
.game-icon-cherish:before {
    content: "\FF205";
}
.game-icon-cherry:before {
    content: "\FF206";
}
.game-icon-chest-armor:before {
    content: "\FF207";
}
.game-icon-chest:before {
    content: "\FF208";
}
.game-icon-chestnut-leaf:before {
    content: "\FF209";
}
.game-icon-chicken-oven:before {
    content: "\FF20A";
}
.game-icon-chicken:before {
    content: "\FF20B";
}
.game-icon-chili-pepper:before {
    content: "\FF20C";
}
.game-icon-chimney:before {
    content: "\FF20D";
}
.game-icon-chips-bag:before {
    content: "\FF20E";
}
.game-icon-chisel:before {
    content: "\FF20F";
}
.game-icon-choice:before {
    content: "\FF210";
}
.game-icon-chopsticks:before {
    content: "\FF211";
}
.game-icon-church:before {
    content: "\FF212";
}
.game-icon-cigale:before {
    content: "\FF213";
}
.game-icon-cigar:before {
    content: "\FF214";
}
.game-icon-cigarette:before {
    content: "\FF215";
}
.game-icon-circle-forest:before {
    content: "\FF216";
}
.game-icon-circle:before {
    content: "\FF217";
}
.game-icon-circling-fish:before {
    content: "\FF218";
}
.game-icon-city-car:before {
    content: "\FF219";
}
.game-icon-clamp:before {
    content: "\FF21A";
}
.game-icon-clapperboard:before {
    content: "\FF21B";
}
.game-icon-classical-knowledge:before {
    content: "\FF21C";
}
.game-icon-claws:before {
    content: "\FF21D";
}
.game-icon-clay-brick:before {
    content: "\FF21E";
}
.game-icon-claymore-explosive:before {
    content: "\FF21F";
}
.game-icon-cleaver:before {
    content: "\FF220";
}
.game-icon-cleopatra:before {
    content: "\FF221";
}
.game-icon-cliff-crossing:before {
    content: "\FF222";
}
.game-icon-clockwise-rotation:before {
    content: "\FF223";
}
.game-icon-closed-barbute:before {
    content: "\FF224";
}
.game-icon-closed-doors:before {
    content: "\FF225";
}
.game-icon-cloth-jar:before {
    content: "\FF226";
}
.game-icon-clothes:before {
    content: "\FF227";
}
.game-icon-clothespin:before {
    content: "\FF228";
}
.game-icon-cloud-download:before {
    content: "\FF229";
}
.game-icon-cloud-upload:before {
    content: "\FF22A";
}
.game-icon-clown:before {
    content: "\FF22B";
}
.game-icon-clownfish:before {
    content: "\FF22C";
}
.game-icon-coa-de-jima:before {
    content: "\FF22D";
}
.game-icon-coal-pile:before {
    content: "\FF22E";
}
.game-icon-coal-wagon:before {
    content: "\FF22F";
}
.game-icon-cobra:before {
    content: "\FF230";
}
.game-icon-coconuts:before {
    content: "\FF231";
}
.game-icon-coffee-beans:before {
    content: "\FF232";
}
.game-icon-coffee-cup:before {
    content: "\FF233";
}
.game-icon-coffee-pot:before {
    content: "\FF234";
}
.game-icon-coiled-nail:before {
    content: "\FF235";
}
.game-icon-coins-pile:before {
    content: "\FF236";
}
.game-icon-coins:before {
    content: "\FF237";
}
.game-icon-coliseum:before {
    content: "\FF238";
}
.game-icon-colombia:before {
    content: "\FF239";
}
.game-icon-colombian-statue:before {
    content: "\FF23A";
}
.game-icon-column-vase:before {
    content: "\FF23B";
}
.game-icon-commercial-airplane:before {
    content: "\FF23C";
}
.game-icon-compact-disc:before {
    content: "\FF23D";
}
.game-icon-companion-cube:before {
    content: "\FF23E";
}
.game-icon-computer-fan:before {
    content: "\FF23F";
}
.game-icon-concrete-bag:before {
    content: "\FF240";
}
.game-icon-confirmed:before {
    content: "\FF241";
}
.game-icon-confrontation:before {
    content: "\FF242";
}
.game-icon-congress:before {
    content: "\FF243";
}
.game-icon-conqueror:before {
    content: "\FF244";
}
.game-icon-contortionist:before {
    content: "\FF245";
}
.game-icon-contract:before {
    content: "\FF246";
}
.game-icon-control-tower:before {
    content: "\FF247";
}
.game-icon-convergence-target:before {
    content: "\FF248";
}
.game-icon-converse-shoe:before {
    content: "\FF249";
}
.game-icon-convict:before {
    content: "\FF24A";
}
.game-icon-convince:before {
    content: "\FF24B";
}
.game-icon-conway-life-glider:before {
    content: "\FF24C";
}
.game-icon-cook:before {
    content: "\FF24D";
}
.game-icon-cookie:before {
    content: "\FF24E";
}
.game-icon-cooking-glove:before {
    content: "\FF24F";
}
.game-icon-cooking-pot:before {
    content: "\FF250";
}
.game-icon-cooler:before {
    content: "\FF251";
}
.game-icon-coral:before {
    content: "\FF252";
}
.game-icon-cork-hat:before {
    content: "\FF253";
}
.game-icon-corkscrew:before {
    content: "\FF254";
}
.game-icon-corn:before {
    content: "\FF255";
}
.game-icon-corner-flag:before {
    content: "\FF256";
}
.game-icon-cornucopia:before {
    content: "\FF257";
}
.game-icon-coronation:before {
    content: "\FF258";
}
.game-icon-corporal:before {
    content: "\FF259";
}
.game-icon-corset:before {
    content: "\FF25A";
}
.game-icon-corsica:before {
    content: "\FF25B";
}
.game-icon-cosmic-egg:before {
    content: "\FF25C";
}
.game-icon-cotton-flower:before {
    content: "\FF25D";
}
.game-icon-covered-jar:before {
    content: "\FF25E";
}
.game-icon-cow:before {
    content: "\FF25F";
}
.game-icon-cowboy-boot:before {
    content: "\FF260";
}
.game-icon-cowboy-holster:before {
    content: "\FF261";
}
.game-icon-cpu:before {
    content: "\FF262";
}
.game-icon-crafting:before {
    content: "\FF263";
}
.game-icon-crane:before {
    content: "\FF264";
}
.game-icon-credits-currency:before {
    content: "\FF265";
}
.game-icon-crenel-crown:before {
    content: "\FF266";
}
.game-icon-crescent-staff:before {
    content: "\FF267";
}
.game-icon-cricket-bat:before {
    content: "\FF268";
}
.game-icon-cricket:before {
    content: "\FF269";
}
.game-icon-crime-scene-tape:before {
    content: "\FF26A";
}
.game-icon-croissant:before {
    content: "\FF26B";
}
.game-icon-crook-flail:before {
    content: "\FF26C";
}
.game-icon-cross-shield:before {
    content: "\FF26D";
}
.game-icon-crosscut-saw:before {
    content: "\FF26E";
}
.game-icon-crosshair:before {
    content: "\FF26F";
}
.game-icon-crossroad:before {
    content: "\FF270";
}
.game-icon-crow-nest:before {
    content: "\FF271";
}
.game-icon-crowbar:before {
    content: "\FF272";
}
.game-icon-crucifix:before {
    content: "\FF273";
}
.game-icon-cryo-chamber:before {
    content: "\FF274";
}
.game-icon-crypt-entrance:before {
    content: "\FF275";
}
.game-icon-crystal-earrings:before {
    content: "\FF276";
}
.game-icon-crystal-shrine:before {
    content: "\FF277";
}
.game-icon-cuauhtli:before {
    content: "\FF278";
}
.game-icon-cube:before {
    content: "\FF279";
}
.game-icon-cuckoo-clock:before {
    content: "\FF27A";
}
.game-icon-cupcake:before {
    content: "\FF27B";
}
.game-icon-curling-stone:before {
    content: "\FF27C";
}
.game-icon-custodian-helmet:before {
    content: "\FF27D";
}
.game-icon-cut-lemon:before {
    content: "\FF27E";
}
.game-icon-cyber-eye:before {
    content: "\FF27F";
}
.game-icon-cyborg-face:before {
    content: "\FF280";
}
.game-icon-cycling:before {
    content: "\FF281";
}
.game-icon-daemon-pull:before {
    content: "\FF282";
}
.game-icon-dagger-rose:before {
    content: "\FF283";
}
.game-icon-dam:before {
    content: "\FF284";
}
.game-icon-dandelion-flower:before {
    content: "\FF285";
}
.game-icon-dango:before {
    content: "\FF286";
}
.game-icon-dart:before {
    content: "\FF287";
}
.game-icon-database:before {
    content: "\FF288";
}
.game-icon-dead-head:before {
    content: "\FF289";
}
.game-icon-death-star:before {
    content: "\FF28A";
}
.game-icon-deer-track:before {
    content: "\FF28B";
}
.game-icon-defense-satellite:before {
    content: "\FF28C";
}
.game-icon-deku-tree:before {
    content: "\FF28D";
}
.game-icon-delicate-perfume:before {
    content: "\FF28E";
}
.game-icon-delivery-drone:before {
    content: "\FF28F";
}
.game-icon-desert:before {
    content: "\FF290";
}
.game-icon-deshret-red-crown:before {
    content: "\FF291";
}
.game-icon-desk:before {
    content: "\FF292";
}
.game-icon-detonator:before {
    content: "\FF293";
}
.game-icon-detour:before {
    content: "\FF294";
}
.game-icon-devil-mask:before {
    content: "\FF295";
}
.game-icon-dial-padlock:before {
    content: "\FF296";
}
.game-icon-diamond-hilt:before {
    content: "\FF297";
}
.game-icon-diamond-ring:before {
    content: "\FF298";
}
.game-icon-diamond-trophy:before {
    content: "\FF299";
}
.game-icon-dice-eight-faces-eight:before {
    content: "\FF29A";
}
.game-icon-dice-fire:before {
    content: "\FF29B";
}
.game-icon-dice-shield:before {
    content: "\FF29C";
}
.game-icon-dice-six-faces-five:before {
    content: "\FF29D";
}
.game-icon-dice-six-faces-four:before {
    content: "\FF29E";
}
.game-icon-dice-six-faces-one:before {
    content: "\FF29F";
}
.game-icon-dice-six-faces-six:before {
    content: "\FF2A0";
}
.game-icon-dice-six-faces-three:before {
    content: "\FF2A1";
}
.game-icon-dice-six-faces-two:before {
    content: "\FF2A2";
}
.game-icon-dice-target:before {
    content: "\FF2A3";
}
.game-icon-dice-twenty-faces-one:before {
    content: "\FF2A4";
}
.game-icon-dice-twenty-faces-twenty:before {
    content: "\FF2A5";
}
.game-icon-dig-hole:before {
    content: "\FF2A6";
}
.game-icon-dimetrodon:before {
    content: "\FF2A7";
}
.game-icon-diplodocus:before {
    content: "\FF2A8";
}
.game-icon-diploma:before {
    content: "\FF2A9";
}
.game-icon-direction-sign:before {
    content: "\FF2AA";
}
.game-icon-direction-signs:before {
    content: "\FF2AB";
}
.game-icon-director-chair:before {
    content: "\FF2AC";
}
.game-icon-disc-golf-bag:before {
    content: "\FF2AD";
}
.game-icon-disc-golf-basket:before {
    content: "\FF2AE";
}
.game-icon-discobolus:before {
    content: "\FF2AF";
}
.game-icon-discussion:before {
    content: "\FF2B0";
}
.game-icon-distress-signal:before {
    content: "\FF2B1";
}
.game-icon-diving-helmet:before {
    content: "\FF2B2";
}
.game-icon-djed-pillar:before {
    content: "\FF2B3";
}
.game-icon-djembe:before {
    content: "\FF2B4";
}
.game-icon-djinn:before {
    content: "\FF2B5";
}
.game-icon-dog-bowl:before {
    content: "\FF2B6";
}
.game-icon-dog-house:before {
    content: "\FF2B7";
}
.game-icon-dolmen:before {
    content: "\FF2B8";
}
.game-icon-dolphin:before {
    content: "\FF2B9";
}
.game-icon-domino-tiles:before {
    content: "\FF2BA";
}
.game-icon-doner-kebab:before {
    content: "\FF2BB";
}
.game-icon-donut:before {
    content: "\FF2BC";
}
.game-icon-door-handle:before {
    content: "\FF2BD";
}
.game-icon-door-ring-handle:before {
    content: "\FF2BE";
}
.game-icon-door-watcher:before {
    content: "\FF2BF";
}
.game-icon-door:before {
    content: "\FF2C0";
}
.game-icon-double-fish:before {
    content: "\FF2C1";
}
.game-icon-double-necklace:before {
    content: "\FF2C2";
}
.game-icon-double-street-lights:before {
    content: "\FF2C3";
}
.game-icon-dough-roller:before {
    content: "\FF2C4";
}
.game-icon-dragon-orb:before {
    content: "\FF2C5";
}
.game-icon-dragon-shield:before {
    content: "\FF2C6";
}
.game-icon-drakkar-dragon:before {
    content: "\FF2C7";
}
.game-icon-drakkar:before {
    content: "\FF2C8";
}
.game-icon-drawbridge:before {
    content: "\FF2C9";
}
.game-icon-dream-catcher:before {
    content: "\FF2CA";
}
.game-icon-drill:before {
    content: "\FF2CB";
}
.game-icon-drinking:before {
    content: "\FF2CC";
}
.game-icon-drop-earrings:before {
    content: "\FF2CD";
}
.game-icon-drum:before {
    content: "\FF2CE";
}
.game-icon-duck-palm:before {
    content: "\FF2CF";
}
.game-icon-duck:before {
    content: "\FF2D0";
}
.game-icon-duffel-bag:before {
    content: "\FF2D1";
}
.game-icon-dunce-cap:before {
    content: "\FF2D2";
}
.game-icon-dungeon-gate:before {
    content: "\FF2D3";
}
.game-icon-dungeon-light:before {
    content: "\FF2D4";
}
.game-icon-duration:before {
    content: "\FF2D5";
}
.game-icon-dutch-bike:before {
    content: "\FF2D6";
}
.game-icon-dwarf-face:before {
    content: "\FF2D7";
}
.game-icon-dwennimmen:before {
    content: "\FF2D8";
}
.game-icon-dynamite:before {
    content: "\FF2D9";
}
.game-icon-eagle-head:before {
    content: "\FF2DA";
}
.game-icon-earrings:before {
    content: "\FF2DB";
}
.game-icon-earth-africa-europe:before {
    content: "\FF2DC";
}
.game-icon-earth-america:before {
    content: "\FF2DD";
}
.game-icon-earth-asia-oceania:before {
    content: "\FF2DE";
}
.game-icon-easter-egg:before {
    content: "\FF2DF";
}
.game-icon-eating-pelican:before {
    content: "\FF2E0";
}
.game-icon-eating:before {
    content: "\FF2E1";
}
.game-icon-ecology:before {
    content: "\FF2E2";
}
.game-icon-eel:before {
    content: "\FF2E3";
}
.game-icon-egg-eye:before {
    content: "\FF2E4";
}
.game-icon-egypt:before {
    content: "\FF2E5";
}
.game-icon-egyptian-bird:before {
    content: "\FF2E6";
}
.game-icon-egyptian-profile:before {
    content: "\FF2E7";
}
.game-icon-egyptian-pyramids:before {
    content: "\FF2E8";
}
.game-icon-egyptian-sphinx:before {
    content: "\FF2E9";
}
.game-icon-egyptian-temple:before {
    content: "\FF2EA";
}
.game-icon-egyptian-urns:before {
    content: "\FF2EB";
}
.game-icon-egyptian-walk:before {
    content: "\FF2EC";
}
.game-icon-eight-ball:before {
    content: "\FF2ED";
}
.game-icon-elbow-pad:before {
    content: "\FF2EE";
}
.game-icon-electrical-resistance:before {
    content: "\FF2EF";
}
.game-icon-electrical-socket:before {
    content: "\FF2F0";
}
.game-icon-elephant-head:before {
    content: "\FF2F1";
}
.game-icon-elephant:before {
    content: "\FF2F2";
}
.game-icon-elevator:before {
    content: "\FF2F3";
}
.game-icon-elf-ear:before {
    content: "\FF2F4";
}
.game-icon-emerald-necklace:before {
    content: "\FF2F5";
}
.game-icon-empty-chessboard:before {
    content: "\FF2F6";
}
.game-icon-empty-metal-bucket-handle:before {
    content: "\FF2F7";
}
.game-icon-empty-metal-bucket:before {
    content: "\FF2F8";
}
.game-icon-empty-wood-bucket-handle:before {
    content: "\FF2F9";
}
.game-icon-empty-wood-bucket:before {
    content: "\FF2FA";
}
.game-icon-encirclement:before {
    content: "\FF2FB";
}
.game-icon-energy-tank:before {
    content: "\FF2FC";
}
.game-icon-enrage:before {
    content: "\FF2FD";
}
.game-icon-entry-door:before {
    content: "\FF2FE";
}
.game-icon-ermine:before {
    content: "\FF2FF";
}
.game-icon-escalator:before {
    content: "\FF300";
}
.game-icon-eternal-love:before {
    content: "\FF301";
}
.game-icon-european-flag:before {
    content: "\FF302";
}
.game-icon-evasion:before {
    content: "\FF303";
}
.game-icon-evil-love:before {
    content: "\FF304";
}
.game-icon-evil-tower:before {
    content: "\FF305";
}
.game-icon-executioner-hood:before {
    content: "\FF306";
}
.game-icon-exit-door:before {
    content: "\FF307";
}
.game-icon-expand:before {
    content: "\FF308";
}
.game-icon-expense:before {
    content: "\FF309";
}
.game-icon-exploding-planet:before {
    content: "\FF30A";
}
.game-icon-extra-time:before {
    content: "\FF30B";
}
.game-icon-eye-of-horus:before {
    content: "\FF30C";
}
.game-icon-eye-target:before {
    content: "\FF30D";
}
.game-icon-eyelashes:before {
    content: "\FF30E";
}
.game-icon-eyepatch:before {
    content: "\FF30F";
}
.game-icon-f-clef:before {
    content: "\FF310";
}
.game-icon-face-to-face:before {
    content: "\FF311";
}
.game-icon-factory-arm:before {
    content: "\FF312";
}
.game-icon-factory:before {
    content: "\FF313";
}
.game-icon-fairy-wings:before {
    content: "\FF314";
}
.game-icon-fairy:before {
    content: "\FF315";
}
.game-icon-falcon-moon:before {
    content: "\FF316";
}
.game-icon-falling-bomb:before {
    content: "\FF317";
}
.game-icon-falling-rocks:before {
    content: "\FF318";
}
.game-icon-falling-star:before {
    content: "\FF319";
}
.game-icon-fallout-shelter:before {
    content: "\FF31A";
}
.game-icon-family-house:before {
    content: "\FF31B";
}
.game-icon-family-tree:before {
    content: "\FF31C";
}
.game-icon-farm-tractor:before {
    content: "\FF31D";
}
.game-icon-farmer:before {
    content: "\FF31E";
}
.game-icon-fast-backward-button:before {
    content: "\FF31F";
}
.game-icon-fast-forward-button:before {
    content: "\FF320";
}
.game-icon-fast-noodles:before {
    content: "\FF321";
}
.game-icon-feather-necklace:before {
    content: "\FF322";
}
.game-icon-feline:before {
    content: "\FF323";
}
.game-icon-female-legs:before {
    content: "\FF324";
}
.game-icon-female-vampire:before {
    content: "\FF325";
}
.game-icon-female:before {
    content: "\FF326";
}
.game-icon-fencer:before {
    content: "\FF327";
}
.game-icon-fern:before {
    content: "\FF328";
}
.game-icon-fertilizer-bag:before {
    content: "\FF329";
}
.game-icon-fez:before {
    content: "\FF32A";
}
.game-icon-figurehead:before {
    content: "\FF32B";
}
.game-icon-files:before {
    content: "\FF32C";
}
.game-icon-film-projector:before {
    content: "\FF32D";
}
.game-icon-film-spool:before {
    content: "\FF32E";
}
.game-icon-film-strip:before {
    content: "\FF32F";
}
.game-icon-finch:before {
    content: "\FF330";
}
.game-icon-finger-print:before {
    content: "\FF331";
}
.game-icon-fingernail:before {
    content: "\FF332";
}
.game-icon-finish-line:before {
    content: "\FF333";
}
.game-icon-fire-extinguisher:before {
    content: "\FF334";
}
.game-icon-fire-flower:before {
    content: "\FF335";
}
.game-icon-fire-gem:before {
    content: "\FF336";
}
.game-icon-fire-shrine:before {
    content: "\FF337";
}
.game-icon-fire-spell-cast:before {
    content: "\FF338";
}
.game-icon-fireplace:before {
    content: "\FF339";
}
.game-icon-firewall:before {
    content: "\FF33A";
}
.game-icon-first-aid-kit:before {
    content: "\FF33B";
}
.game-icon-fish-bucket:before {
    content: "\FF33C";
}
.game-icon-fish-eggs:before {
    content: "\FF33D";
}
.game-icon-fish-escape:before {
    content: "\FF33E";
}
.game-icon-fish-monster:before {
    content: "\FF33F";
}
.game-icon-fish-scales:before {
    content: "\FF340";
}
.game-icon-fish-smoking:before {
    content: "\FF341";
}
.game-icon-fishing-boat:before {
    content: "\FF342";
}
.game-icon-fishing-jig:before {
    content: "\FF343";
}
.game-icon-fishing-lure:before {
    content: "\FF344";
}
.game-icon-fishing-pole:before {
    content: "\FF345";
}
.game-icon-fishing-spoon:before {
    content: "\FF346";
}
.game-icon-fishing:before {
    content: "\FF347";
}
.game-icon-flag-objective:before {
    content: "\FF348";
}
.game-icon-flail:before {
    content: "\FF349";
}
.game-icon-flamethrower-soldier:before {
    content: "\FF34A";
}
.game-icon-flamethrower:before {
    content: "\FF34B";
}
.game-icon-flamingo:before {
    content: "\FF34C";
}
.game-icon-flanged-mace:before {
    content: "\FF34D";
}
.game-icon-flashlight:before {
    content: "\FF34E";
}
.game-icon-flat-platform:before {
    content: "\FF34F";
}
.game-icon-flat-tire:before {
    content: "\FF350";
}
.game-icon-flatfish:before {
    content: "\FF351";
}
.game-icon-flax:before {
    content: "\FF352";
}
.game-icon-fleur-de-lys:before {
    content: "\FF353";
}
.game-icon-flexible-lamp:before {
    content: "\FF354";
}
.game-icon-flint-spark:before {
    content: "\FF355";
}
.game-icon-flip-flops:before {
    content: "\FF356";
}
.game-icon-floating-ghost:before {
    content: "\FF357";
}
.game-icon-floating-platforms:before {
    content: "\FF358";
}
.game-icon-floating-tentacles:before {
    content: "\FF359";
}
.game-icon-flood:before {
    content: "\FF35A";
}
.game-icon-floor-hatch:before {
    content: "\FF35B";
}
.game-icon-floor-polisher:before {
    content: "\FF35C";
}
.game-icon-flour:before {
    content: "\FF35D";
}
.game-icon-flower-emblem:before {
    content: "\FF35E";
}
.game-icon-flower-hat:before {
    content: "\FF35F";
}
.game-icon-flower-star:before {
    content: "\FF360";
}
.game-icon-flute:before {
    content: "\FF361";
}
.game-icon-fly:before {
    content: "\FF362";
}
.game-icon-flying-beetle:before {
    content: "\FF363";
}
.game-icon-flying-fox:before {
    content: "\FF364";
}
.game-icon-flying-target:before {
    content: "\FF365";
}
.game-icon-fog-light:before {
    content: "\FF366";
}
.game-icon-fog:before {
    content: "\FF367";
}
.game-icon-food-truck:before {
    content: "\FF368";
}
.game-icon-foot-plaster:before {
    content: "\FF369";
}
.game-icon-footy-field:before {
    content: "\FF36A";
}
.game-icon-forearm:before {
    content: "\FF36B";
}
.game-icon-forest-camp:before {
    content: "\FF36C";
}
.game-icon-forest-entrance:before {
    content: "\FF36D";
}
.game-icon-forest:before {
    content: "\FF36E";
}
.game-icon-fork-knife-spoon:before {
    content: "\FF36F";
}
.game-icon-forklift:before {
    content: "\FF370";
}
.game-icon-foundry-bucket:before {
    content: "\FF371";
}
.game-icon-fox-tail:before {
    content: "\FF372";
}
.game-icon-france:before {
    content: "\FF373";
}
.game-icon-freemasonry:before {
    content: "\FF374";
}
.game-icon-french-fries:before {
    content: "\FF375";
}
.game-icon-fried-eggs:before {
    content: "\FF376";
}
.game-icon-frisbee:before {
    content: "\FF377";
}
.game-icon-frog-foot:before {
    content: "\FF378";
}
.game-icon-frog-prince:before {
    content: "\FF379";
}
.game-icon-frozen-body:before {
    content: "\FF37A";
}
.game-icon-frozen-ring:before {
    content: "\FF37B";
}
.game-icon-fruit-tree:before {
    content: "\FF37C";
}
.game-icon-fuel-tank:before {
    content: "\FF37D";
}
.game-icon-fuji:before {
    content: "\FF37E";
}
.game-icon-full-folder:before {
    content: "\FF37F";
}
.game-icon-full-metal-bucket-handle:before {
    content: "\FF380";
}
.game-icon-full-metal-bucket:before {
    content: "\FF381";
}
.game-icon-full-motorcycle-helmet:before {
    content: "\FF382";
}
.game-icon-full-pizza:before {
    content: "\FF383";
}
.game-icon-full-wood-bucket-handle:before {
    content: "\FF384";
}
.game-icon-full-wood-bucket:before {
    content: "\FF385";
}
.game-icon-funnel:before {
    content: "\FF386";
}
.game-icon-fur-boot:before {
    content: "\FF387";
}
.game-icon-fur-shirt:before {
    content: "\FF388";
}
.game-icon-furnace:before {
    content: "\FF389";
}
.game-icon-g-clef:before {
    content: "\FF38A";
}
.game-icon-galaxy:before {
    content: "\FF38B";
}
.game-icon-galea:before {
    content: "\FF38C";
}
.game-icon-galley:before {
    content: "\FF38D";
}
.game-icon-game-console:before {
    content: "\FF38E";
}
.game-icon-gamepad:before {
    content: "\FF38F";
}
.game-icon-gardening-shears:before {
    content: "\FF390";
}
.game-icon-gargoyle:before {
    content: "\FF391";
}
.game-icon-garlic:before {
    content: "\FF392";
}
.game-icon-gas-pump:before {
    content: "\FF393";
}
.game-icon-gas-stove:before {
    content: "\FF394";
}
.game-icon-gate:before {
    content: "\FF395";
}
.game-icon-gauls-helm:before {
    content: "\FF396";
}
.game-icon-gauntlet:before {
    content: "\FF397";
}
.game-icon-gear-stick-pattern:before {
    content: "\FF398";
}
.game-icon-gear-stick:before {
    content: "\FF399";
}
.game-icon-gemini:before {
    content: "\FF39A";
}
.game-icon-giant-squid:before {
    content: "\FF39B";
}
.game-icon-giant:before {
    content: "\FF39C";
}
.game-icon-gingerbread-man:before {
    content: "\FF39D";
}
.game-icon-ginkgo-leaf:before {
    content: "\FF39E";
}
.game-icon-glaive:before {
    content: "\FF39F";
}
.game-icon-glass-ball:before {
    content: "\FF3A0";
}
.game-icon-glass-celebration:before {
    content: "\FF3A1";
}
.game-icon-globe-ring:before {
    content: "\FF3A2";
}
.game-icon-gloves:before {
    content: "\FF3A3";
}
.game-icon-glowing-artifact:before {
    content: "\FF3A4";
}
.game-icon-goal-keeper:before {
    content: "\FF3A5";
}
.game-icon-goblin-camp:before {
    content: "\FF3A6";
}
.game-icon-goblin-head:before {
    content: "\FF3A7";
}
.game-icon-gold-mine:before {
    content: "\FF3A8";
}
.game-icon-gold-nuggets:before {
    content: "\FF3A9";
}
.game-icon-gold-stack:before {
    content: "\FF3AA";
}
.game-icon-golem-head:before {
    content: "\FF3AB";
}
.game-icon-golf-flag:before {
    content: "\FF3AC";
}
.game-icon-golf-tee:before {
    content: "\FF3AD";
}
.game-icon-gong:before {
    content: "\FF3AE";
}
.game-icon-goose:before {
    content: "\FF3AF";
}
.game-icon-gorilla:before {
    content: "\FF3B0";
}
.game-icon-gps:before {
    content: "\FF3B1";
}
.game-icon-graduate-cap:before {
    content: "\FF3B2";
}
.game-icon-grain-bundle:before {
    content: "\FF3B3";
}
.game-icon-grain:before {
    content: "\FF3B4";
}
.game-icon-granary:before {
    content: "\FF3B5";
}
.game-icon-grasping-slug:before {
    content: "\FF3B6";
}
.game-icon-grass-mushroom:before {
    content: "\FF3B7";
}
.game-icon-grass:before {
    content: "\FF3B8";
}
.game-icon-grave-flowers:before {
    content: "\FF3B9";
}
.game-icon-graveyard:before {
    content: "\FF3BA";
}
.game-icon-great-pyramid:before {
    content: "\FF3BB";
}
.game-icon-greaves:before {
    content: "\FF3BC";
}
.game-icon-greek-sphinx:before {
    content: "\FF3BD";
}
.game-icon-greek-temple:before {
    content: "\FF3BE";
}
.game-icon-green-power:before {
    content: "\FF3BF";
}
.game-icon-greenhouse:before {
    content: "\FF3C0";
}
.game-icon-griffin-shield:before {
    content: "\FF3C1";
}
.game-icon-griffin-symbol:before {
    content: "\FF3C2";
}
.game-icon-growth:before {
    content: "\FF3C3";
}
.game-icon-guards:before {
    content: "\FF3C4";
}
.game-icon-guatemala:before {
    content: "\FF3C5";
}
.game-icon-guitar-bass-head:before {
    content: "\FF3C6";
}
.game-icon-guitar-head:before {
    content: "\FF3C7";
}
.game-icon-gun-rose:before {
    content: "\FF3C8";
}
.game-icon-gun-stock:before {
    content: "\FF3C9";
}
.game-icon-gym-bag:before {
    content: "\FF3CA";
}
.game-icon-h2o:before {
    content: "\FF3CB";
}
.game-icon-habitat-dome:before {
    content: "\FF3CC";
}
.game-icon-hades-symbol:before {
    content: "\FF3CD";
}
.game-icon-hair-strands:before {
    content: "\FF3CE";
}
.game-icon-half-body-crawling:before {
    content: "\FF3CF";
}
.game-icon-half-dead:before {
    content: "\FF3D0";
}
.game-icon-half-log:before {
    content: "\FF3D1";
}
.game-icon-hamburger-menu:before {
    content: "\FF3D2";
}
.game-icon-hamburger:before {
    content: "\FF3D3";
}
.game-icon-hammer-break:before {
    content: "\FF3D4";
}
.game-icon-hammer-sickle:before {
    content: "\FF3D5";
}
.game-icon-hand-bag:before {
    content: "\FF3D6";
}
.game-icon-hand-bandage:before {
    content: "\FF3D7";
}
.game-icon-hand-grip:before {
    content: "\FF3D8";
}
.game-icon-hand-of-god:before {
    content: "\FF3D9";
}
.game-icon-hand-ok:before {
    content: "\FF3DA";
}
.game-icon-hand-saw:before {
    content: "\FF3DB";
}
.game-icon-hand-truck:before {
    content: "\FF3DC";
}
.game-icon-hand-wing:before {
    content: "\FF3DD";
}
.game-icon-handcuffed:before {
    content: "\FF3DE";
}
.game-icon-handheld-fan:before {
    content: "\FF3DF";
}
.game-icon-hang-glider:before {
    content: "\FF3E0";
}
.game-icon-hanger:before {
    content: "\FF3E1";
}
.game-icon-hanging-sign:before {
    content: "\FF3E2";
}
.game-icon-harbor-dock:before {
    content: "\FF3E3";
}
.game-icon-harp:before {
    content: "\FF3E4";
}
.game-icon-hatchet:before {
    content: "\FF3E5";
}
.game-icon-hazmat-suit:before {
    content: "\FF3E6";
}
.game-icon-headband-knot:before {
    content: "\FF3E7";
}
.game-icon-headphones:before {
    content: "\FF3E8";
}
.game-icon-healing-shield:before {
    content: "\FF3E9";
}
.game-icon-healing:before {
    content: "\FF3EA";
}
.game-icon-health-capsule:before {
    content: "\FF3EB";
}
.game-icon-health-potion:before {
    content: "\FF3EC";
}
.game-icon-heart-armor:before {
    content: "\FF3ED";
}
.game-icon-heart-battery:before {
    content: "\FF3EE";
}
.game-icon-heart-beats:before {
    content: "\FF3EF";
}
.game-icon-heart-earrings:before {
    content: "\FF3F0";
}
.game-icon-heart-key:before {
    content: "\FF3F1";
}
.game-icon-heart-necklace:before {
    content: "\FF3F2";
}
.game-icon-heart-shield:before {
    content: "\FF3F3";
}
.game-icon-heart-stake:before {
    content: "\FF3F4";
}
.game-icon-heart-wings:before {
    content: "\FF3F5";
}
.game-icon-heaven-gate:before {
    content: "\FF3F6";
}
.game-icon-heavy-bullets:before {
    content: "\FF3F7";
}
.game-icon-heavy-collar:before {
    content: "\FF3F8";
}
.game-icon-hedjet-white-crown:before {
    content: "\FF3F9";
}
.game-icon-helicoprion:before {
    content: "\FF3FA";
}
.game-icon-helicopter-tail:before {
    content: "\FF3FB";
}
.game-icon-helicopter:before {
    content: "\FF3FC";
}
.game-icon-hell-crosses:before {
    content: "\FF3FD";
}
.game-icon-hemp:before {
    content: "\FF3FE";
}
.game-icon-heptagram:before {
    content: "\FF3FF";
}
.game-icon-herbs-bundle:before {
    content: "\FF400";
}
.game-icon-hexagonal-nut:before {
    content: "\FF401";
}
.game-icon-hieroglyph-legs:before {
    content: "\FF402";
}
.game-icon-hieroglyph-y:before {
    content: "\FF403";
}
.game-icon-high-grass:before {
    content: "\FF404";
}
.game-icon-high-heel:before {
    content: "\FF405";
}
.game-icon-high-kick:before {
    content: "\FF406";
}
.game-icon-high-punch:before {
    content: "\FF407";
}
.game-icon-high-tide:before {
    content: "\FF408";
}
.game-icon-highlighter:before {
    content: "\FF409";
}
.game-icon-hiking:before {
    content: "\FF40A";
}
.game-icon-hill-conquest:before {
    content: "\FF40B";
}
.game-icon-hill-fort:before {
    content: "\FF40C";
}
.game-icon-hills:before {
    content: "\FF40D";
}
.game-icon-histogram:before {
    content: "\FF40E";
}
.game-icon-hive-mind:before {
    content: "\FF40F";
}
.game-icon-hobbit-door:before {
    content: "\FF410";
}
.game-icon-hobbit-dwelling:before {
    content: "\FF411";
}
.game-icon-hockey:before {
    content: "\FF412";
}
.game-icon-hole-ladder:before {
    content: "\FF413";
}
.game-icon-hole:before {
    content: "\FF414";
}
.game-icon-holy-hand-grenade:before {
    content: "\FF415";
}
.game-icon-holy-water:before {
    content: "\FF416";
}
.game-icon-home-garage:before {
    content: "\FF417";
}
.game-icon-honey-jar:before {
    content: "\FF418";
}
.game-icon-hoodie:before {
    content: "\FF419";
}
.game-icon-hook:before {
    content: "\FF41A";
}
.game-icon-hops:before {
    content: "\FF41B";
}
.game-icon-horizon-road:before {
    content: "\FF41C";
}
.game-icon-horizontal-flip:before {
    content: "\FF41D";
}
.game-icon-horned-reptile:before {
    content: "\FF41E";
}
.game-icon-horse-head:before {
    content: "\FF41F";
}
.game-icon-horseshoe:before {
    content: "\FF420";
}
.game-icon-horus:before {
    content: "\FF421";
}
.game-icon-hospital:before {
    content: "\FF422";
}
.game-icon-hot-dog:before {
    content: "\FF423";
}
.game-icon-hot-meal:before {
    content: "\FF424";
}
.game-icon-house-keys:before {
    content: "\FF425";
}
.game-icon-house:before {
    content: "\FF426";
}
.game-icon-human-cannonball:before {
    content: "\FF427";
}
.game-icon-human-ear:before {
    content: "\FF428";
}
.game-icon-human-pyramid:before {
    content: "\FF429";
}
.game-icon-human-target:before {
    content: "\FF42A";
}
.game-icon-hummingbird:before {
    content: "\FF42B";
}
.game-icon-hungary:before {
    content: "\FF42C";
}
.game-icon-hunter-eyes:before {
    content: "\FF42D";
}
.game-icon-hunting-bolas:before {
    content: "\FF42E";
}
.game-icon-hut:before {
    content: "\FF42F";
}
.game-icon-huts-village:before {
    content: "\FF430";
}
.game-icon-i-beam:before {
    content: "\FF431";
}
.game-icon-i-brick:before {
    content: "\FF432";
}
.game-icon-ibis:before {
    content: "\FF433";
}
.game-icon-icarus:before {
    content: "\FF434";
}
.game-icon-ice-cream-cone:before {
    content: "\FF435";
}
.game-icon-ice-cream-scoop:before {
    content: "\FF436";
}
.game-icon-ice-cubes:before {
    content: "\FF437";
}
.game-icon-ice-golem:before {
    content: "\FF438";
}
.game-icon-ice-iris:before {
    content: "\FF439";
}
.game-icon-ice-pop:before {
    content: "\FF43A";
}
.game-icon-ice-skate:before {
    content: "\FF43B";
}
.game-icon-ice-spell-cast:before {
    content: "\FF43C";
}
.game-icon-iceberg:before {
    content: "\FF43D";
}
.game-icon-iceland:before {
    content: "\FF43E";
}
.game-icon-id-card:before {
    content: "\FF43F";
}
.game-icon-idea:before {
    content: "\FF440";
}
.game-icon-igloo:before {
    content: "\FF441";
}
.game-icon-imperial-crown:before {
    content: "\FF442";
}
.game-icon-inauguration:before {
    content: "\FF443";
}
.game-icon-incubator:before {
    content: "\FF444";
}
.game-icon-india-gate:before {
    content: "\FF445";
}
.game-icon-indian-palace:before {
    content: "\FF446";
}
.game-icon-inferno-bomb:before {
    content: "\FF447";
}
.game-icon-info:before {
    content: "\FF448";
}
.game-icon-injustice:before {
    content: "\FF449";
}
.game-icon-inspiration:before {
    content: "\FF44A";
}
.game-icon-interceptor-ship:before {
    content: "\FF44B";
}
.game-icon-intricate-necklace:before {
    content: "\FF44C";
}
.game-icon-invisible-face:before {
    content: "\FF44D";
}
.game-icon-invisible:before {
    content: "\FF44E";
}
.game-icon-ionic-column:before {
    content: "\FF44F";
}
.game-icon-iraq:before {
    content: "\FF450";
}
.game-icon-island:before {
    content: "\FF451";
}
.game-icon-italia:before {
    content: "\FF452";
}
.game-icon-ivory-tusks:before {
    content: "\FF453";
}
.game-icon-j-brick:before {
    content: "\FF454";
}
.game-icon-jack-plug:before {
    content: "\FF455";
}
.game-icon-japan:before {
    content: "\FF456";
}
.game-icon-japanese-bridge:before {
    content: "\FF457";
}
.game-icon-jasmine:before {
    content: "\FF458";
}
.game-icon-jason-mask:before {
    content: "\FF459";
}
.game-icon-jawless-cyclop:before {
    content: "\FF45A";
}
.game-icon-jeep:before {
    content: "\FF45B";
}
.game-icon-jelly-beans:before {
    content: "\FF45C";
}
.game-icon-jelly:before {
    content: "\FF45D";
}
.game-icon-jerrycan:before {
    content: "\FF45E";
}
.game-icon-jerusalem-cross:before {
    content: "\FF45F";
}
.game-icon-jester-hat:before {
    content: "\FF460";
}
.game-icon-jet-fighter:before {
    content: "\FF461";
}
.game-icon-jewel-crown:before {
    content: "\FF462";
}
.game-icon-join:before {
    content: "\FF463";
}
.game-icon-joystick:before {
    content: "\FF464";
}
.game-icon-jug:before {
    content: "\FF465";
}
.game-icon-juggling-clubs:before {
    content: "\FF466";
}
.game-icon-juggling-seal:before {
    content: "\FF467";
}
.game-icon-jump-across:before {
    content: "\FF468";
}
.game-icon-jumping-dog:before {
    content: "\FF469";
}
.game-icon-jumping-rope:before {
    content: "\FF46A";
}
.game-icon-jungle:before {
    content: "\FF46B";
}
.game-icon-jupiter:before {
    content: "\FF46C";
}
.game-icon-kangaroo:before {
    content: "\FF46D";
}
.game-icon-katana:before {
    content: "\FF46E";
}
.game-icon-kebab-spit:before {
    content: "\FF46F";
}
.game-icon-kenku-head:before {
    content: "\FF470";
}
.game-icon-kenya:before {
    content: "\FF471";
}
.game-icon-ketchup:before {
    content: "\FF472";
}
.game-icon-key-card:before {
    content: "\FF473";
}
.game-icon-key-lock:before {
    content: "\FF474";
}
.game-icon-keyboard:before {
    content: "\FF475";
}
.game-icon-keyring:before {
    content: "\FF476";
}
.game-icon-kick-scooter:before {
    content: "\FF477";
}
.game-icon-kid-slide:before {
    content: "\FF478";
}
.game-icon-kidneys:before {
    content: "\FF479";
}
.game-icon-kimono:before {
    content: "\FF47A";
}
.game-icon-king-ju-mask:before {
    content: "\FF47B";
}
.game-icon-kitchen-scale:before {
    content: "\FF47C";
}
.game-icon-kitchen-tap:before {
    content: "\FF47D";
}
.game-icon-kite:before {
    content: "\FF47E";
}
.game-icon-kiwi-bird:before {
    content: "\FF47F";
}
.game-icon-kiwi-fruit:before {
    content: "\FF480";
}
.game-icon-klingon:before {
    content: "\FF481";
}
.game-icon-knee-bandage:before {
    content: "\FF482";
}
.game-icon-knee-cap:before {
    content: "\FF483";
}
.game-icon-knee-pad:before {
    content: "\FF484";
}
.game-icon-kneeling:before {
    content: "\FF485";
}
.game-icon-knight-banner:before {
    content: "\FF486";
}
.game-icon-knocked-out-stars:before {
    content: "\FF487";
}
.game-icon-knot:before {
    content: "\FF488";
}
.game-icon-koala:before {
    content: "\FF489";
}
.game-icon-koholint-egg:before {
    content: "\FF48A";
}
.game-icon-kraken-tentacle:before {
    content: "\FF48B";
}
.game-icon-l-brick:before {
    content: "\FF48C";
}
.game-icon-lab-coat:before {
    content: "\FF48D";
}
.game-icon-labrador-head:before {
    content: "\FF48E";
}
.game-icon-ladder:before {
    content: "\FF48F";
}
.game-icon-ladders-platform:before {
    content: "\FF490";
}
.game-icon-ladle:before {
    content: "\FF491";
}
.game-icon-ladybug:before {
    content: "\FF492";
}
.game-icon-laptop:before {
    content: "\FF493";
}
.game-icon-large-dress:before {
    content: "\FF494";
}
.game-icon-large-paint-brush:before {
    content: "\FF495";
}
.game-icon-laser-turret:before {
    content: "\FF496";
}
.game-icon-lasso:before {
    content: "\FF497";
}
.game-icon-latvia:before {
    content: "\FF498";
}
.game-icon-laurels-trophy:before {
    content: "\FF499";
}
.game-icon-lead-pipe:before {
    content: "\FF49A";
}
.game-icon-leak:before {
    content: "\FF49B";
}
.game-icon-leapfrog:before {
    content: "\FF49C";
}
.game-icon-leather-armor:before {
    content: "\FF49D";
}
.game-icon-led:before {
    content: "\FF49E";
}
.game-icon-leek:before {
    content: "\FF49F";
}
.game-icon-leg-armor:before {
    content: "\FF4A0";
}
.game-icon-leg:before {
    content: "\FF4A1";
}
.game-icon-lemon:before {
    content: "\FF4A2";
}
.game-icon-leo:before {
    content: "\FF4A3";
}
.game-icon-level-crossing:before {
    content: "\FF4A4";
}
.game-icon-level-end-flag:before {
    content: "\FF4A5";
}
.game-icon-liar:before {
    content: "\FF4A6";
}
.game-icon-liberty-wing:before {
    content: "\FF4A7";
}
.game-icon-libra:before {
    content: "\FF4A8";
}
.game-icon-libya:before {
    content: "\FF4A9";
}
.game-icon-life-bar:before {
    content: "\FF4AA";
}
.game-icon-life-buoy:before {
    content: "\FF4AB";
}
.game-icon-life-jacket:before {
    content: "\FF4AC";
}
.game-icon-light-backpack:before {
    content: "\FF4AD";
}
.game-icon-light-helm:before {
    content: "\FF4AE";
}
.game-icon-light-projector:before {
    content: "\FF4AF";
}
.game-icon-light-sabers:before {
    content: "\FF4B0";
}
.game-icon-lighter:before {
    content: "\FF4B1";
}
.game-icon-lighthouse:before {
    content: "\FF4B2";
}
.game-icon-lightning-dome:before {
    content: "\FF4B3";
}
.game-icon-lightning-flame:before {
    content: "\FF4B4";
}
.game-icon-lily-pads:before {
    content: "\FF4B5";
}
.game-icon-lipstick:before {
    content: "\FF4B6";
}
.game-icon-liquid-soap:before {
    content: "\FF4B7";
}
.game-icon-liver:before {
    content: "\FF4B8";
}
.game-icon-load:before {
    content: "\FF4B9";
}
.game-icon-lock-picking:before {
    content: "\FF4BA";
}
.game-icon-lock-spy:before {
    content: "\FF4BB";
}
.game-icon-locked-box:before {
    content: "\FF4BC";
}
.game-icon-locked-door:before {
    content: "\FF4BD";
}
.game-icon-locked-heart:before {
    content: "\FF4BE";
}
.game-icon-lockers:before {
    content: "\FF4BF";
}
.game-icon-lockpicks:before {
    content: "\FF4C0";
}
.game-icon-log:before {
    content: "\FF4C1";
}
.game-icon-logic-gate-and:before {
    content: "\FF4C2";
}
.game-icon-logic-gate-nand:before {
    content: "\FF4C3";
}
.game-icon-logic-gate-nor:before {
    content: "\FF4C4";
}
.game-icon-logic-gate-not:before {
    content: "\FF4C5";
}
.game-icon-logic-gate-nxor:before {
    content: "\FF4C6";
}
.game-icon-logic-gate-or:before {
    content: "\FF4C7";
}
.game-icon-logic-gate-xor:before {
    content: "\FF4C8";
}
.game-icon-loincloth:before {
    content: "\FF4C9";
}
.game-icon-look-at:before {
    content: "\FF4CA";
}
.game-icon-lorgnette:before {
    content: "\FF4CB";
}
.game-icon-louvre-pyramid:before {
    content: "\FF4CC";
}
.game-icon-love-letter:before {
    content: "\FF4CD";
}
.game-icon-love-mystery:before {
    content: "\FF4CE";
}
.game-icon-lovers:before {
    content: "\FF4CF";
}
.game-icon-low-tide:before {
    content: "\FF4D0";
}
.game-icon-luchador:before {
    content: "\FF4D1";
}
.game-icon-lucky-fisherman:before {
    content: "\FF4D2";
}
.game-icon-lunar-module:before {
    content: "\FF4D3";
}
.game-icon-lunar-wand:before {
    content: "\FF4D4";
}
.game-icon-lungs:before {
    content: "\FF4D5";
}
.game-icon-lynx-head:before {
    content: "\FF4D6";
}
.game-icon-machine-gun-magazine:before {
    content: "\FF4D7";
}
.game-icon-magic-axe:before {
    content: "\FF4D8";
}
.game-icon-magic-broom:before {
    content: "\FF4D9";
}
.game-icon-magic-hat:before {
    content: "\FF4DA";
}
.game-icon-magic-potion:before {
    content: "\FF4DB";
}
.game-icon-magic-trident:before {
    content: "\FF4DC";
}
.game-icon-magick-trick:before {
    content: "\FF4DD";
}
.game-icon-magnet-man:before {
    content: "\FF4DE";
}
.game-icon-mailbox:before {
    content: "\FF4DF";
}
.game-icon-male:before {
    content: "\FF4E0";
}
.game-icon-mammoth:before {
    content: "\FF4E1";
}
.game-icon-mandrill-head:before {
    content: "\FF4E2";
}
.game-icon-manta-ray:before {
    content: "\FF4E3";
}
.game-icon-manual-juicer:before {
    content: "\FF4E4";
}
.game-icon-manual-meat-grinder:before {
    content: "\FF4E5";
}
.game-icon-maracas:before {
    content: "\FF4E6";
}
.game-icon-marble-tap:before {
    content: "\FF4E7";
}
.game-icon-marbles:before {
    content: "\FF4E8";
}
.game-icon-mars-curiosity:before {
    content: "\FF4E9";
}
.game-icon-mars-pathfinder:before {
    content: "\FF4EA";
}
.game-icon-marshmallows:before {
    content: "\FF4EB";
}
.game-icon-martyr-memorial:before {
    content: "\FF4EC";
}
.game-icon-matchbox:before {
    content: "\FF4ED";
}
.game-icon-matryoshka-dolls:before {
    content: "\FF4EE";
}
.game-icon-matter-states:before {
    content: "\FF4EF";
}
.game-icon-mayan-pyramid:before {
    content: "\FF4F0";
}
.game-icon-meal:before {
    content: "\FF4F1";
}
.game-icon-mecha-head:before {
    content: "\FF4F2";
}
.game-icon-mecha-mask:before {
    content: "\FF4F3";
}
.game-icon-mechanic-garage:before {
    content: "\FF4F4";
}
.game-icon-medallist:before {
    content: "\FF4F5";
}
.game-icon-medical-drip:before {
    content: "\FF4F6";
}
.game-icon-medical-thermometer:before {
    content: "\FF4F7";
}
.game-icon-medicine-pills:before {
    content: "\FF4F8";
}
.game-icon-medicines:before {
    content: "\FF4F9";
}
.game-icon-medieval-barracks:before {
    content: "\FF4FA";
}
.game-icon-medieval-gate:before {
    content: "\FF4FB";
}
.game-icon-medieval-pavilion:before {
    content: "\FF4FC";
}
.game-icon-meeple-army:before {
    content: "\FF4FD";
}
.game-icon-meeple-circle:before {
    content: "\FF4FE";
}
.game-icon-meeple-group:before {
    content: "\FF4FF";
}
.game-icon-meeple-king:before {
    content: "\FF500";
}
.game-icon-meeple:before {
    content: "\FF501";
}
.game-icon-megaphone:before {
    content: "\FF502";
}
.game-icon-melting-ice-cube:before {
    content: "\FF503";
}
.game-icon-melting-metal:before {
    content: "\FF504";
}
.game-icon-menhir:before {
    content: "\FF505";
}
.game-icon-mermaid:before {
    content: "\FF506";
}
.game-icon-mesh-network:before {
    content: "\FF507";
}
.game-icon-metal-boot:before {
    content: "\FF508";
}
.game-icon-metal-golem-head:before {
    content: "\FF509";
}
.game-icon-metal-plate:before {
    content: "\FF50A";
}
.game-icon-metal-skirt:before {
    content: "\FF50B";
}
.game-icon-metroid:before {
    content: "\FF50C";
}
.game-icon-metronome:before {
    content: "\FF50D";
}
.game-icon-mexico:before {
    content: "\FF50E";
}
.game-icon-microphone:before {
    content: "\FF50F";
}
.game-icon-mighty-boosh:before {
    content: "\FF510";
}
.game-icon-mighty-force:before {
    content: "\FF511";
}
.game-icon-mighty-horn:before {
    content: "\FF512";
}
.game-icon-military-fort:before {
    content: "\FF513";
}
.game-icon-millenium-key:before {
    content: "\FF514";
}
.game-icon-mimic-chest:before {
    content: "\FF515";
}
.game-icon-mine-truck:before {
    content: "\FF516";
}
.game-icon-mine-wagon:before {
    content: "\FF517";
}
.game-icon-miner:before {
    content: "\FF518";
}
.game-icon-mineral-pearls:before {
    content: "\FF519";
}
.game-icon-mini-submarine:before {
    content: "\FF51A";
}
.game-icon-mining-helmet:before {
    content: "\FF51B";
}
.game-icon-misdirection:before {
    content: "\FF51C";
}
.game-icon-missile-launcher:before {
    content: "\FF51D";
}
.game-icon-moai:before {
    content: "\FF51E";
}
.game-icon-modern-city:before {
    content: "\FF51F";
}
.game-icon-moka-pot:before {
    content: "\FF520";
}
.game-icon-moldova:before {
    content: "\FF521";
}
.game-icon-mona-lisa:before {
    content: "\FF522";
}
.game-icon-moncler-jacket:before {
    content: "\FF523";
}
.game-icon-money-stack:before {
    content: "\FF524";
}
.game-icon-mongolia:before {
    content: "\FF525";
}
.game-icon-monk-face:before {
    content: "\FF526";
}
.game-icon-monkey-wrench:before {
    content: "\FF527";
}
.game-icon-mono-wheel-robot:before {
    content: "\FF528";
}
.game-icon-monstera-leaf:before {
    content: "\FF529";
}
.game-icon-monument-valley:before {
    content: "\FF52A";
}
.game-icon-moon-bats:before {
    content: "\FF52B";
}
.game-icon-moon-orbit:before {
    content: "\FF52C";
}
.game-icon-mooring-bollard:before {
    content: "\FF52D";
}
.game-icon-morgue-feet:before {
    content: "\FF52E";
}
.game-icon-morph-ball:before {
    content: "\FF52F";
}
.game-icon-mortar:before {
    content: "\FF530";
}
.game-icon-mountain-cave:before {
    content: "\FF531";
}
.game-icon-mountain-road:before {
    content: "\FF532";
}
.game-icon-mouse:before {
    content: "\FF533";
}
.game-icon-move:before {
    content: "\FF534";
}
.game-icon-mp5:before {
    content: "\FF535";
}
.game-icon-mug-shot:before {
    content: "\FF536";
}
.game-icon-multi-directions:before {
    content: "\FF537";
}
.game-icon-multiple-targets:before {
    content: "\FF538";
}
.game-icon-mummy-head:before {
    content: "\FF539";
}
.game-icon-muscular-torso:before {
    content: "\FF53A";
}
.game-icon-mushroom-house:before {
    content: "\FF53B";
}
.game-icon-mushrooms-cluster:before {
    content: "\FF53C";
}
.game-icon-mushrooms:before {
    content: "\FF53D";
}
.game-icon-musical-keyboard:before {
    content: "\FF53E";
}
.game-icon-musical-notes:before {
    content: "\FF53F";
}
.game-icon-musical-score:before {
    content: "\FF540";
}
.game-icon-mussel:before {
    content: "\FF541";
}
.game-icon-mustache:before {
    content: "\FF542";
}
.game-icon-mute:before {
    content: "\FF543";
}
.game-icon-nachos:before {
    content: "\FF544";
}
.game-icon-nano-bot:before {
    content: "\FF545";
}
.game-icon-neck-bite:before {
    content: "\FF546";
}
.game-icon-necklace-display:before {
    content: "\FF547";
}
.game-icon-nefertiti:before {
    content: "\FF548";
}
.game-icon-nest-birds:before {
    content: "\FF549";
}
.game-icon-nest-eggs:before {
    content: "\FF54A";
}
.game-icon-nested-hearts:before {
    content: "\FF54B";
}
.game-icon-nested-hexagons:before {
    content: "\FF54C";
}
.game-icon-network-bars:before {
    content: "\FF54D";
}
.game-icon-new-born:before {
    content: "\FF54E";
}
.game-icon-newspaper:before {
    content: "\FF54F";
}
.game-icon-next-button:before {
    content: "\FF550";
}
.game-icon-nigeria:before {
    content: "\FF551";
}
.game-icon-night-sleep:before {
    content: "\FF552";
}
.game-icon-night-vision:before {
    content: "\FF553";
}
.game-icon-ninja-armor:before {
    content: "\FF554";
}
.game-icon-ninja-velociraptor:before {
    content: "\FF555";
}
.game-icon-noodles:before {
    content: "\FF556";
}
.game-icon-nose-front:before {
    content: "\FF557";
}
.game-icon-nose-side:before {
    content: "\FF558";
}
.game-icon-notebook:before {
    content: "\FF559";
}
.game-icon-nuclear-plant:before {
    content: "\FF55A";
}
.game-icon-nuclear-waste:before {
    content: "\FF55B";
}
.game-icon-nunchaku:before {
    content: "\FF55C";
}
.game-icon-nurse-female:before {
    content: "\FF55D";
}
.game-icon-nurse-male:before {
    content: "\FF55E";
}
.game-icon-o-brick:before {
    content: "\FF55F";
}
.game-icon-oak-leaf:before {
    content: "\FF560";
}
.game-icon-oasis:before {
    content: "\FF561";
}
.game-icon-obelisk:before {
    content: "\FF562";
}
.game-icon-observatory:before {
    content: "\FF563";
}
.game-icon-ocarina:before {
    content: "\FF564";
}
.game-icon-oden:before {
    content: "\FF565";
}
.game-icon-office-chair:before {
    content: "\FF566";
}
.game-icon-offshore-platform:before {
    content: "\FF567";
}
.game-icon-ogre:before {
    content: "\FF568";
}
.game-icon-oil-pump:before {
    content: "\FF569";
}
.game-icon-oil-rig:before {
    content: "\FF56A";
}
.game-icon-old-lantern:before {
    content: "\FF56B";
}
.game-icon-old-microphone:before {
    content: "\FF56C";
}
.game-icon-old-wagon:before {
    content: "\FF56D";
}
.game-icon-olive:before {
    content: "\FF56E";
}
.game-icon-oni:before {
    content: "\FF56F";
}
.game-icon-onigori:before {
    content: "\FF570";
}
.game-icon-open-folder:before {
    content: "\FF571";
}
.game-icon-open-gate:before {
    content: "\FF572";
}
.game-icon-opened-food-can:before {
    content: "\FF573";
}
.game-icon-ophiuchus:before {
    content: "\FF574";
}
.game-icon-opposite-hearts:before {
    content: "\FF575";
}
.game-icon-orange-slice:before {
    content: "\FF576";
}
.game-icon-orange:before {
    content: "\FF577";
}
.game-icon-orc-head:before {
    content: "\FF578";
}
.game-icon-organigram:before {
    content: "\FF579";
}
.game-icon-ostrich:before {
    content: "\FF57A";
}
.game-icon-outback-hat:before {
    content: "\FF57B";
}
.game-icon-overlord-helm:before {
    content: "\FF57C";
}
.game-icon-oyster-pearl:before {
    content: "\FF57D";
}
.game-icon-oyster:before {
    content: "\FF57E";
}
.game-icon-packed-planks:before {
    content: "\FF57F";
}
.game-icon-paddle-steamer:before {
    content: "\FF580";
}
.game-icon-paddles:before {
    content: "\FF581";
}
.game-icon-padlock-open:before {
    content: "\FF582";
}
.game-icon-pagoda:before {
    content: "\FF583";
}
.game-icon-paint-brush:before {
    content: "\FF584";
}
.game-icon-paint-bucket:before {
    content: "\FF585";
}
.game-icon-paint-roller:before {
    content: "\FF586";
}
.game-icon-painted-pottery:before {
    content: "\FF587";
}
.game-icon-palette:before {
    content: "\FF588";
}
.game-icon-palisade:before {
    content: "\FF589";
}
.game-icon-palm-tree:before {
    content: "\FF58A";
}
.game-icon-pan-flute:before {
    content: "\FF58B";
}
.game-icon-panda:before {
    content: "\FF58C";
}
.game-icon-panzerfaust:before {
    content: "\FF58D";
}
.game-icon-paper-boat:before {
    content: "\FF58E";
}
.game-icon-paper-clip:before {
    content: "\FF58F";
}
.game-icon-paper-plane:before {
    content: "\FF590";
}
.game-icon-paper-tray:before {
    content: "\FF591";
}
.game-icon-paper-windmill:before {
    content: "\FF592";
}
.game-icon-papyrus:before {
    content: "\FF593";
}
.game-icon-paraguay:before {
    content: "\FF594";
}
.game-icon-parasaurolophus:before {
    content: "\FF595";
}
.game-icon-park-bench:before {
    content: "\FF596";
}
.game-icon-party-flags:before {
    content: "\FF597";
}
.game-icon-party-hat:before {
    content: "\FF598";
}
.game-icon-party-popper:before {
    content: "\FF599";
}
.game-icon-passport:before {
    content: "\FF59A";
}
.game-icon-path-distance:before {
    content: "\FF59B";
}
.game-icon-path-tile:before {
    content: "\FF59C";
}
.game-icon-pay-money:before {
    content: "\FF59D";
}
.game-icon-peace-dove:before {
    content: "\FF59E";
}
.game-icon-peach:before {
    content: "\FF59F";
}
.game-icon-pear:before {
    content: "\FF5A0";
}
.game-icon-pearl-earring:before {
    content: "\FF5A1";
}
.game-icon-pearl-necklace:before {
    content: "\FF5A2";
}
.game-icon-peas:before {
    content: "\FF5A3";
}
.game-icon-pelvis-bone:before {
    content: "\FF5A4";
}
.game-icon-pencil-brush:before {
    content: "\FF5A5";
}
.game-icon-pencil-ruler:before {
    content: "\FF5A6";
}
.game-icon-pencil:before {
    content: "\FF5A7";
}
.game-icon-pendant-key:before {
    content: "\FF5A8";
}
.game-icon-pendulum-swing:before {
    content: "\FF5A9";
}
.game-icon-penguin:before {
    content: "\FF5AA";
}
.game-icon-periscope:before {
    content: "\FF5AB";
}
.game-icon-person-in-bed:before {
    content: "\FF5AC";
}
.game-icon-person:before {
    content: "\FF5AD";
}
.game-icon-perspective-dice-five:before {
    content: "\FF5AE";
}
.game-icon-perspective-dice-four:before {
    content: "\FF5AF";
}
.game-icon-perspective-dice-one:before {
    content: "\FF5B0";
}
.game-icon-perspective-dice-six-faces-five:before {
    content: "\FF5B1";
}
.game-icon-perspective-dice-six-faces-four:before {
    content: "\FF5B2";
}
.game-icon-perspective-dice-six-faces-one:before {
    content: "\FF5B3";
}
.game-icon-perspective-dice-six-faces-random:before {
    content: "\FF5B4";
}
.game-icon-perspective-dice-six-faces-six:before {
    content: "\FF5B5";
}
.game-icon-perspective-dice-six-faces-three:before {
    content: "\FF5B6";
}
.game-icon-perspective-dice-six-faces-two:before {
    content: "\FF5B7";
}
.game-icon-perspective-dice-six:before {
    content: "\FF5B8";
}
.game-icon-perspective-dice-three:before {
    content: "\FF5B9";
}
.game-icon-perspective-dice-two:before {
    content: "\FF5BA";
}
.game-icon-peru:before {
    content: "\FF5BB";
}
.game-icon-pharoah:before {
    content: "\FF5BC";
}
.game-icon-phone:before {
    content: "\FF5BD";
}
.game-icon-photo-camera:before {
    content: "\FF5BE";
}
.game-icon-phrygian-cap:before {
    content: "\FF5BF";
}
.game-icon-piano-keys:before {
    content: "\FF5C0";
}
.game-icon-pick-of-destiny:before {
    content: "\FF5C1";
}
.game-icon-pickle:before {
    content: "\FF5C2";
}
.game-icon-pie-chart:before {
    content: "\FF5C3";
}
.game-icon-piggy-bank:before {
    content: "\FF5C4";
}
.game-icon-pikeman:before {
    content: "\FF5C5";
}
.game-icon-pilgrim-hat:before {
    content: "\FF5C6";
}
.game-icon-pillow:before {
    content: "\FF5C7";
}
.game-icon-pimiento:before {
    content: "\FF5C8";
}
.game-icon-pin:before {
    content: "\FF5C9";
}
.game-icon-pinata:before {
    content: "\FF5CA";
}
.game-icon-pinball-flipper:before {
    content: "\FF5CB";
}
.game-icon-pineapple:before {
    content: "\FF5CC";
}
.game-icon-ping-pong-bat:before {
    content: "\FF5CD";
}
.game-icon-pipes:before {
    content: "\FF5CE";
}
.game-icon-piranha:before {
    content: "\FF5CF";
}
.game-icon-pirate-cannon:before {
    content: "\FF5D0";
}
.game-icon-pirate-captain:before {
    content: "\FF5D1";
}
.game-icon-pirate-coat:before {
    content: "\FF5D2";
}
.game-icon-pirate-flag:before {
    content: "\FF5D3";
}
.game-icon-pirate-hat:before {
    content: "\FF5D4";
}
.game-icon-pirate-hook:before {
    content: "\FF5D5";
}
.game-icon-pisa-tower:before {
    content: "\FF5D6";
}
.game-icon-pisces:before {
    content: "\FF5D7";
}
.game-icon-pitchfork:before {
    content: "\FF5D8";
}
.game-icon-pizza-slice:before {
    content: "\FF5D9";
}
.game-icon-plague-doctor-profile:before {
    content: "\FF5DA";
}
.game-icon-plain-arrow:before {
    content: "\FF5DB";
}
.game-icon-plain-circle:before {
    content: "\FF5DC";
}
.game-icon-plain-square:before {
    content: "\FF5DD";
}
.game-icon-plane-pilot:before {
    content: "\FF5DE";
}
.game-icon-plane-wing:before {
    content: "\FF5DF";
}
.game-icon-planet-conquest:before {
    content: "\FF5E0";
}
.game-icon-planet-core:before {
    content: "\FF5E1";
}
.game-icon-planks:before {
    content: "\FF5E2";
}
.game-icon-plant-roots:before {
    content: "\FF5E3";
}
.game-icon-plant-seed:before {
    content: "\FF5E4";
}
.game-icon-plant-watering:before {
    content: "\FF5E5";
}
.game-icon-plastic-duck:before {
    content: "\FF5E6";
}
.game-icon-player-base:before {
    content: "\FF5E7";
}
.game-icon-player-next:before {
    content: "\FF5E8";
}
.game-icon-player-previous:before {
    content: "\FF5E9";
}
.game-icon-player-time:before {
    content: "\FF5EA";
}
.game-icon-plesiosaurus:before {
    content: "\FF5EB";
}
.game-icon-plow:before {
    content: "\FF5EC";
}
.game-icon-plug:before {
    content: "\FF5ED";
}
.game-icon-plunger:before {
    content: "\FF5EE";
}
.game-icon-pocket-radio:before {
    content: "\FF5EF";
}
.game-icon-podium-second:before {
    content: "\FF5F0";
}
.game-icon-podium-third:before {
    content: "\FF5F1";
}
.game-icon-podium-winner:before {
    content: "\FF5F2";
}
.game-icon-podium:before {
    content: "\FF5F3";
}
.game-icon-poland:before {
    content: "\FF5F4";
}
.game-icon-polar-star:before {
    content: "\FF5F5";
}
.game-icon-police-car:before {
    content: "\FF5F6";
}
.game-icon-police-officer-head:before {
    content: "\FF5F7";
}
.game-icon-police-target:before {
    content: "\FF5F8";
}
.game-icon-polo-shirt:before {
    content: "\FF5F9";
}
.game-icon-poncho:before {
    content: "\FF5FA";
}
.game-icon-pool-dive:before {
    content: "\FF5FB";
}
.game-icon-pool-table-corner:before {
    content: "\FF5FC";
}
.game-icon-pool-triangle:before {
    content: "\FF5FD";
}
.game-icon-popcorn:before {
    content: "\FF5FE";
}
.game-icon-pope-crown:before {
    content: "\FF5FF";
}
.game-icon-porcelain-vase:before {
    content: "\FF600";
}
.game-icon-porcupinefish:before {
    content: "\FF601";
}
.game-icon-portrait:before {
    content: "\FF602";
}
.game-icon-portugal:before {
    content: "\FF603";
}
.game-icon-position-marker:before {
    content: "\FF604";
}
.game-icon-post-office:before {
    content: "\FF605";
}
.game-icon-post-stamp:before {
    content: "\FF606";
}
.game-icon-potato:before {
    content: "\FF607";
}
.game-icon-pouring-pot:before {
    content: "\FF608";
}
.game-icon-powder-bag:before {
    content: "\FF609";
}
.game-icon-power-generator:before {
    content: "\FF60A";
}
.game-icon-power-ring:before {
    content: "\FF60B";
}
.game-icon-prayer-beads:before {
    content: "\FF60C";
}
.game-icon-praying-mantis:before {
    content: "\FF60D";
}
.game-icon-present:before {
    content: "\FF60E";
}
.game-icon-pretzel:before {
    content: "\FF60F";
}
.game-icon-previous-button:before {
    content: "\FF610";
}
.game-icon-price-tag:before {
    content: "\FF611";
}
.game-icon-primitive-necklace:before {
    content: "\FF612";
}
.game-icon-primitive-torch:before {
    content: "\FF613";
}
.game-icon-prism:before {
    content: "\FF614";
}
.game-icon-prisoner:before {
    content: "\FF615";
}
.game-icon-private-first-class:before {
    content: "\FF616";
}
.game-icon-private:before {
    content: "\FF617";
}
.game-icon-progression:before {
    content: "\FF618";
}
.game-icon-propeller-beanie:before {
    content: "\FF619";
}
.game-icon-protection-glasses:before {
    content: "\FF61A";
}
.game-icon-pschent-double-crown:before {
    content: "\FF61B";
}
.game-icon-pterodactylus:before {
    content: "\FF61C";
}
.game-icon-public-speaker:before {
    content: "\FF61D";
}
.game-icon-pull:before {
    content: "\FF61E";
}
.game-icon-pulley-hook:before {
    content: "\FF61F";
}
.game-icon-pumpkin:before {
    content: "\FF620";
}
.game-icon-punching-bag:before {
    content: "\FF621";
}
.game-icon-purple-tentacle:before {
    content: "\FF622";
}
.game-icon-push:before {
    content: "\FF623";
}
.game-icon-puzzle:before {
    content: "\FF624";
}
.game-icon-pylon:before {
    content: "\FF625";
}
.game-icon-pyre:before {
    content: "\FF626";
}
.game-icon-qaitbay-citadel:before {
    content: "\FF627";
}
.game-icon-quick-man:before {
    content: "\FF628";
}
.game-icon-quiver:before {
    content: "\FF629";
}
.game-icon-rabbit-head:before {
    content: "\FF62A";
}
.game-icon-rabbit:before {
    content: "\FF62B";
}
.game-icon-raccoon-head:before {
    content: "\FF62C";
}
.game-icon-radar-cross-section:before {
    content: "\FF62D";
}
.game-icon-raddish:before {
    content: "\FF62E";
}
.game-icon-radiations:before {
    content: "\FF62F";
}
.game-icon-radio-tower:before {
    content: "\FF630";
}
.game-icon-raft:before {
    content: "\FF631";
}
.game-icon-rail-road:before {
    content: "\FF632";
}
.game-icon-railway:before {
    content: "\FF633";
}
.game-icon-rake:before {
    content: "\FF634";
}
.game-icon-ram-profile:before {
    content: "\FF635";
}
.game-icon-ranch-gate:before {
    content: "\FF636";
}
.game-icon-raspberry:before {
    content: "\FF637";
}
.game-icon-rat:before {
    content: "\FF638";
}
.game-icon-rattlesnake:before {
    content: "\FF639";
}
.game-icon-raw-egg:before {
    content: "\FF63A";
}
.game-icon-razor:before {
    content: "\FF63B";
}
.game-icon-receive-money:before {
    content: "\FF63C";
}
.game-icon-red-carpet:before {
    content: "\FF63D";
}
.game-icon-reed:before {
    content: "\FF63E";
}
.game-icon-refinery:before {
    content: "\FF63F";
}
.game-icon-reload-gun-barrel:before {
    content: "\FF640";
}
.game-icon-remedy:before {
    content: "\FF641";
}
.game-icon-rempart:before {
    content: "\FF642";
}
.game-icon-reptile-tail:before {
    content: "\FF643";
}
.game-icon-resize:before {
    content: "\FF644";
}
.game-icon-resting-vampire:before {
    content: "\FF645";
}
.game-icon-revolver:before {
    content: "\FF646";
}
.game-icon-rhinoceros-horn:before {
    content: "\FF647";
}
.game-icon-rialto-bridge:before {
    content: "\FF648";
}
.game-icon-ribbon-medal:before {
    content: "\FF649";
}
.game-icon-ribbon-shield:before {
    content: "\FF64A";
}
.game-icon-ring-box:before {
    content: "\FF64B";
}
.game-icon-ring-mould:before {
    content: "\FF64C";
}
.game-icon-ring:before {
    content: "\FF64D";
}
.game-icon-ringing-alarm:before {
    content: "\FF64E";
}
.game-icon-ringmaster:before {
    content: "\FF64F";
}
.game-icon-river:before {
    content: "\FF650";
}
.game-icon-road:before {
    content: "\FF651";
}
.game-icon-robber-mask:before {
    content: "\FF652";
}
.game-icon-robber:before {
    content: "\FF653";
}
.game-icon-robin-hood-hat:before {
    content: "\FF654";
}
.game-icon-robot-antennas:before {
    content: "\FF655";
}
.game-icon-robot-grab:before {
    content: "\FF656";
}
.game-icon-robot-helmet:before {
    content: "\FF657";
}
.game-icon-robot-leg:before {
    content: "\FF658";
}
.game-icon-rock-golem:before {
    content: "\FF659";
}
.game-icon-rocket-thruster:before {
    content: "\FF65A";
}
.game-icon-rocking-chair:before {
    content: "\FF65B";
}
.game-icon-rod-of-asclepius:before {
    content: "\FF65C";
}
.game-icon-rolled-cloth:before {
    content: "\FF65D";
}
.game-icon-roller-skate:before {
    content: "\FF65E";
}
.game-icon-rolling-dice-cup:before {
    content: "\FF65F";
}
.game-icon-rolling-dices:before {
    content: "\FF660";
}
.game-icon-roman-shield:before {
    content: "\FF661";
}
.game-icon-roman-toga:before {
    content: "\FF662";
}
.game-icon-rooster:before {
    content: "\FF663";
}
.game-icon-rope-bridge:before {
    content: "\FF664";
}
.game-icon-rope-coil:before {
    content: "\FF665";
}
.game-icon-rope-dart:before {
    content: "\FF666";
}
.game-icon-ropeway:before {
    content: "\FF667";
}
.game-icon-round-knob:before {
    content: "\FF668";
}
.game-icon-round-silo:before {
    content: "\FF669";
}
.game-icon-round-star:before {
    content: "\FF66A";
}
.game-icon-round-straw-bale:before {
    content: "\FF66B";
}
.game-icon-round-table:before {
    content: "\FF66C";
}
.game-icon-rss:before {
    content: "\FF66D";
}
.game-icon-rub-el-hizb:before {
    content: "\FF66E";
}
.game-icon-rubber-boot:before {
    content: "\FF66F";
}
.game-icon-rugby-conversion:before {
    content: "\FF670";
}
.game-icon-rule-book:before {
    content: "\FF671";
}
.game-icon-running-shoe:before {
    content: "\FF672";
}
.game-icon-rupee:before {
    content: "\FF673";
}
.game-icon-rusty-sword:before {
    content: "\FF674";
}
.game-icon-s-brick:before {
    content: "\FF675";
}
.game-icon-saber-and-pistol:before {
    content: "\FF676";
}
.game-icon-saber-toothed-cat-head:before {
    content: "\FF677";
}
.game-icon-saddle:before {
    content: "\FF678";
}
.game-icon-safety-pin:before {
    content: "\FF679";
}
.game-icon-sagittarius:before {
    content: "\FF67A";
}
.game-icon-sai:before {
    content: "\FF67B";
}
.game-icon-sail:before {
    content: "\FF67C";
}
.game-icon-sailboat:before {
    content: "\FF67D";
}
.game-icon-saint-basil-cathedral:before {
    content: "\FF67E";
}
.game-icon-saiyan-suit:before {
    content: "\FF67F";
}
.game-icon-saloon-doors:before {
    content: "\FF680";
}
.game-icon-saloon:before {
    content: "\FF681";
}
.game-icon-samara-mosque:before {
    content: "\FF682";
}
.game-icon-samurai-helmet:before {
    content: "\FF683";
}
.game-icon-samus-helmet:before {
    content: "\FF684";
}
.game-icon-sand-castle:before {
    content: "\FF685";
}
.game-icon-sand-snake:before {
    content: "\FF686";
}
.game-icon-sandal:before {
    content: "\FF687";
}
.game-icon-sandstorm:before {
    content: "\FF688";
}
.game-icon-sandwich:before {
    content: "\FF689";
}
.game-icon-sarcophagus:before {
    content: "\FF68A";
}
.game-icon-sasquatch:before {
    content: "\FF68B";
}
.game-icon-satellite-communication:before {
    content: "\FF68C";
}
.game-icon-sauropod-head:before {
    content: "\FF68D";
}
.game-icon-sausage:before {
    content: "\FF68E";
}
.game-icon-sausages-ribbon:before {
    content: "\FF68F";
}
.game-icon-save-arrow:before {
    content: "\FF690";
}
.game-icon-save:before {
    content: "\FF691";
}
.game-icon-sawed-off-shotgun:before {
    content: "\FF692";
}
.game-icon-saxophone:before {
    content: "\FF693";
}
.game-icon-scabbard:before {
    content: "\FF694";
}
.game-icon-school-bag:before {
    content: "\FF695";
}
.game-icon-school-of-fish:before {
    content: "\FF696";
}
.game-icon-scooter:before {
    content: "\FF697";
}
.game-icon-scorpio:before {
    content: "\FF698";
}
.game-icon-scout-ship:before {
    content: "\FF699";
}
.game-icon-screw:before {
    content: "\FF69A";
}
.game-icon-scroll-quill:before {
    content: "\FF69B";
}
.game-icon-scuba-mask:before {
    content: "\FF69C";
}
.game-icon-scuba-tanks:before {
    content: "\FF69D";
}
.game-icon-sea-cliff:before {
    content: "\FF69E";
}
.game-icon-sea-star:before {
    content: "\FF69F";
}
.game-icon-sea-turtle:before {
    content: "\FF6A0";
}
.game-icon-seagull:before {
    content: "\FF6A1";
}
.game-icon-seahorse:before {
    content: "\FF6A2";
}
.game-icon-secret-book:before {
    content: "\FF6A3";
}
.game-icon-secret-door:before {
    content: "\FF6A4";
}
.game-icon-security-gate:before {
    content: "\FF6A5";
}
.game-icon-seedling:before {
    content: "\FF6A6";
}
.game-icon-sell-card:before {
    content: "\FF6A7";
}
.game-icon-sergeant:before {
    content: "\FF6A8";
}
.game-icon-server-rack:before {
    content: "\FF6A9";
}
.game-icon-sesame:before {
    content: "\FF6AA";
}
.game-icon-settings-knobs:before {
    content: "\FF6AB";
}
.game-icon-seven-pointed-star:before {
    content: "\FF6AC";
}
.game-icon-sewing-string:before {
    content: "\FF6AD";
}
.game-icon-sextant:before {
    content: "\FF6AE";
}
.game-icon-shaking-hands:before {
    content: "\FF6AF";
}
.game-icon-shambling-mound:before {
    content: "\FF6B0";
}
.game-icon-shambling-zombie:before {
    content: "\FF6B1";
}
.game-icon-shamrock:before {
    content: "\FF6B2";
}
.game-icon-share:before {
    content: "\FF6B3";
}
.game-icon-shark-bite:before {
    content: "\FF6B4";
}
.game-icon-shark-fin:before {
    content: "\FF6B5";
}
.game-icon-sharp-axe:before {
    content: "\FF6B6";
}
.game-icon-sharp-halberd:before {
    content: "\FF6B7";
}
.game-icon-shattered-heart:before {
    content: "\FF6B8";
}
.game-icon-shears:before {
    content: "\FF6B9";
}
.game-icon-sheep:before {
    content: "\FF6BA";
}
.game-icon-sherlock-holmes:before {
    content: "\FF6BB";
}
.game-icon-shield-bash:before {
    content: "\FF6BC";
}
.game-icon-shield-impact:before {
    content: "\FF6BD";
}
.game-icon-shield-opposition:before {
    content: "\FF6BE";
}
.game-icon-shinto-shrine-mirror:before {
    content: "\FF6BF";
}
.game-icon-shinto-shrine:before {
    content: "\FF6C0";
}
.game-icon-ship-bow:before {
    content: "\FF6C1";
}
.game-icon-ship-wheel:before {
    content: "\FF6C2";
}
.game-icon-ship-wreck:before {
    content: "\FF6C3";
}
.game-icon-shirt-button:before {
    content: "\FF6C4";
}
.game-icon-shoebill-stork:before {
    content: "\FF6C5";
}
.game-icon-shop:before {
    content: "\FF6C6";
}
.game-icon-shopping-bag:before {
    content: "\FF6C7";
}
.game-icon-shopping-cart:before {
    content: "\FF6C8";
}
.game-icon-shorts:before {
    content: "\FF6C9";
}
.game-icon-shotgun-rounds:before {
    content: "\FF6CA";
}
.game-icon-shoulder-armor:before {
    content: "\FF6CB";
}
.game-icon-shoulder-bag:before {
    content: "\FF6CC";
}
.game-icon-shower:before {
    content: "\FF6CD";
}
.game-icon-shrimp:before {
    content: "\FF6CE";
}
.game-icon-shrug:before {
    content: "\FF6CF";
}
.game-icon-shuttlecock:before {
    content: "\FF6D0";
}
.game-icon-sickle:before {
    content: "\FF6D1";
}
.game-icon-siege-tower:before {
    content: "\FF6D2";
}
.game-icon-silenced:before {
    content: "\FF6D3";
}
.game-icon-silex:before {
    content: "\FF6D4";
}
.game-icon-silver-bullet:before {
    content: "\FF6D5";
}
.game-icon-sinagot:before {
    content: "\FF6D6";
}
.game-icon-sinking-ship:before {
    content: "\FF6D7";
}
.game-icon-sitting-dog:before {
    content: "\FF6D8";
}
.game-icon-skateboard:before {
    content: "\FF6D9";
}
.game-icon-skeletal-hand:before {
    content: "\FF6DA";
}
.game-icon-ski-boot:before {
    content: "\FF6DB";
}
.game-icon-skier:before {
    content: "\FF6DC";
}
.game-icon-skills:before {
    content: "\FF6DD";
}
.game-icon-skipping-rope:before {
    content: "\FF6DE";
}
.game-icon-skirt:before {
    content: "\FF6DF";
}
.game-icon-skis:before {
    content: "\FF6E0";
}
.game-icon-skull-staff:before {
    content: "\FF6E1";
}
.game-icon-slalom:before {
    content: "\FF6E2";
}
.game-icon-sleeping-bag:before {
    content: "\FF6E3";
}
.game-icon-sleeveless-jacket:before {
    content: "\FF6E4";
}
.game-icon-sleeveless-top:before {
    content: "\FF6E5";
}
.game-icon-sliced-mushroom:before {
    content: "\FF6E6";
}
.game-icon-sliced-sausage:before {
    content: "\FF6E7";
}
.game-icon-slime:before {
    content: "\FF6E8";
}
.game-icon-sling:before {
    content: "\FF6E9";
}
.game-icon-slingshot:before {
    content: "\FF6EA";
}
.game-icon-slipknot:before {
    content: "\FF6EB";
}
.game-icon-slippers:before {
    content: "\FF6EC";
}
.game-icon-slumbering-sanctuary:before {
    content: "\FF6ED";
}
.game-icon-smart:before {
    content: "\FF6EE";
}
.game-icon-smartphone:before {
    content: "\FF6EF";
}
.game-icon-smoking-pipe:before {
    content: "\FF6F0";
}
.game-icon-smoking-volcano:before {
    content: "\FF6F1";
}
.game-icon-snail-eyes:before {
    content: "\FF6F2";
}
.game-icon-snake-egg:before {
    content: "\FF6F3";
}
.game-icon-snake-jar:before {
    content: "\FF6F4";
}
.game-icon-snake-spiral:before {
    content: "\FF6F5";
}
.game-icon-snake-tongue:before {
    content: "\FF6F6";
}
.game-icon-sniffing-dog:before {
    content: "\FF6F7";
}
.game-icon-snitch-quidditch-ball:before {
    content: "\FF6F8";
}
.game-icon-snowboard:before {
    content: "\FF6F9";
}
.game-icon-soap:before {
    content: "\FF6FA";
}
.game-icon-soccer-ball:before {
    content: "\FF6FB";
}
.game-icon-soccer-field:before {
    content: "\FF6FC";
}
.game-icon-soccer-kick:before {
    content: "\FF6FD";
}
.game-icon-socks:before {
    content: "\FF6FE";
}
.game-icon-sofa:before {
    content: "\FF6FF";
}
.game-icon-solar-system:before {
    content: "\FF700";
}
.game-icon-solar-time:before {
    content: "\FF701";
}
.game-icon-solid-leaf:before {
    content: "\FF702";
}
.game-icon-sombrero:before {
    content: "\FF703";
}
.game-icon-sonic-shoes:before {
    content: "\FF704";
}
.game-icon-soul-vessel:before {
    content: "\FF705";
}
.game-icon-sound-off:before {
    content: "\FF706";
}
.game-icon-sound-on:before {
    content: "\FF707";
}
.game-icon-south-africa-flag:before {
    content: "\FF708";
}
.game-icon-south-africa:before {
    content: "\FF709";
}
.game-icon-south-america:before {
    content: "\FF70A";
}
.game-icon-south-korea:before {
    content: "\FF70B";
}
.game-icon-space-needle:before {
    content: "\FF70C";
}
.game-icon-space-shuttle:before {
    content: "\FF70D";
}
.game-icon-spaceship:before {
    content: "\FF70E";
}
.game-icon-spain:before {
    content: "\FF70F";
}
.game-icon-spark-plug:before {
    content: "\FF710";
}
.game-icon-sparkles:before {
    content: "\FF711";
}
.game-icon-spartan-helmet:before {
    content: "\FF712";
}
.game-icon-speaker-off:before {
    content: "\FF713";
}
.game-icon-speaker:before {
    content: "\FF714";
}
.game-icon-spear-feather:before {
    content: "\FF715";
}
.game-icon-spearfishing:before {
    content: "\FF716";
}
.game-icon-speed-boat:before {
    content: "\FF717";
}
.game-icon-speedometer:before {
    content: "\FF718";
}
.game-icon-spell-book:before {
    content: "\FF719";
}
.game-icon-sperm-whale:before {
    content: "\FF71A";
}
.game-icon-spider-bot:before {
    content: "\FF71B";
}
.game-icon-spider-eye:before {
    content: "\FF71C";
}
.game-icon-spider-mask:before {
    content: "\FF71D";
}
.game-icon-spiked-dragon-head:before {
    content: "\FF71E";
}
.game-icon-spiked-shield:before {
    content: "\FF71F";
}
.game-icon-spiked-shoulder-armor:before {
    content: "\FF720";
}
.game-icon-spiked-trunk:before {
    content: "\FF721";
}
.game-icon-spiked-wall:before {
    content: "\FF722";
}
.game-icon-spiky-pit:before {
    content: "\FF723";
}
.game-icon-spiky-wing:before {
    content: "\FF724";
}
.game-icon-spiral-hilt:before {
    content: "\FF725";
}
.game-icon-spiral-lollipop:before {
    content: "\FF726";
}
.game-icon-split-arrows:before {
    content: "\FF727";
}
.game-icon-spock-hand:before {
    content: "\FF728";
}
.game-icon-spooky-house:before {
    content: "\FF729";
}
.game-icon-sport-medal:before {
    content: "\FF72A";
}
.game-icon-spring:before {
    content: "\FF72B";
}
.game-icon-spy:before {
    content: "\FF72C";
}
.game-icon-square:before {
    content: "\FF72D";
}
.game-icon-squirrel:before {
    content: "\FF72E";
}
.game-icon-sri-lanka:before {
    content: "\FF72F";
}
.game-icon-stabbed-note:before {
    content: "\FF730";
}
.game-icon-stable:before {
    content: "\FF731";
}
.game-icon-stack:before {
    content: "\FF732";
}
.game-icon-stairs-cake:before {
    content: "\FF733";
}
.game-icon-stairs-goal:before {
    content: "\FF734";
}
.game-icon-stairs:before {
    content: "\FF735";
}
.game-icon-stake-hammer:before {
    content: "\FF736";
}
.game-icon-stakes-fence:before {
    content: "\FF737";
}
.game-icon-stalactites:before {
    content: "\FF738";
}
.game-icon-stamper:before {
    content: "\FF739";
}
.game-icon-star-altar:before {
    content: "\FF73A";
}
.game-icon-star-flag:before {
    content: "\FF73B";
}
.game-icon-star-formation:before {
    content: "\FF73C";
}
.game-icon-star-gate:before {
    content: "\FF73D";
}
.game-icon-star-key:before {
    content: "\FF73E";
}
.game-icon-star-medal:before {
    content: "\FF73F";
}
.game-icon-star-struck:before {
    content: "\FF740";
}
.game-icon-starfighter:before {
    content: "\FF741";
}
.game-icon-stars-stack:before {
    content: "\FF742";
}
.game-icon-steak:before {
    content: "\FF743";
}
.game-icon-stealth-bomber:before {
    content: "\FF744";
}
.game-icon-steam-blast:before {
    content: "\FF745";
}
.game-icon-steam-locomotive:before {
    content: "\FF746";
}
.game-icon-steam:before {
    content: "\FF747";
}
.game-icon-steampunk-goggles:before {
    content: "\FF748";
}
.game-icon-steering-wheel:before {
    content: "\FF749";
}
.game-icon-stegosaurus-scales:before {
    content: "\FF74A";
}
.game-icon-stethoscope:before {
    content: "\FF74B";
}
.game-icon-stick-frame:before {
    content: "\FF74C";
}
.game-icon-sticky-boot:before {
    content: "\FF74D";
}
.game-icon-stockpiles:before {
    content: "\FF74E";
}
.game-icon-stomach:before {
    content: "\FF74F";
}
.game-icon-stone-bridge:before {
    content: "\FF750";
}
.game-icon-stone-bust:before {
    content: "\FF751";
}
.game-icon-stone-path:before {
    content: "\FF752";
}
.game-icon-stone-pile:before {
    content: "\FF753";
}
.game-icon-stone-stack:before {
    content: "\FF754";
}
.game-icon-stone-wall:before {
    content: "\FF755";
}
.game-icon-stone-wheel:before {
    content: "\FF756";
}
.game-icon-stop-sign:before {
    content: "\FF757";
}
.game-icon-stork-delivery:before {
    content: "\FF758";
}
.game-icon-straight-pipe:before {
    content: "\FF759";
}
.game-icon-strawberry:before {
    content: "\FF75A";
}
.game-icon-street-light:before {
    content: "\FF75B";
}
.game-icon-striped-sun:before {
    content: "\FF75C";
}
.game-icon-strong-man:before {
    content: "\FF75D";
}
.game-icon-strongbox:before {
    content: "\FF75E";
}
.game-icon-stump-regrowth:before {
    content: "\FF75F";
}
.game-icon-stun-grenade:before {
    content: "\FF760";
}
.game-icon-submarine-missile:before {
    content: "\FF761";
}
.game-icon-submarine:before {
    content: "\FF762";
}
.game-icon-subway:before {
    content: "\FF763";
}
.game-icon-sugar-cane:before {
    content: "\FF764";
}
.game-icon-suitcase:before {
    content: "\FF765";
}
.game-icon-summits:before {
    content: "\FF766";
}
.game-icon-sun-cloud:before {
    content: "\FF767";
}
.game-icon-sun-priest:before {
    content: "\FF768";
}
.game-icon-sun-spear:before {
    content: "\FF769";
}
.game-icon-sunflower:before {
    content: "\FF76A";
}
.game-icon-sunglasses:before {
    content: "\FF76B";
}
.game-icon-sunrise:before {
    content: "\FF76C";
}
.game-icon-sunset:before {
    content: "\FF76D";
}
.game-icon-surf-board:before {
    content: "\FF76E";
}
.game-icon-surfer-van:before {
    content: "\FF76F";
}
.game-icon-sushis:before {
    content: "\FF770";
}
.game-icon-suspension-bridge:before {
    content: "\FF771";
}
.game-icon-sverd-i-fjell:before {
    content: "\FF772";
}
.game-icon-swallow:before {
    content: "\FF773";
}
.game-icon-swallower:before {
    content: "\FF774";
}
.game-icon-swamp-bat:before {
    content: "\FF775";
}
.game-icon-swamp:before {
    content: "\FF776";
}
.game-icon-swimfins:before {
    content: "\FF777";
}
.game-icon-swipe-card:before {
    content: "\FF778";
}
.game-icon-swiss-army-knife:before {
    content: "\FF779";
}
.game-icon-switch-weapon:before {
    content: "\FF77A";
}
.game-icon-switzerland:before {
    content: "\FF77B";
}
.game-icon-sword-altar:before {
    content: "\FF77C";
}
.game-icon-sword-brandish:before {
    content: "\FF77D";
}
.game-icon-sword-mold:before {
    content: "\FF77E";
}
.game-icon-swords-power:before {
    content: "\FF77F";
}
.game-icon-sydney-opera-house:before {
    content: "\FF780";
}
.game-icon-t-brick:before {
    content: "\FF781";
}
.game-icon-t-rex-skull:before {
    content: "\FF782";
}
.game-icon-t-shirt:before {
    content: "\FF783";
}
.game-icon-table:before {
    content: "\FF784";
}
.game-icon-tabletop-players:before {
    content: "\FF785";
}
.game-icon-tacos:before {
    content: "\FF786";
}
.game-icon-tadpole:before {
    content: "\FF787";
}
.game-icon-take-my-money:before {
    content: "\FF788";
}
.game-icon-tall-bridge:before {
    content: "\FF789";
}
.game-icon-tambourine:before {
    content: "\FF78A";
}
.game-icon-tangerine:before {
    content: "\FF78B";
}
.game-icon-tank-top:before {
    content: "\FF78C";
}
.game-icon-tanzania:before {
    content: "\FF78D";
}
.game-icon-tap:before {
    content: "\FF78E";
}
.game-icon-tapir:before {
    content: "\FF78F";
}
.game-icon-target-poster:before {
    content: "\FF790";
}
.game-icon-target-prize:before {
    content: "\FF791";
}
.game-icon-taurus:before {
    content: "\FF792";
}
.game-icon-tavern-sign:before {
    content: "\FF793";
}
.game-icon-teacher:before {
    content: "\FF794";
}
.game-icon-team-downgrade:before {
    content: "\FF795";
}
.game-icon-team-idea:before {
    content: "\FF796";
}
.game-icon-team-upgrade:before {
    content: "\FF797";
}
.game-icon-teapot-leaves:before {
    content: "\FF798";
}
.game-icon-tee-pipe:before {
    content: "\FF799";
}
.game-icon-templar-shield:before {
    content: "\FF79A";
}
.game-icon-temple-door:before {
    content: "\FF79B";
}
.game-icon-temple-gate:before {
    content: "\FF79C";
}
.game-icon-temporary-shield:before {
    content: "\FF79D";
}
.game-icon-tennis-ball:before {
    content: "\FF79E";
}
.game-icon-tennis-court:before {
    content: "\FF79F";
}
.game-icon-tennis-racket:before {
    content: "\FF7A0";
}
.game-icon-tentacles-barrier:before {
    content: "\FF7A1";
}
.game-icon-texas:before {
    content: "\FF7A2";
}
.game-icon-theater-curtains:before {
    content: "\FF7A3";
}
.game-icon-theater:before {
    content: "\FF7A4";
}
.game-icon-thermometer-cold:before {
    content: "\FF7A5";
}
.game-icon-thermometer-hot:before {
    content: "\FF7A6";
}
.game-icon-think:before {
    content: "\FF7A7";
}
.game-icon-thor-hammer:before {
    content: "\FF7A8";
}
.game-icon-three-friends:before {
    content: "\FF7A9";
}
.game-icon-throne-king:before {
    content: "\FF7AA";
}
.game-icon-throwing-ball:before {
    content: "\FF7AB";
}
.game-icon-thrust-bend:before {
    content: "\FF7AC";
}
.game-icon-thumb-down:before {
    content: "\FF7AD";
}
.game-icon-thumb-up:before {
    content: "\FF7AE";
}
.game-icon-thwomp:before {
    content: "\FF7AF";
}
.game-icon-tiara:before {
    content: "\FF7B0";
}
.game-icon-tic-tac-toe:before {
    content: "\FF7B1";
}
.game-icon-ticket:before {
    content: "\FF7B2";
}
.game-icon-tie:before {
    content: "\FF7B3";
}
.game-icon-tiger-head:before {
    content: "\FF7B4";
}
.game-icon-tiger:before {
    content: "\FF7B5";
}
.game-icon-tightrope:before {
    content: "\FF7B6";
}
.game-icon-time-dynamite:before {
    content: "\FF7B7";
}
.game-icon-time-synchronization:before {
    content: "\FF7B8";
}
.game-icon-tipi:before {
    content: "\FF7B9";
}
.game-icon-tire-iron-cross:before {
    content: "\FF7BA";
}
.game-icon-tire-iron:before {
    content: "\FF7BB";
}
.game-icon-tire-tracks:before {
    content: "\FF7BC";
}
.game-icon-tired-eye:before {
    content: "\FF7BD";
}
.game-icon-toaster:before {
    content: "\FF7BE";
}
.game-icon-toggles:before {
    content: "\FF7BF";
}
.game-icon-token:before {
    content: "\FF7C0";
}
.game-icon-tomahawk:before {
    content: "\FF7C1";
}
.game-icon-tomato:before {
    content: "\FF7C2";
}
.game-icon-tongue:before {
    content: "\FF7C3";
}
.game-icon-toolbox:before {
    content: "\FF7C4";
}
.game-icon-torch:before {
    content: "\FF7C5";
}
.game-icon-torpedo:before {
    content: "\FF7C6";
}
.game-icon-tortoise:before {
    content: "\FF7C7";
}
.game-icon-totem:before {
    content: "\FF7C8";
}
.game-icon-toucan:before {
    content: "\FF7C9";
}
.game-icon-tow-truck:before {
    content: "\FF7CA";
}
.game-icon-towel:before {
    content: "\FF7CB";
}
.game-icon-tower-bridge:before {
    content: "\FF7CC";
}
.game-icon-tower-flag:before {
    content: "\FF7CD";
}
.game-icon-toy-mallet:before {
    content: "\FF7CE";
}
.game-icon-tracked-robot:before {
    content: "\FF7CF";
}
.game-icon-traffic-cone:before {
    content: "\FF7D0";
}
.game-icon-traffic-lights-green:before {
    content: "\FF7D1";
}
.game-icon-traffic-lights-orange:before {
    content: "\FF7D2";
}
.game-icon-traffic-lights-ready-to-go:before {
    content: "\FF7D3";
}
.game-icon-traffic-lights-red:before {
    content: "\FF7D4";
}
.game-icon-trail:before {
    content: "\FF7D5";
}
.game-icon-transform:before {
    content: "\FF7D6";
}
.game-icon-trash-can:before {
    content: "\FF7D7";
}
.game-icon-travel-dress:before {
    content: "\FF7D8";
}
.game-icon-trebuchet:before {
    content: "\FF7D9";
}
.game-icon-tree-beehive:before {
    content: "\FF7DA";
}
.game-icon-tree-door:before {
    content: "\FF7DB";
}
.game-icon-tree-growth:before {
    content: "\FF7DC";
}
.game-icon-tree-roots:before {
    content: "\FF7DD";
}
.game-icon-tree-swing:before {
    content: "\FF7DE";
}
.game-icon-triangle-target:before {
    content: "\FF7DF";
}
.game-icon-tribal-gear:before {
    content: "\FF7E0";
}
.game-icon-tribal-pendant:before {
    content: "\FF7E1";
}
.game-icon-tribal-shield:before {
    content: "\FF7E2";
}
.game-icon-tribunal-jury:before {
    content: "\FF7E3";
}
.game-icon-triceratops-head:before {
    content: "\FF7E4";
}
.game-icon-trident-shield:before {
    content: "\FF7E5";
}
.game-icon-triforce:before {
    content: "\FF7E6";
}
.game-icon-trinacria:before {
    content: "\FF7E7";
}
.game-icon-triple-gate:before {
    content: "\FF7E8";
}
.game-icon-triquetra:before {
    content: "\FF7E9";
}
.game-icon-trireme:before {
    content: "\FF7EA";
}
.game-icon-troglodyte:before {
    content: "\FF7EB";
}
.game-icon-trojan-horse:before {
    content: "\FF7EC";
}
.game-icon-trophies-shelf:before {
    content: "\FF7ED";
}
.game-icon-trophy-cup:before {
    content: "\FF7EE";
}
.game-icon-tropical-fish:before {
    content: "\FF7EF";
}
.game-icon-trowel:before {
    content: "\FF7F0";
}
.game-icon-truce:before {
    content: "\FF7F1";
}
.game-icon-truck:before {
    content: "\FF7F2";
}
.game-icon-trumpet-flag:before {
    content: "\FF7F3";
}
.game-icon-trumpet:before {
    content: "\FF7F4";
}
.game-icon-trunk-mushroom:before {
    content: "\FF7F5";
}
.game-icon-tumbleweed:before {
    content: "\FF7F6";
}
.game-icon-tunisia:before {
    content: "\FF7F7";
}
.game-icon-turban:before {
    content: "\FF7F8";
}
.game-icon-turbine:before {
    content: "\FF7F9";
}
.game-icon-turnstile:before {
    content: "\FF7FA";
}
.game-icon-tusks-flag:before {
    content: "\FF7FB";
}
.game-icon-tv-remote:before {
    content: "\FF7FC";
}
.game-icon-tv-tower:before {
    content: "\FF7FD";
}
.game-icon-tv:before {
    content: "\FF7FE";
}
.game-icon-two-coins:before {
    content: "\FF7FF";
}
.game-icon-two-handed-sword:before {
    content: "\FF800";
}
.game-icon-udder:before {
    content: "\FF801";
}
.game-icon-ufo:before {
    content: "\FF802";
}
.game-icon-uluru:before {
    content: "\FF803";
}
.game-icon-umbrella-bayonet:before {
    content: "\FF804";
}
.game-icon-unbalanced:before {
    content: "\FF805";
}
.game-icon-underground-cave:before {
    content: "\FF806";
}
.game-icon-underwear-shorts:before {
    content: "\FF807";
}
.game-icon-underwear:before {
    content: "\FF808";
}
.game-icon-unicorn:before {
    content: "\FF809";
}
.game-icon-unicycle:before {
    content: "\FF80A";
}
.game-icon-union-jack:before {
    content: "\FF80B";
}
.game-icon-up-card:before {
    content: "\FF80C";
}
.game-icon-upgrade:before {
    content: "\FF80D";
}
.game-icon-uprising:before {
    content: "\FF80E";
}
.game-icon-ursa-major:before {
    content: "\FF80F";
}
.game-icon-uruguay:before {
    content: "\FF810";
}
.game-icon-usa-flag:before {
    content: "\FF811";
}
.game-icon-usb-key:before {
    content: "\FF812";
}
.game-icon-ushanka:before {
    content: "\FF813";
}
.game-icon-uzi:before {
    content: "\FF814";
}
.game-icon-vacuum-cleaner:before {
    content: "\FF815";
}
.game-icon-valve:before {
    content: "\FF816";
}
.game-icon-vampire-cape:before {
    content: "\FF817";
}
.game-icon-vampire-dracula:before {
    content: "\FF818";
}
.game-icon-van-damme-split:before {
    content: "\FF819";
}
.game-icon-velocipede:before {
    content: "\FF81A";
}
.game-icon-velociraptor-tracks:before {
    content: "\FF81B";
}
.game-icon-velociraptor:before {
    content: "\FF81C";
}
.game-icon-vending-machine:before {
    content: "\FF81D";
}
.game-icon-venezuela:before {
    content: "\FF81E";
}
.game-icon-venus-of-willendorf:before {
    content: "\FF81F";
}
.game-icon-vertical-banner:before {
    content: "\FF820";
}
.game-icon-vertical-flip:before {
    content: "\FF821";
}
.game-icon-vibrating-shield:before {
    content: "\FF822";
}
.game-icon-vibrating-smartphone:before {
    content: "\FF823";
}
.game-icon-video-camera:before {
    content: "\FF824";
}
.game-icon-video-conference:before {
    content: "\FF825";
}
.game-icon-viking-church:before {
    content: "\FF826";
}
.game-icon-viking-head:before {
    content: "\FF827";
}
.game-icon-viking-helmet:before {
    content: "\FF828";
}
.game-icon-viking-longhouse:before {
    content: "\FF829";
}
.game-icon-viking-shield:before {
    content: "\FF82A";
}
.game-icon-village:before {
    content: "\FF82B";
}
.game-icon-vines:before {
    content: "\FF82C";
}
.game-icon-virgo:before {
    content: "\FF82D";
}
.game-icon-virtual-marker:before {
    content: "\FF82E";
}
.game-icon-vitruvian-man:before {
    content: "\FF82F";
}
.game-icon-volleyball-ball:before {
    content: "\FF830";
}
.game-icon-vote:before {
    content: "\FF831";
}
.game-icon-vr-headset:before {
    content: "\FF832";
}
.game-icon-vuvuzelas:before {
    content: "\FF833";
}
.game-icon-walk:before {
    content: "\FF834";
}
.game-icon-walkie-talkie:before {
    content: "\FF835";
}
.game-icon-walking-scout:before {
    content: "\FF836";
}
.game-icon-walking-turret:before {
    content: "\FF837";
}
.game-icon-wall-light:before {
    content: "\FF838";
}
.game-icon-wallet:before {
    content: "\FF839";
}
.game-icon-walrus-head:before {
    content: "\FF83A";
}
.game-icon-wanted-reward:before {
    content: "\FF83B";
}
.game-icon-war-axe:before {
    content: "\FF83C";
}
.game-icon-war-bonnet:before {
    content: "\FF83D";
}
.game-icon-war-pick:before {
    content: "\FF83E";
}
.game-icon-warhammer:before {
    content: "\FF83F";
}
.game-icon-warlock-eye:before {
    content: "\FF840";
}
.game-icon-warlock-hood:before {
    content: "\FF841";
}
.game-icon-warp-pipe:before {
    content: "\FF842";
}
.game-icon-washing-machine:before {
    content: "\FF843";
}
.game-icon-watch:before {
    content: "\FF844";
}
.game-icon-watchtower:before {
    content: "\FF845";
}
.game-icon-water-bottle:before {
    content: "\FF846";
}
.game-icon-water-diviner-stick:before {
    content: "\FF847";
}
.game-icon-water-flask:before {
    content: "\FF848";
}
.game-icon-water-fountain:before {
    content: "\FF849";
}
.game-icon-water-gallon:before {
    content: "\FF84A";
}
.game-icon-water-gun:before {
    content: "\FF84B";
}
.game-icon-water-polo:before {
    content: "\FF84C";
}
.game-icon-water-recycling:before {
    content: "\FF84D";
}
.game-icon-water-tank:before {
    content: "\FF84E";
}
.game-icon-water-tower:before {
    content: "\FF84F";
}
.game-icon-waterfall:before {
    content: "\FF850";
}
.game-icon-watering-can:before {
    content: "\FF851";
}
.game-icon-watermelon:before {
    content: "\FF852";
}
.game-icon-wave-surfer:before {
    content: "\FF853";
}
.game-icon-wax-tablet:before {
    content: "\FF854";
}
.game-icon-weight-lifting-down:before {
    content: "\FF855";
}
.game-icon-weight-lifting-up:before {
    content: "\FF856";
}
.game-icon-weight-scale:before {
    content: "\FF857";
}
.game-icon-weight:before {
    content: "\FF858";
}
.game-icon-well:before {
    content: "\FF859";
}
.game-icon-western-hat:before {
    content: "\FF85A";
}
.game-icon-whale-tail:before {
    content: "\FF85B";
}
.game-icon-wheelbarrow:before {
    content: "\FF85C";
}
.game-icon-whisk:before {
    content: "\FF85D";
}
.game-icon-whistle:before {
    content: "\FF85E";
}
.game-icon-wifi-router:before {
    content: "\FF85F";
}
.game-icon-wind-turbine:before {
    content: "\FF860";
}
.game-icon-windmill:before {
    content: "\FF861";
}
.game-icon-window-bars:before {
    content: "\FF862";
}
.game-icon-window:before {
    content: "\FF863";
}
.game-icon-windpump:before {
    content: "\FF864";
}
.game-icon-windsock:before {
    content: "\FF865";
}
.game-icon-wine-bottle:before {
    content: "\FF866";
}
.game-icon-winged-scepter:before {
    content: "\FF867";
}
.game-icon-winter-gloves:before {
    content: "\FF868";
}
.game-icon-winter-hat:before {
    content: "\FF869";
}
.game-icon-wire-coil:before {
    content: "\FF86A";
}
.game-icon-wireframe-globe:before {
    content: "\FF86B";
}
.game-icon-wisdom:before {
    content: "\FF86C";
}
.game-icon-wizard-face:before {
    content: "\FF86D";
}
.game-icon-wolverine-claws:before {
    content: "\FF86E";
}
.game-icon-woman-elf-face:before {
    content: "\FF86F";
}
.game-icon-wood-beam:before {
    content: "\FF870";
}
.game-icon-wood-cabin:before {
    content: "\FF871";
}
.game-icon-wood-canoe:before {
    content: "\FF872";
}
.game-icon-wood-club:before {
    content: "\FF873";
}
.game-icon-wood-frame:before {
    content: "\FF874";
}
.game-icon-wood-pile:before {
    content: "\FF875";
}
.game-icon-wood-stick:before {
    content: "\FF876";
}
.game-icon-wooden-chair:before {
    content: "\FF877";
}
.game-icon-wooden-clogs:before {
    content: "\FF878";
}
.game-icon-wooden-crate:before {
    content: "\FF879";
}
.game-icon-wooden-pegleg:before {
    content: "\FF87A";
}
.game-icon-wooden-pier:before {
    content: "\FF87B";
}
.game-icon-wool:before {
    content: "\FF87C";
}
.game-icon-worms:before {
    content: "\FF87D";
}
.game-icon-xylophone:before {
    content: "\FF87E";
}
.game-icon-yarn:before {
    content: "\FF87F";
}
.game-icon-yin-yang:before {
    content: "\FF880";
}
.game-icon-yunluo:before {
    content: "\FF881";
}
.game-icon-z-brick:before {
    content: "\FF882";
}
.game-icon-zat-gun:before {
    content: "\FF883";
}
.game-icon-zigzag-hieroglyph:before {
    content: "\FF884";
}
.game-icon-zipper:before {
    content: "\FF885";
}
.game-icon-card-discard:before {
    content: "\FF886";
}
.game-icon-card-draw:before {
    content: "\FF887";
}
.game-icon-card-pick:before {
    content: "\FF888";
}
.game-icon-card-random:before {
    content: "\FF889";
}
.game-icon-dragon-head:before {
    content: "\FF88A";
}
.game-icon-forward-sun:before {
    content: "\FF88B";
}
.game-icon-minerals:before {
    content: "\FF88C";
}
.game-icon-nautilus-shell:before {
    content: "\FF88D";
}
.game-icon-ninja-star:before {
    content: "\FF88E";
}
.game-icon-ore:before {
    content: "\FF88F";
}
.game-icon-dodge:before {
    content: "\FF890";
}
.game-icon-overhead:before {
    content: "\FF891";
}
.game-icon-sideswipe:before {
    content: "\FF892";
}
.game-icon-thrust:before {
    content: "\FF893";
}
.game-icon-underhand:before {
    content: "\FF894";
}
.game-icon-fingers-crossed:before {
    content: "\FF895";
}
.game-icon-shepherds-crook:before {
    content: "\FF896";
}
.game-icon-pause-button:before {
    content: "\FF897";
}
.game-icon-play-button:before {
    content: "\FF898";
}
.game-icon-soda-can:before {
    content: "\FF899";
}
.game-icon-catapult:before {
    content: "\FF89A";
}
.game-icon-defensive-wall:before {
    content: "\FF89B";
}
.game-icon-armored-pants:before {
    content: "\FF89C";
}
.game-icon-pistol-gun:before {
    content: "\FF89D";
}
.game-icon-paper:before {
    content: "\FF89E";
}
.game-icon-rock:before {
    content: "\FF89F";
}
.game-icon-scissors:before {
    content: "\FF8A0";
}
.game-icon-dwarf-helmet:before {
    content: "\FF8A1";
}
.game-icon-dwarf-king:before {
    content: "\FF8A2";
}
.game-icon-elf-helmet:before {
    content: "\FF8A3";
}
.game-icon-ace:before {
    content: "\FF8A4";
}
.game-icon-acid-blob:before {
    content: "\FF8A5";
}
.game-icon-acorn:before {
    content: "\FF8A6";
}
.game-icon-aerial-signal:before {
    content: "\FF8A7";
}
.game-icon-aerodynamic-harpoon:before {
    content: "\FF8A8";
}
.game-icon-aerosol:before {
    content: "\FF8A9";
}
.game-icon-afterburn:before {
    content: "\FF8AA";
}
.game-icon-air-zigzag:before {
    content: "\FF8AB";
}
.game-icon-alien-fire:before {
    content: "\FF8AC";
}
.game-icon-alien-skull:before {
    content: "\FF8AD";
}
.game-icon-alien-stare:before {
    content: "\FF8AE";
}
.game-icon-all-for-one:before {
    content: "\FF8AF";
}
.game-icon-alligator-clip:before {
    content: "\FF8B0";
}
.game-icon-ammonite-fossil:before {
    content: "\FF8B1";
}
.game-icon-ammonite:before {
    content: "\FF8B2";
}
.game-icon-anatomy:before {
    content: "\FF8B3";
}
.game-icon-anchor:before {
    content: "\FF8B4";
}
.game-icon-android-mask:before {
    content: "\FF8B5";
}
.game-icon-andromeda-chain:before {
    content: "\FF8B6";
}
.game-icon-angel-outfit:before {
    content: "\FF8B7";
}
.game-icon-angel-wings:before {
    content: "\FF8B8";
}
.game-icon-angler-fish:before {
    content: "\FF8B9";
}
.game-icon-angular-spider:before {
    content: "\FF8BA";
}
.game-icon-animal-skull:before {
    content: "\FF8BB";
}
.game-icon-ankh:before {
    content: "\FF8BC";
}
.game-icon-anthem:before {
    content: "\FF8BD";
}
.game-icon-anvil-impact:before {
    content: "\FF8BE";
}
.game-icon-anvil:before {
    content: "\FF8BF";
}
.game-icon-apple-maggot:before {
    content: "\FF8C0";
}
.game-icon-apple-seeds:before {
    content: "\FF8C1";
}
.game-icon-archery-target:before {
    content: "\FF8C2";
}
.game-icon-architect-mask:before {
    content: "\FF8C3";
}
.game-icon-arcing-bolt:before {
    content: "\FF8C4";
}
.game-icon-armadillo-tail:before {
    content: "\FF8C5";
}
.game-icon-armor-vest:before {
    content: "\FF8C6";
}
.game-icon-armoured-shell:before {
    content: "\FF8C7";
}
.game-icon-arrow-cluster:before {
    content: "\FF8C8";
}
.game-icon-arrow-dunk:before {
    content: "\FF8C9";
}
.game-icon-arrow-flights:before {
    content: "\FF8CA";
}
.game-icon-arrow-scope:before {
    content: "\FF8CB";
}
.game-icon-arrowed:before {
    content: "\FF8CC";
}
.game-icon-arrowhead:before {
    content: "\FF8CD";
}
.game-icon-arrows-shield:before {
    content: "\FF8CE";
}
.game-icon-arson:before {
    content: "\FF8CF";
}
.game-icon-artificial-hive:before {
    content: "\FF8D0";
}
.game-icon-assassin-pocket:before {
    content: "\FF8D1";
}
.game-icon-at-sea:before {
    content: "\FF8D2";
}
.game-icon-atomic-slashes:before {
    content: "\FF8D3";
}
.game-icon-aubergine:before {
    content: "\FF8D4";
}
.game-icon-aura:before {
    content: "\FF8D5";
}
.game-icon-auto-repair:before {
    content: "\FF8D6";
}
.game-icon-autogun:before {
    content: "\FF8D7";
}
.game-icon-automatic-sas:before {
    content: "\FF8D8";
}
.game-icon-awareness:before {
    content: "\FF8D9";
}
.game-icon-axe-in-stump:before {
    content: "\FF8DA";
}
.game-icon-axe-swing:before {
    content: "\FF8DB";
}
.game-icon-back-forth:before {
    content: "\FF8DC";
}
.game-icon-back-pain:before {
    content: "\FF8DD";
}
.game-icon-backbone-shell:before {
    content: "\FF8DE";
}
.game-icon-backstab:before {
    content: "\FF8DF";
}
.game-icon-backup:before {
    content: "\FF8E0";
}
.game-icon-ball-glow:before {
    content: "\FF8E1";
}
.game-icon-ball-heart:before {
    content: "\FF8E2";
}
.game-icon-balloons:before {
    content: "\FF8E3";
}
.game-icon-bandage-roll:before {
    content: "\FF8E4";
}
.game-icon-bandaged:before {
    content: "\FF8E5";
}
.game-icon-barbed-arrow:before {
    content: "\FF8E6";
}
.game-icon-barbed-coil:before {
    content: "\FF8E7";
}
.game-icon-barbed-nails:before {
    content: "\FF8E8";
}
.game-icon-barbed-spear:before {
    content: "\FF8E9";
}
.game-icon-barbed-star:before {
    content: "\FF8EA";
}
.game-icon-barbed-sun:before {
    content: "\FF8EB";
}
.game-icon-barbed-wire:before {
    content: "\FF8EC";
}
.game-icon-barbute:before {
    content: "\FF8ED";
}
.game-icon-barefoot:before {
    content: "\FF8EE";
}
.game-icon-bat-blade:before {
    content: "\FF8EF";
}
.game-icon-bat-wing:before {
    content: "\FF8F0";
}
.game-icon-battered-axe:before {
    content: "\FF8F1";
}
.game-icon-batteries:before {
    content: "\FF8F2";
}
.game-icon-battle-axe:before {
    content: "\FF8F3";
}
.game-icon-battle-gear:before {
    content: "\FF8F4";
}
.game-icon-battle-tank:before {
    content: "\FF8F5";
}
.game-icon-batwing-emblem:before {
    content: "\FF8F6";
}
.game-icon-beam-wake:before {
    content: "\FF8F7";
}
.game-icon-beams-aura:before {
    content: "\FF8F8";
}
.game-icon-beanstalk:before {
    content: "\FF8F9";
}
.game-icon-beard:before {
    content: "\FF8FA";
}
.game-icon-beast-eye:before {
    content: "\FF8FB";
}
.game-icon-bee:before {
    content: "\FF8FC";
}
.game-icon-beech:before {
    content: "\FF8FD";
}
.game-icon-beer-stein:before {
    content: "\FF8FE";
}
.game-icon-beetle-shell:before {
    content: "\FF8FF";
}
.game-icon-behold:before {
    content: "\FF900";
}
.game-icon-bell-shield:before {
    content: "\FF901";
}
.game-icon-belt-buckles:before {
    content: "\FF902";
}
.game-icon-bestial-fangs:before {
    content: "\FF903";
}
.game-icon-beveled-star:before {
    content: "\FF904";
}
.game-icon-big-wave:before {
    content: "\FF905";
}
.game-icon-biohazard:before {
    content: "\FF906";
}
.game-icon-bird-claw:before {
    content: "\FF907";
}
.game-icon-bird-limb:before {
    content: "\FF908";
}
.game-icon-bird-mask:before {
    content: "\FF909";
}
.game-icon-bird-twitter:before {
    content: "\FF90A";
}
.game-icon-black-bar:before {
    content: "\FF90B";
}
.game-icon-black-flag:before {
    content: "\FF90C";
}
.game-icon-black-hand-shield:before {
    content: "\FF90D";
}
.game-icon-black-hole-bolas:before {
    content: "\FF90E";
}
.game-icon-blackball:before {
    content: "\FF90F";
}
.game-icon-blackcurrant:before {
    content: "\FF910";
}
.game-icon-blade-bite:before {
    content: "\FF911";
}
.game-icon-blade-drag:before {
    content: "\FF912";
}
.game-icon-blade-fall:before {
    content: "\FF913";
}
.game-icon-bleeding-eye:before {
    content: "\FF914";
}
.game-icon-bleeding-heart:before {
    content: "\FF915";
}
.game-icon-bleeding-wound:before {
    content: "\FF916";
}
.game-icon-bloody-stash:before {
    content: "\FF917";
}
.game-icon-bloody-sword:before {
    content: "\FF918";
}
.game-icon-blunderbuss:before {
    content: "\FF919";
}
.game-icon-boar-tusks:before {
    content: "\FF91A";
}
.game-icon-body-swapping:before {
    content: "\FF91B";
}
.game-icon-boiling-bubbles:before {
    content: "\FF91C";
}
.game-icon-bolas:before {
    content: "\FF91D";
}
.game-icon-bolt-eye:before {
    content: "\FF91E";
}
.game-icon-bolt-saw:before {
    content: "\FF91F";
}
.game-icon-bolt-shield:before {
    content: "\FF920";
}
.game-icon-bolter-gun:before {
    content: "\FF921";
}
.game-icon-bombing-run:before {
    content: "\FF922";
}
.game-icon-bone-gnawer:before {
    content: "\FF923";
}
.game-icon-bone-knife-2:before {
    content: "\FF924";
}
.game-icon-book-aura:before {
    content: "\FF925";
}
.game-icon-book-cover-2:before {
    content: "\FF926";
}
.game-icon-book-storm:before {
    content: "\FF927";
}
.game-icon-bookmark:before {
    content: "\FF928";
}
.game-icon-bookmarklet:before {
    content: "\FF929";
}
.game-icon-boomerang-sun:before {
    content: "\FF92A";
}
.game-icon-boot-kick:before {
    content: "\FF92B";
}
.game-icon-boot-prints:before {
    content: "\FF92C";
}
.game-icon-boot-stomp:before {
    content: "\FF92D";
}
.game-icon-boots:before {
    content: "\FF92E";
}
.game-icon-bordered-shield:before {
    content: "\FF92F";
}
.game-icon-bottle-vapors:before {
    content: "\FF930";
}
.game-icon-bottled-bolt:before {
    content: "\FF931";
}
.game-icon-bottom-right-3d-arrow:before {
    content: "\FF932";
}
.game-icon-bouncing-sword:before {
    content: "\FF933";
}
.game-icon-bowie-knife:before {
    content: "\FF934";
}
.game-icon-bowl-spiral:before {
    content: "\FF935";
}
.game-icon-bowling-propulsion:before {
    content: "\FF936";
}
.game-icon-bowman:before {
    content: "\FF937";
}
.game-icon-boxing-glove-surprise:before {
    content: "\FF938";
}
.game-icon-boxing-glove:before {
    content: "\FF939";
}
.game-icon-brain-freeze:before {
    content: "\FF93A";
}
.game-icon-brain-stem:before {
    content: "\FF93B";
}
.game-icon-brain:before {
    content: "\FF93C";
}
.game-icon-brainstorm:before {
    content: "\FF93D";
}
.game-icon-branch-arrow:before {
    content: "\FF93E";
}
.game-icon-brandy-bottle:before {
    content: "\FF93F";
}
.game-icon-brass-eye:before {
    content: "\FF940";
}
.game-icon-breastplate:before {
    content: "\FF941";
}
.game-icon-bridge:before {
    content: "\FF942";
}
.game-icon-bright-explosion:before {
    content: "\FF943";
}
.game-icon-broad-dagger:before {
    content: "\FF944";
}
.game-icon-broadhead-arrow:before {
    content: "\FF945";
}
.game-icon-broadsword:before {
    content: "\FF946";
}
.game-icon-broken-bone:before {
    content: "\FF947";
}
.game-icon-broken-bottle:before {
    content: "\FF948";
}
.game-icon-broken-heart-zone:before {
    content: "\FF949";
}
.game-icon-broken-heart:before {
    content: "\FF94A";
}
.game-icon-broken-ribbon:before {
    content: "\FF94B";
}
.game-icon-broken-shield:before {
    content: "\FF94C";
}
.game-icon-broken-skull:before {
    content: "\FF94D";
}
.game-icon-broken-tablet:before {
    content: "\FF94E";
}
.game-icon-bubble-field:before {
    content: "\FF94F";
}
.game-icon-bubbles:before {
    content: "\FF950";
}
.game-icon-bubbling-beam:before {
    content: "\FF951";
}
.game-icon-bubbling-bowl:before {
    content: "\FF952";
}
.game-icon-bubbling-flask:before {
    content: "\FF953";
}
.game-icon-bud:before {
    content: "\FF954";
}
.game-icon-bugle-call:before {
    content: "\FF955";
}
.game-icon-bulb:before {
    content: "\FF956";
}
.game-icon-bull-horns:before {
    content: "\FF957";
}
.game-icon-bull:before {
    content: "\FF958";
}
.game-icon-bullets:before {
    content: "\FF959";
}
.game-icon-burning-blobs:before {
    content: "\FF95A";
}
.game-icon-burning-book:before {
    content: "\FF95B";
}
.game-icon-burning-dot:before {
    content: "\FF95C";
}
.game-icon-burning-embers:before {
    content: "\FF95D";
}
.game-icon-burning-eye:before {
    content: "\FF95E";
}
.game-icon-burning-meteor:before {
    content: "\FF95F";
}
.game-icon-burning-passion:before {
    content: "\FF960";
}
.game-icon-burning-round-shot:before {
    content: "\FF961";
}
.game-icon-burning-tree:before {
    content: "\FF962";
}
.game-icon-burst-blob:before {
    content: "\FF963";
}
.game-icon-butterfly-warning:before {
    content: "\FF964";
}
.game-icon-butterfly:before {
    content: "\FF965";
}
.game-icon-caged-ball:before {
    content: "\FF966";
}
.game-icon-cake-slice:before {
    content: "\FF967";
}
.game-icon-caldera:before {
    content: "\FF968";
}
.game-icon-campfire:before {
    content: "\FF969";
}
.game-icon-candle-flame:before {
    content: "\FF96A";
}
.game-icon-candle-holder:before {
    content: "\FF96B";
}
.game-icon-candle-light:before {
    content: "\FF96C";
}
.game-icon-candle-skull:before {
    content: "\FF96D";
}
.game-icon-candlebright:before {
    content: "\FF96E";
}
.game-icon-cannister:before {
    content: "\FF96F";
}
.game-icon-cannon-ball:before {
    content: "\FF970";
}
.game-icon-cannon-shot:before {
    content: "\FF971";
}
.game-icon-cannon:before {
    content: "\FF972";
}
.game-icon-capitol:before {
    content: "\FF973";
}
.game-icon-cargo-crane:before {
    content: "\FF974";
}
.game-icon-carillon:before {
    content: "\FF975";
}
.game-icon-carnivore-mouth:before {
    content: "\FF976";
}
.game-icon-carrion:before {
    content: "\FF977";
}
.game-icon-cartwheel:before {
    content: "\FF978";
}
.game-icon-cash:before {
    content: "\FF979";
}
.game-icon-castle-2:before {
    content: "\FF97A";
}
.game-icon-cat:before {
    content: "\FF97B";
}
.game-icon-cauldron-2:before {
    content: "\FF97C";
}
.game-icon-celebration-fire:before {
    content: "\FF97D";
}
.game-icon-centipede:before {
    content: "\FF97E";
}
.game-icon-chained-arrow-heads:before {
    content: "\FF97F";
}
.game-icon-chained-heart:before {
    content: "\FF980";
}
.game-icon-chalice-drops:before {
    content: "\FF981";
}
.game-icon-charged-arrow:before {
    content: "\FF982";
}
.game-icon-charm:before {
    content: "\FF983";
}
.game-icon-checkbox-tree:before {
    content: "\FF984";
}
.game-icon-checked-shield:before {
    content: "\FF985";
}
.game-icon-checkered-diamond:before {
    content: "\FF986";
}
.game-icon-cheerful:before {
    content: "\FF987";
}
.game-icon-cheese-wedge:before {
    content: "\FF988";
}
.game-icon-chemical-arrow:before {
    content: "\FF989";
}
.game-icon-chemical-bolt:before {
    content: "\FF98A";
}
.game-icon-chemical-drop:before {
    content: "\FF98B";
}
.game-icon-chemical-tank:before {
    content: "\FF98C";
}
.game-icon-chewed-heart:before {
    content: "\FF98D";
}
.game-icon-chewed-skull:before {
    content: "\FF98E";
}
.game-icon-chicken-leg:before {
    content: "\FF98F";
}
.game-icon-chopped-skull:before {
    content: "\FF990";
}
.game-icon-circle-cage:before {
    content: "\FF991";
}
.game-icon-circle-claws:before {
    content: "\FF992";
}
.game-icon-circle-sparks:before {
    content: "\FF993";
}
.game-icon-circuitry:before {
    content: "\FF994";
}
.game-icon-circular-sawblade:before {
    content: "\FF995";
}
.game-icon-claw-hammer:before {
    content: "\FF996";
}
.game-icon-claw-slashes:before {
    content: "\FF997";
}
.game-icon-claw-string:before {
    content: "\FF998";
}
.game-icon-cloak-dagger:before {
    content: "\FF999";
}
.game-icon-clockwork:before {
    content: "\FF99A";
}
.game-icon-cloud-ring:before {
    content: "\FF99B";
}
.game-icon-cloudy-fork:before {
    content: "\FF99C";
}
.game-icon-clout:before {
    content: "\FF99D";
}
.game-icon-clover:before {
    content: "\FF99E";
}
.game-icon-cluster-bomb:before {
    content: "\FF99F";
}
.game-icon-cobweb:before {
    content: "\FF9A0";
}
.game-icon-coffee-mug:before {
    content: "\FF9A1";
}
.game-icon-coffin:before {
    content: "\FF9A2";
}
.game-icon-cog-lock:before {
    content: "\FF9A3";
}
.game-icon-cog:before {
    content: "\FF9A4";
}
.game-icon-cogsplosion:before {
    content: "\FF9A5";
}
.game-icon-coiling-curl:before {
    content: "\FF9A6";
}
.game-icon-cold-heart:before {
    content: "\FF9A7";
}
.game-icon-coma:before {
    content: "\FF9A8";
}
.game-icon-comb:before {
    content: "\FF9A9";
}
.game-icon-comet-spark:before {
    content: "\FF9AA";
}
.game-icon-compass:before {
    content: "\FF9AB";
}
.game-icon-concentration-orb:before {
    content: "\FF9AC";
}
.game-icon-concentric-crescents:before {
    content: "\FF9AD";
}
.game-icon-condor-emblem:before {
    content: "\FF9AE";
}
.game-icon-condylura-skull:before {
    content: "\FF9AF";
}
.game-icon-conversation:before {
    content: "\FF9B0";
}
.game-icon-cool-spices:before {
    content: "\FF9B1";
}
.game-icon-corked-tube:before {
    content: "\FF9B2";
}
.game-icon-corner-explosion:before {
    content: "\FF9B3";
}
.game-icon-cowled:before {
    content: "\FF9B4";
}
.game-icon-cpu-shot:before {
    content: "\FF9B5";
}
.game-icon-crab-claw:before {
    content: "\FF9B6";
}
.game-icon-crab:before {
    content: "\FF9B7";
}
.game-icon-cracked-alien-skull:before {
    content: "\FF9B8";
}
.game-icon-cracked-ball-dunk:before {
    content: "\FF9B9";
}
.game-icon-cracked-disc:before {
    content: "\FF9BA";
}
.game-icon-cracked-glass:before {
    content: "\FF9BB";
}
.game-icon-cracked-helm:before {
    content: "\FF9BC";
}
.game-icon-cracked-mask:before {
    content: "\FF9BD";
}
.game-icon-cracked-saber:before {
    content: "\FF9BE";
}
.game-icon-cracked-shield:before {
    content: "\FF9BF";
}
.game-icon-crags:before {
    content: "\FF9C0";
}
.game-icon-crenulated-shield:before {
    content: "\FF9C1";
}
.game-icon-crescent-blade:before {
    content: "\FF9C2";
}
.game-icon-crested-helmet:before {
    content: "\FF9C3";
}
.game-icon-croc-jaws:before {
    content: "\FF9C4";
}
.game-icon-croc-sword:before {
    content: "\FF9C5";
}
.game-icon-croissants-pupil:before {
    content: "\FF9C6";
}
.game-icon-cross-flare:before {
    content: "\FF9C7";
}
.game-icon-cross-mark:before {
    content: "\FF9C8";
}
.game-icon-crossed-air-flows:before {
    content: "\FF9C9";
}
.game-icon-crossed-axes:before {
    content: "\FF9CA";
}
.game-icon-crossed-bones:before {
    content: "\FF9CB";
}
.game-icon-crossed-chains:before {
    content: "\FF9CC";
}
.game-icon-crossed-claws:before {
    content: "\FF9CD";
}
.game-icon-crossed-pistols:before {
    content: "\FF9CE";
}
.game-icon-crossed-sabres:before {
    content: "\FF9CF";
}
.game-icon-crossed-slashes:before {
    content: "\FF9D0";
}
.game-icon-crossed-swords:before {
    content: "\FF9D1";
}
.game-icon-crosshair-arrow:before {
    content: "\FF9D2";
}
.game-icon-crow-dive:before {
    content: "\FF9D3";
}
.game-icon-crown-coin:before {
    content: "\FF9D4";
}
.game-icon-crown-of-thorns:before {
    content: "\FF9D5";
}
.game-icon-crown:before {
    content: "\FF9D6";
}
.game-icon-crowned-explosion:before {
    content: "\FF9D7";
}
.game-icon-crowned-heart:before {
    content: "\FF9D8";
}
.game-icon-crowned-skull:before {
    content: "\FF9D9";
}
.game-icon-crumbling-ball:before {
    content: "\FF9DA";
}
.game-icon-crystal-ball:before {
    content: "\FF9DB";
}
.game-icon-crystal-bars:before {
    content: "\FF9DC";
}
.game-icon-crystal-cluster:before {
    content: "\FF9DD";
}
.game-icon-crystal-eye:before {
    content: "\FF9DE";
}
.game-icon-crystal-growth:before {
    content: "\FF9DF";
}
.game-icon-crystal-shine:before {
    content: "\FF9E0";
}
.game-icon-crystal-wand:before {
    content: "\FF9E1";
}
.game-icon-crystalize:before {
    content: "\FF9E2";
}
.game-icon-cubeforce:before {
    content: "\FF9E3";
}
.game-icon-cubes:before {
    content: "\FF9E4";
}
.game-icon-cultist:before {
    content: "\FF9E5";
}
.game-icon-cupidon-arrow:before {
    content: "\FF9E6";
}
.game-icon-curled-leaf:before {
    content: "\FF9E7";
}
.game-icon-curled-tentacle:before {
    content: "\FF9E8";
}
.game-icon-curling-vines:before {
    content: "\FF9E9";
}
.game-icon-curly-mask:before {
    content: "\FF9EA";
}
.game-icon-curly-wing:before {
    content: "\FF9EB";
}
.game-icon-cursed-star:before {
    content: "\FF9EC";
}
.game-icon-curvy-knife:before {
    content: "\FF9ED";
}
.game-icon-cut-diamond:before {
    content: "\FF9EE";
}
.game-icon-cut-palm:before {
    content: "\FF9EF";
}
.game-icon-cycle:before {
    content: "\FF9F0";
}
.game-icon-cyclops:before {
    content: "\FF9F1";
}
.game-icon-daemon-skull:before {
    content: "\FF9F2";
}
.game-icon-daggers:before {
    content: "\FF9F3";
}
.game-icon-daisy:before {
    content: "\FF9F4";
}
.game-icon-dark-squad:before {
    content: "\FF9F5";
}
.game-icon-dead-eye:before {
    content: "\FF9F6";
}
.game-icon-dead-wood:before {
    content: "\FF9F7";
}
.game-icon-deadly-strike:before {
    content: "\FF9F8";
}
.game-icon-death-note:before {
    content: "\FF9F9";
}
.game-icon-death-zone:before {
    content: "\FF9FA";
}
.game-icon-deathcab:before {
    content: "\FF9FB";
}
.game-icon-decapitation:before {
    content: "\FF9FC";
}
.game-icon-defibrilate:before {
    content: "\FF9FD";
}
.game-icon-delighted:before {
    content: "\FF9FE";
}
.game-icon-demolish:before {
    content: "\FF9FF";
}
.game-icon-dervish-swords:before {
    content: "\FFA00";
}
.game-icon-desert-skull:before {
    content: "\FFA01";
}
.game-icon-desk-lamp:before {
    content: "\FFA02";
}
.game-icon-despair:before {
    content: "\FFA03";
}
.game-icon-dew:before {
    content: "\FFA04";
}
.game-icon-diablo-skull:before {
    content: "\FFA05";
}
.game-icon-diamond-hard:before {
    content: "\FFA06";
}
.game-icon-diamonds-smile:before {
    content: "\FFA07";
}
.game-icon-dig-dug:before {
    content: "\FFA08";
}
.game-icon-dinosaur-bones:before {
    content: "\FFA09";
}
.game-icon-dinosaur-egg:before {
    content: "\FFA0A";
}
.game-icon-dinosaur-rex:before {
    content: "\FFA0B";
}
.game-icon-direwolf:before {
    content: "\FFA0C";
}
.game-icon-disc:before {
    content: "\FFA0D";
}
.game-icon-disintegrate:before {
    content: "\FFA0E";
}
.game-icon-distraction-2:before {
    content: "\FFA0F";
}
.game-icon-divergence:before {
    content: "\FFA10";
}
.game-icon-divert:before {
    content: "\FFA11";
}
.game-icon-divided-spiral:before {
    content: "\FFA12";
}
.game-icon-diving-dagger:before {
    content: "\FFA13";
}
.game-icon-dna1:before {
    content: "\FFA14";
}
.game-icon-dna2:before {
    content: "\FFA15";
}
.game-icon-doctor-face:before {
    content: "\FFA16";
}
.game-icon-dodging:before {
    content: "\FFA17";
}
.game-icon-domino-mask:before {
    content: "\FFA18";
}
.game-icon-doorway:before {
    content: "\FFA19";
}
.game-icon-dorsal-scales:before {
    content: "\FFA1A";
}
.game-icon-double-diaphragm:before {
    content: "\FFA1B";
}
.game-icon-double-dragon:before {
    content: "\FFA1C";
}
.game-icon-double-face-mask:before {
    content: "\FFA1D";
}
.game-icon-double-quaver:before {
    content: "\FFA1E";
}
.game-icon-double-ringed-orb:before {
    content: "\FFA1F";
}
.game-icon-double-shot:before {
    content: "\FFA20";
}
.game-icon-dove:before {
    content: "\FFA21";
}
.game-icon-dozen:before {
    content: "\FFA22";
}
.game-icon-dragon-balls:before {
    content: "\FFA23";
}
.game-icon-dragon-breath:before {
    content: "\FFA24";
}
.game-icon-dragon-head-2:before {
    content: "\FFA25";
}
.game-icon-dragon-spiral:before {
    content: "\FFA26";
}
.game-icon-dragonfly:before {
    content: "\FFA27";
}
.game-icon-drama-masks:before {
    content: "\FFA28";
}
.game-icon-dread-skull:before {
    content: "\FFA29";
}
.game-icon-drill-2:before {
    content: "\FFA2A";
}
.game-icon-drink-me:before {
    content: "\FFA2B";
}
.game-icon-dripping-blade:before {
    content: "\FFA2C";
}
.game-icon-dripping-goo:before {
    content: "\FFA2D";
}
.game-icon-dripping-honey:before {
    content: "\FFA2E";
}
.game-icon-dripping-knife:before {
    content: "\FFA2F";
}
.game-icon-dripping-star:before {
    content: "\FFA30";
}
.game-icon-dripping-stone:before {
    content: "\FFA31";
}
.game-icon-dripping-sword:before {
    content: "\FFA32";
}
.game-icon-dripping-tube:before {
    content: "\FFA33";
}
.game-icon-drop:before {
    content: "\FFA34";
}
.game-icon-droplet-splash:before {
    content: "\FFA35";
}
.game-icon-droplets:before {
    content: "\FFA36";
}
.game-icon-drowning:before {
    content: "\FFA37";
}
.game-icon-duality-mask:before {
    content: "\FFA38";
}
.game-icon-duality:before {
    content: "\FFA39";
}
.game-icon-dust-cloud:before {
    content: "\FFA3A";
}
.game-icon-eagle-emblem:before {
    content: "\FFA3B";
}
.game-icon-earth-crack:before {
    content: "\FFA3C";
}
.game-icon-earth-spit:before {
    content: "\FFA3D";
}
.game-icon-earwig:before {
    content: "\FFA3E";
}
.game-icon-echo-ripples:before {
    content: "\FFA3F";
}
.game-icon-eclipse-flare:before {
    content: "\FFA40";
}
.game-icon-eclipse-saw:before {
    content: "\FFA41";
}
.game-icon-eclipse:before {
    content: "\FFA42";
}
.game-icon-edge-crack:before {
    content: "\FFA43";
}
.game-icon-edged-shield:before {
    content: "\FFA44";
}
.game-icon-egg-clutch:before {
    content: "\FFA45";
}
.game-icon-egg-defense:before {
    content: "\FFA46";
}
.game-icon-elderberry:before {
    content: "\FFA47";
}
.game-icon-electric-whip:before {
    content: "\FFA48";
}
.game-icon-electrical-crescent:before {
    content: "\FFA49";
}
.game-icon-ember-shot:before {
    content: "\FFA4A";
}
.game-icon-embrassed-energy:before {
    content: "\FFA4B";
}
.game-icon-embryo:before {
    content: "\FFA4C";
}
.game-icon-emerald:before {
    content: "\FFA4D";
}
.game-icon-empty-hourglass:before {
    content: "\FFA4E";
}
.game-icon-energise:before {
    content: "\FFA4F";
}
.game-icon-energy-arrow:before {
    content: "\FFA50";
}
.game-icon-energy-breath:before {
    content: "\FFA51";
}
.game-icon-energy-shield:before {
    content: "\FFA52";
}
.game-icon-energy-sword:before {
    content: "\FFA53";
}
.game-icon-engagement-ring:before {
    content: "\FFA54";
}
.game-icon-enlightenment:before {
    content: "\FFA55";
}
.game-icon-ent-mouth:before {
    content: "\FFA56";
}
.game-icon-entangled-typhoon:before {
    content: "\FFA57";
}
.game-icon-envelope:before {
    content: "\FFA58";
}
.game-icon-erlenmeyer:before {
    content: "\FFA59";
}
.game-icon-eruption:before {
    content: "\FFA5A";
}
.game-icon-eskimo:before {
    content: "\FFA5B";
}
.game-icon-evil-bat:before {
    content: "\FFA5C";
}
.game-icon-evil-book:before {
    content: "\FFA5D";
}
.game-icon-evil-bud:before {
    content: "\FFA5E";
}
.game-icon-evil-comet:before {
    content: "\FFA5F";
}
.game-icon-evil-fork:before {
    content: "\FFA60";
}
.game-icon-evil-hand:before {
    content: "\FFA61";
}
.game-icon-evil-minion:before {
    content: "\FFA62";
}
.game-icon-evil-moon:before {
    content: "\FFA63";
}
.game-icon-evil-tree:before {
    content: "\FFA64";
}
.game-icon-expanded-rays:before {
    content: "\FFA65";
}
.game-icon-explosion-rays:before {
    content: "\FFA66";
}
.game-icon-explosive-materials:before {
    content: "\FFA67";
}
.game-icon-explosive-meeting:before {
    content: "\FFA68";
}
.game-icon-extra-lucid:before {
    content: "\FFA69";
}
.game-icon-extraction-orb:before {
    content: "\FFA6A";
}
.game-icon-eye-shield:before {
    content: "\FFA6B";
}
.game-icon-eyeball:before {
    content: "\FFA6C";
}
.game-icon-eyedropper:before {
    content: "\FFA6D";
}
.game-icon-eyestalk:before {
    content: "\FFA6E";
}
.game-icon-fairy-wand:before {
    content: "\FFA6F";
}
.game-icon-fairy-2:before {
    content: "\FFA70";
}
.game-icon-falling-blob:before {
    content: "\FFA71";
}
.game-icon-falling-boulder:before {
    content: "\FFA72";
}
.game-icon-falling-eye:before {
    content: "\FFA73";
}
.game-icon-falling-leaf:before {
    content: "\FFA74";
}
.game-icon-falling-ovoid:before {
    content: "\FFA75";
}
.game-icon-fanged-skull:before {
    content: "\FFA76";
}
.game-icon-fangs-circle:before {
    content: "\FFA77";
}
.game-icon-fast-arrow:before {
    content: "\FFA78";
}
.game-icon-feather-wound:before {
    content: "\FFA79";
}
.game-icon-feather:before {
    content: "\FFA7A";
}
.game-icon-feathered-wing:before {
    content: "\FFA7B";
}
.game-icon-fedora:before {
    content: "\FFA7C";
}
.game-icon-field:before {
    content: "\FFA7D";
}
.game-icon-fire-ace:before {
    content: "\FFA7E";
}
.game-icon-fire-axe:before {
    content: "\FFA7F";
}
.game-icon-fire-bomb:before {
    content: "\FFA80";
}
.game-icon-fire-bottle:before {
    content: "\FFA81";
}
.game-icon-fire-bowl:before {
    content: "\FFA82";
}
.game-icon-fire-breath:before {
    content: "\FFA83";
}
.game-icon-fire-dash:before {
    content: "\FFA84";
}
.game-icon-fire-punch:before {
    content: "\FFA85";
}
.game-icon-fire-ray:before {
    content: "\FFA86";
}
.game-icon-fire-ring:before {
    content: "\FFA87";
}
.game-icon-fire-shield:before {
    content: "\FFA88";
}
.game-icon-fire-silhouette:before {
    content: "\FFA89";
}
.game-icon-fire-tail:before {
    content: "\FFA8A";
}
.game-icon-fire-wave:before {
    content: "\FFA8B";
}
.game-icon-fire-zone:before {
    content: "\FFA8C";
}
.game-icon-fireball:before {
    content: "\FFA8D";
}
.game-icon-firework-rocket:before {
    content: "\FFA8E";
}
.game-icon-fish-corpse:before {
    content: "\FFA8F";
}
.game-icon-fishbone:before {
    content: "\FFA90";
}
.game-icon-fishhook-fork:before {
    content: "\FFA91";
}
.game-icon-fishing-hook:before {
    content: "\FFA92";
}
.game-icon-fishing-net:before {
    content: "\FFA93";
}
.game-icon-fission:before {
    content: "\FFA94";
}
.game-icon-fist:before {
    content: "\FFA95";
}
.game-icon-fizzing-flask:before {
    content: "\FFA96";
}
.game-icon-flame-claws:before {
    content: "\FFA97";
}
.game-icon-flame-spin:before {
    content: "\FFA98";
}
.game-icon-flame-tunnel:before {
    content: "\FFA99";
}
.game-icon-flamed-leaf:before {
    content: "\FFA9A";
}
.game-icon-flaming-arrow:before {
    content: "\FFA9B";
}
.game-icon-flaming-claw:before {
    content: "\FFA9C";
}
.game-icon-flaming-sheet:before {
    content: "\FFA9D";
}
.game-icon-flaming-trident:before {
    content: "\FFA9E";
}
.game-icon-flash-grenade:before {
    content: "\FFA9F";
}
.game-icon-flat-hammer:before {
    content: "\FFAA0";
}
.game-icon-flat-paw-print:before {
    content: "\FFAA1";
}
.game-icon-flat-star:before {
    content: "\FFAA2";
}
.game-icon-fleshy-mass:before {
    content: "\FFAA3";
}
.game-icon-flexible-star:before {
    content: "\FFAA4";
}
.game-icon-floating-crystal:before {
    content: "\FFAA5";
}
.game-icon-flower-pot:before {
    content: "\FFAA6";
}
.game-icon-flower-twirl:before {
    content: "\FFAA7";
}
.game-icon-flowers:before {
    content: "\FFAA8";
}
.game-icon-fluffy-cloud:before {
    content: "\FFAA9";
}
.game-icon-fluffy-flame:before {
    content: "\FFAAA";
}
.game-icon-fluffy-swirl:before {
    content: "\FFAAB";
}
.game-icon-fluffy-trefoil:before {
    content: "\FFAAC";
}
.game-icon-fluffy-wing:before {
    content: "\FFAAD";
}
.game-icon-flying-dagger:before {
    content: "\FFAAE";
}
.game-icon-flying-flag:before {
    content: "\FFAAF";
}
.game-icon-foam:before {
    content: "\FFAB0";
}
.game-icon-foamy-disc:before {
    content: "\FFAB1";
}
.game-icon-focused-lightning:before {
    content: "\FFAB2";
}
.game-icon-folded-paper:before {
    content: "\FFAB3";
}
.game-icon-food-chain:before {
    content: "\FFAB4";
}
.game-icon-foot-trip:before {
    content: "\FFAB5";
}
.game-icon-footprint:before {
    content: "\FFAB6";
}
.game-icon-forward-field:before {
    content: "\FFAB7";
}
.game-icon-fossil:before {
    content: "\FFAB8";
}
.game-icon-fountain-pen:before {
    content: "\FFAB9";
}
.game-icon-fountain:before {
    content: "\FFABA";
}
.game-icon-fox-head:before {
    content: "\FFABB";
}
.game-icon-fragmented-meteor:before {
    content: "\FFABC";
}
.game-icon-fragmented-sword:before {
    content: "\FFABD";
}
.game-icon-fragrance:before {
    content: "\FFABE";
}
.game-icon-frankenstein-creature:before {
    content: "\FFABF";
}
.game-icon-frayed-arrow:before {
    content: "\FFAC0";
}
.game-icon-freedom-dove:before {
    content: "\FFAC1";
}
.game-icon-frog:before {
    content: "\FFAC2";
}
.game-icon-front-teeth:before {
    content: "\FFAC3";
}
.game-icon-frontal-lobe:before {
    content: "\FFAC4";
}
.game-icon-frostfire:before {
    content: "\FFAC5";
}
.game-icon-frozen-arrow:before {
    content: "\FFAC6";
}
.game-icon-frozen-block:before {
    content: "\FFAC7";
}
.game-icon-frozen-orb:before {
    content: "\FFAC8";
}
.game-icon-fruiting:before {
    content: "\FFAC9";
}
.game-icon-fulguro-punch:before {
    content: "\FFACA";
}
.game-icon-galleon:before {
    content: "\FFACB";
}
.game-icon-gamepad-cross:before {
    content: "\FFACC";
}
.game-icon-gas-mask:before {
    content: "\FFACD";
}
.game-icon-gavel:before {
    content: "\FFACE";
}
.game-icon-gaze:before {
    content: "\FFACF";
}
.game-icon-gear-hammer:before {
    content: "\FFAD0";
}
.game-icon-gears:before {
    content: "\FFAD1";
}
.game-icon-gecko:before {
    content: "\FFAD2";
}
.game-icon-gem-chain:before {
    content: "\FFAD3";
}
.game-icon-gem-necklace:before {
    content: "\FFAD4";
}
.game-icon-gem-pendant:before {
    content: "\FFAD5";
}
.game-icon-gems:before {
    content: "\FFAD6";
}
.game-icon-ghost-ally:before {
    content: "\FFAD7";
}
.game-icon-ghost:before {
    content: "\FFAD8";
}
.game-icon-gibbet:before {
    content: "\FFAD9";
}
.game-icon-gift-of-knowledge:before {
    content: "\FFADA";
}
.game-icon-gift-trap:before {
    content: "\FFADB";
}
.game-icon-glass-heart:before {
    content: "\FFADC";
}
.game-icon-glass-shot:before {
    content: "\FFADD";
}
.game-icon-globe:before {
    content: "\FFADE";
}
.game-icon-gloop:before {
    content: "\FFADF";
}
.game-icon-glowing-hands:before {
    content: "\FFAE0";
}
.game-icon-gluttonous-smile:before {
    content: "\FFAE1";
}
.game-icon-gluttony:before {
    content: "\FFAE2";
}
.game-icon-gold-scarab:before {
    content: "\FFAE3";
}
.game-icon-gold-shell:before {
    content: "\FFAE4";
}
.game-icon-goo-explosion:before {
    content: "\FFAE5";
}
.game-icon-goo-skull:before {
    content: "\FFAE6";
}
.game-icon-goo-spurt:before {
    content: "\FFAE7";
}
.game-icon-gooey-daemon:before {
    content: "\FFAE8";
}
.game-icon-gooey-eyed-sun:before {
    content: "\FFAE9";
}
.game-icon-gooey-impact:before {
    content: "\FFAEA";
}
.game-icon-gooey-molecule:before {
    content: "\FFAEB";
}
.game-icon-gooey-sword:before {
    content: "\FFAEC";
}
.game-icon-gothic-cross:before {
    content: "\FFAED";
}
.game-icon-grab:before {
    content: "\FFAEE";
}
.game-icon-grapes:before {
    content: "\FFAEF";
}
.game-icon-grapple:before {
    content: "\FFAF0";
}
.game-icon-grasping-claws:before {
    content: "\FFAF1";
}
.game-icon-grease-trap:before {
    content: "\FFAF2";
}
.game-icon-grenade:before {
    content: "\FFAF3";
}
.game-icon-grim-reaper:before {
    content: "\FFAF4";
}
.game-icon-ground-sprout:before {
    content: "\FFAF5";
}
.game-icon-groundbreaker:before {
    content: "\FFAF6";
}
.game-icon-grouped-drops:before {
    content: "\FFAF7";
}
.game-icon-guarded-tower:before {
    content: "\FFAF8";
}
.game-icon-guillotine:before {
    content: "\FFAF9";
}
.game-icon-guitar:before {
    content: "\FFAFA";
}
.game-icon-gunshot:before {
    content: "\FFAFB";
}
.game-icon-halberd-shuriken:before {
    content: "\FFAFC";
}
.game-icon-halberd:before {
    content: "\FFAFD";
}
.game-icon-half-heart:before {
    content: "\FFAFE";
}
.game-icon-half-tornado:before {
    content: "\FFAFF";
}
.game-icon-hammer-drop:before {
    content: "\FFB00";
}
.game-icon-hammer-nails:before {
    content: "\FFB01";
}
.game-icon-hand:before {
    content: "\FFB02";
}
.game-icon-handcuffs:before {
    content: "\FFB03";
}
.game-icon-hanging-spider:before {
    content: "\FFB04";
}
.game-icon-happy-skull:before {
    content: "\FFB05";
}
.game-icon-harpoon-chain:before {
    content: "\FFB06";
}
.game-icon-harpoon-trident:before {
    content: "\FFB07";
}
.game-icon-harpy:before {
    content: "\FFB08";
}
.game-icon-harry-potter-skull:before {
    content: "\FFB09";
}
.game-icon-hatchets:before {
    content: "\FFB0A";
}
.game-icon-haunting:before {
    content: "\FFB0B";
}
.game-icon-hawk-emblem:before {
    content: "\FFB0C";
}
.game-icon-hazard-sign:before {
    content: "\FFB0D";
}
.game-icon-headshot:before {
    content: "\FFB0E";
}
.game-icon-heart-bottle:before {
    content: "\FFB0F";
}
.game-icon-heart-drop:before {
    content: "\FFB10";
}
.game-icon-heart-inside:before {
    content: "\FFB11";
}
.game-icon-heart-organ:before {
    content: "\FFB12";
}
.game-icon-heart-tower:before {
    content: "\FFB13";
}
.game-icon-heartburn:before {
    content: "\FFB14";
}
.game-icon-heat-haze:before {
    content: "\FFB15";
}
.game-icon-heavy-arrow:before {
    content: "\FFB16";
}
.game-icon-heavy-fall:before {
    content: "\FFB17";
}
.game-icon-heavy-helm:before {
    content: "\FFB18";
}
.game-icon-heavy-lightning:before {
    content: "\FFB19";
}
.game-icon-heavy-rain:before {
    content: "\FFB1A";
}
.game-icon-heavy-thorny-triskelion:before {
    content: "\FFB1B";
}
.game-icon-heavy-timer:before {
    content: "\FFB1C";
}
.game-icon-helmet-head-shot:before {
    content: "\FFB1D";
}
.game-icon-hidden:before {
    content: "\FFB1E";
}
.game-icon-high-five:before {
    content: "\FFB1F";
}
.game-icon-high-shot:before {
    content: "\FFB20";
}
.game-icon-hollow-cat:before {
    content: "\FFB21";
}
.game-icon-holy-grail:before {
    content: "\FFB22";
}
.game-icon-holy-symbol:before {
    content: "\FFB23";
}
.game-icon-honeycomb:before {
    content: "\FFB24";
}
.game-icon-honeypot:before {
    content: "\FFB25";
}
.game-icon-hood:before {
    content: "\FFB26";
}
.game-icon-hoof:before {
    content: "\FFB27";
}
.game-icon-horn-internal:before {
    content: "\FFB28";
}
.game-icon-horned-helm:before {
    content: "\FFB29";
}
.game-icon-horned-skull:before {
    content: "\FFB2A";
}
.game-icon-horse-head-2:before {
    content: "\FFB2B";
}
.game-icon-hospital-cross:before {
    content: "\FFB2C";
}
.game-icon-hot-spices:before {
    content: "\FFB2D";
}
.game-icon-hound:before {
    content: "\FFB2E";
}
.game-icon-hourglass:before {
    content: "\FFB2F";
}
.game-icon-hunting-horn:before {
    content: "\FFB30";
}
.game-icon-hydra-shot:before {
    content: "\FFB31";
}
.game-icon-hydra:before {
    content: "\FFB32";
}
.game-icon-hypersonic-bolt:before {
    content: "\FFB33";
}
.game-icon-hypersonic-melon:before {
    content: "\FFB34";
}
.game-icon-hypodermic-test:before {
    content: "\FFB35";
}
.game-icon-ice-bolt:before {
    content: "\FFB36";
}
.game-icon-ice-bomb:before {
    content: "\FFB37";
}
.game-icon-ice-cube:before {
    content: "\FFB38";
}
.game-icon-ice-shield:before {
    content: "\FFB39";
}
.game-icon-ice-spear:before {
    content: "\FFB3A";
}
.game-icon-icebergs:before {
    content: "\FFB3B";
}
.game-icon-icicles-aura:before {
    content: "\FFB3C";
}
.game-icon-icicles-fence:before {
    content: "\FFB3D";
}
.game-icon-ifrit:before {
    content: "\FFB3E";
}
.game-icon-imbricated-arrows:before {
    content: "\FFB3F";
}
.game-icon-imp-laugh:before {
    content: "\FFB40";
}
.game-icon-imp:before {
    content: "\FFB41";
}
.game-icon-impact-point:before {
    content: "\FFB42";
}
.game-icon-implosion:before {
    content: "\FFB43";
}
.game-icon-imprisoned:before {
    content: "\FFB44";
}
.game-icon-incense:before {
    content: "\FFB45";
}
.game-icon-incisors:before {
    content: "\FFB46";
}
.game-icon-incoming-rocket:before {
    content: "\FFB47";
}
.game-icon-infested-mass:before {
    content: "\FFB48";
}
.game-icon-ink-swirl:before {
    content: "\FFB49";
}
.game-icon-inner-self:before {
    content: "\FFB4A";
}
.game-icon-insect-jaws:before {
    content: "\FFB4B";
}
.game-icon-interdiction:before {
    content: "\FFB4C";
}
.game-icon-interlaced-tentacles:before {
    content: "\FFB4D";
}
.game-icon-interleaved-arrows:before {
    content: "\FFB4E";
}
.game-icon-interleaved-claws:before {
    content: "\FFB4F";
}
.game-icon-internal-injury:before {
    content: "\FFB50";
}
.game-icon-internal-organ:before {
    content: "\FFB51";
}
.game-icon-interstellar-path:before {
    content: "\FFB52";
}
.game-icon-ion-cannon-blast:before {
    content: "\FFB53";
}
.game-icon-iron-mask:before {
    content: "\FFB54";
}
.game-icon-james-bond-aperture:before {
    content: "\FFB55";
}
.game-icon-jawbone:before {
    content: "\FFB56";
}
.game-icon-jellyfish:before {
    content: "\FFB57";
}
.game-icon-jetpack:before {
    content: "\FFB58";
}
.game-icon-jeweled-chalice:before {
    content: "\FFB59";
}
.game-icon-jigsaw-box:before {
    content: "\FFB5A";
}
.game-icon-jigsaw-piece:before {
    content: "\FFB5B";
}
.game-icon-journey:before {
    content: "\FFB5C";
}
.game-icon-juggler:before {
    content: "\FFB5D";
}
.game-icon-justice-star:before {
    content: "\FFB5E";
}
.game-icon-kaleidoscope-pearls:before {
    content: "\FFB5F";
}
.game-icon-key:before {
    content: "\FFB60";
}
.game-icon-kindle:before {
    content: "\FFB61";
}
.game-icon-king:before {
    content: "\FFB62";
}
.game-icon-kitchen-knives:before {
    content: "\FFB63";
}
.game-icon-knapsack:before {
    content: "\FFB64";
}
.game-icon-knife-fork:before {
    content: "\FFB65";
}
.game-icon-knife-thrust:before {
    content: "\FFB66";
}
.game-icon-lamellar:before {
    content: "\FFB67";
}
.game-icon-lamprey-mouth:before {
    content: "\FFB68";
}
.game-icon-land-mine:before {
    content: "\FFB69";
}
.game-icon-lantern-flame:before {
    content: "\FFB6A";
}
.game-icon-lantern:before {
    content: "\FFB6B";
}
.game-icon-large-wound:before {
    content: "\FFB6C";
}
.game-icon-laser-blast:before {
    content: "\FFB6D";
}
.game-icon-laser-precision:before {
    content: "\FFB6E";
}
.game-icon-laser-sparks:before {
    content: "\FFB6F";
}
.game-icon-laser-warning:before {
    content: "\FFB70";
}
.game-icon-laserburn:before {
    content: "\FFB71";
}
.game-icon-laurel-crown:before {
    content: "\FFB72";
}
.game-icon-laurels:before {
    content: "\FFB73";
}
.game-icon-law-star:before {
    content: "\FFB74";
}
.game-icon-layered-armor:before {
    content: "\FFB75";
}
.game-icon-leaf-skeleton:before {
    content: "\FFB76";
}
.game-icon-leaf-swirl:before {
    content: "\FFB77";
}
.game-icon-leaky-skull:before {
    content: "\FFB78";
}
.game-icon-leather-boot:before {
    content: "\FFB79";
}
.game-icon-leather-vest:before {
    content: "\FFB7A";
}
.game-icon-leeching-worm:before {
    content: "\FFB7B";
}
.game-icon-letter-bomb:before {
    content: "\FFB7C";
}
.game-icon-lever:before {
    content: "\FFB7D";
}
.game-icon-life-in-the-balance:before {
    content: "\FFB7E";
}
.game-icon-life-support:before {
    content: "\FFB7F";
}
.game-icon-life-tap:before {
    content: "\FFB80";
}
.game-icon-lift:before {
    content: "\FFB81";
}
.game-icon-light-bulb:before {
    content: "\FFB82";
}
.game-icon-light-thorny-triskelion:before {
    content: "\FFB83";
}
.game-icon-lightning-arc:before {
    content: "\FFB84";
}
.game-icon-lightning-bow:before {
    content: "\FFB85";
}
.game-icon-lightning-branches:before {
    content: "\FFB86";
}
.game-icon-lightning-dissipation:before {
    content: "\FFB87";
}
.game-icon-lightning-electron:before {
    content: "\FFB88";
}
.game-icon-lightning-frequency:before {
    content: "\FFB89";
}
.game-icon-lightning-helix:before {
    content: "\FFB8A";
}
.game-icon-lightning-mask:before {
    content: "\FFB8B";
}
.game-icon-lightning-saber:before {
    content: "\FFB8C";
}
.game-icon-lightning-shadow:before {
    content: "\FFB8D";
}
.game-icon-lightning-shield:before {
    content: "\FFB8E";
}
.game-icon-lightning-shout:before {
    content: "\FFB8F";
}
.game-icon-lightning-slashes:before {
    content: "\FFB90";
}
.game-icon-lightning-spanner:before {
    content: "\FFB91";
}
.game-icon-lightning-storm:before {
    content: "\FFB92";
}
.game-icon-lightning-tear:before {
    content: "\FFB93";
}
.game-icon-lightning-tree:before {
    content: "\FFB94";
}
.game-icon-lightning-trio:before {
    content: "\FFB95";
}
.game-icon-linden-leaf:before {
    content: "\FFB96";
}
.game-icon-linked-rings:before {
    content: "\FFB97";
}
.game-icon-lion:before {
    content: "\FFB98";
}
.game-icon-lips:before {
    content: "\FFB99";
}
.game-icon-lit-candelabra:before {
    content: "\FFB9A";
}
.game-icon-lizard-tongue:before {
    content: "\FFB9B";
}
.game-icon-lizardman:before {
    content: "\FFB9C";
}
.game-icon-lob-arrow:before {
    content: "\FFB9D";
}
.game-icon-locked-chest:before {
    content: "\FFB9E";
}
.game-icon-locked-fortress:before {
    content: "\FFB9F";
}
.game-icon-long-antennae-bug:before {
    content: "\FFBA0";
}
.game-icon-lotus-flower:before {
    content: "\FFBA1";
}
.game-icon-lotus:before {
    content: "\FFBA2";
}
.game-icon-love-howl:before {
    content: "\FFBA3";
}
.game-icon-love-injection:before {
    content: "\FFBA4";
}
.game-icon-love-song:before {
    content: "\FFBA5";
}
.game-icon-lyre:before {
    content: "\FFBA6";
}
.game-icon-mace-head:before {
    content: "\FFBA7";
}
.game-icon-machete:before {
    content: "\FFBA8";
}
.game-icon-mad-scientist:before {
    content: "\FFBA9";
}
.game-icon-maggot:before {
    content: "\FFBAA";
}
.game-icon-magic-gate:before {
    content: "\FFBAB";
}
.game-icon-magic-lamp:before {
    content: "\FFBAC";
}
.game-icon-magic-palm:before {
    content: "\FFBAD";
}
.game-icon-magic-portal:before {
    content: "\FFBAE";
}
.game-icon-magic-shield:before {
    content: "\FFBAF";
}
.game-icon-magic-swirl:before {
    content: "\FFBB0";
}
.game-icon-magnet-blast:before {
    content: "\FFBB1";
}
.game-icon-magnet:before {
    content: "\FFBB2";
}
.game-icon-magnifying-glass:before {
    content: "\FFBB3";
}
.game-icon-mail-shirt:before {
    content: "\FFBB4";
}
.game-icon-mailed-fist:before {
    content: "\FFBB5";
}
.game-icon-manacles:before {
    content: "\FFBB6";
}
.game-icon-mantrap:before {
    content: "\FFBB7";
}
.game-icon-maple-leaf:before {
    content: "\FFBB8";
}
.game-icon-marrow-drain:before {
    content: "\FFBB9";
}
.game-icon-martini:before {
    content: "\FFBBA";
}
.game-icon-masked-spider:before {
    content: "\FFBBB";
}
.game-icon-master-of-arms:before {
    content: "\FFBBC";
}
.game-icon-match-head:before {
    content: "\FFBBD";
}
.game-icon-match-tip:before {
    content: "\FFBBE";
}
.game-icon-materials-science:before {
    content: "\FFBBF";
}
.game-icon-maze-cornea:before {
    content: "\FFBC0";
}
.game-icon-maze-saw:before {
    content: "\FFBC1";
}
.game-icon-maze:before {
    content: "\FFBC2";
}
.game-icon-meat-cleaver:before {
    content: "\FFBC3";
}
.game-icon-meat-hook:before {
    content: "\FFBC4";
}
.game-icon-meat:before {
    content: "\FFBC5";
}
.game-icon-mechanical-arm:before {
    content: "\FFBC6";
}
.game-icon-medal-skull:before {
    content: "\FFBC7";
}
.game-icon-medal:before {
    content: "\FFBC8";
}
.game-icon-meditation:before {
    content: "\FFBC9";
}
.game-icon-megabot:before {
    content: "\FFBCA";
}
.game-icon-mesh-ball:before {
    content: "\FFBCB";
}
.game-icon-metal-bar:before {
    content: "\FFBCC";
}
.game-icon-metal-disc:before {
    content: "\FFBCD";
}
.game-icon-metal-hand:before {
    content: "\FFBCE";
}
.game-icon-metal-scales:before {
    content: "\FFBCF";
}
.game-icon-meteor-impact:before {
    content: "\FFBD0";
}
.game-icon-microchip:before {
    content: "\FFBD1";
}
.game-icon-microscope-lens:before {
    content: "\FFBD2";
}
.game-icon-middle-arrow:before {
    content: "\FFBD3";
}
.game-icon-midnight-claw:before {
    content: "\FFBD4";
}
.game-icon-mighty-spanner:before {
    content: "\FFBD5";
}
.game-icon-mine-explosion:before {
    content: "\FFBD6";
}
.game-icon-mineral-heart:before {
    content: "\FFBD7";
}
.game-icon-minigun:before {
    content: "\FFBD8";
}
.game-icon-mining:before {
    content: "\FFBD9";
}
.game-icon-minions:before {
    content: "\FFBDA";
}
.game-icon-minotaur:before {
    content: "\FFBDB";
}
.game-icon-miracle-medecine:before {
    content: "\FFBDC";
}
.game-icon-mirror-mirror:before {
    content: "\FFBDD";
}
.game-icon-missile-mech:before {
    content: "\FFBDE";
}
.game-icon-missile-pod:before {
    content: "\FFBDF";
}
.game-icon-missile-swarm:before {
    content: "\FFBE0";
}
.game-icon-mite:before {
    content: "\FFBE1";
}
.game-icon-moebius-star:before {
    content: "\FFBE2";
}
.game-icon-moebius-trefoil:before {
    content: "\FFBE3";
}
.game-icon-moebius-triangle:before {
    content: "\FFBE4";
}
.game-icon-molecule:before {
    content: "\FFBE5";
}
.game-icon-molotov:before {
    content: "\FFBE6";
}
.game-icon-monkey:before {
    content: "\FFBE7";
}
.game-icon-monster-grasp:before {
    content: "\FFBE8";
}
.game-icon-moon-claws:before {
    content: "\FFBE9";
}
.game-icon-moon:before {
    content: "\FFBEA";
}
.game-icon-morbid-humour:before {
    content: "\FFBEB";
}
.game-icon-mountains:before {
    content: "\FFBEC";
}
.game-icon-mountaintop:before {
    content: "\FFBED";
}
.game-icon-mouse-2:before {
    content: "\FFBEE";
}
.game-icon-mouth-watering:before {
    content: "\FFBEF";
}
.game-icon-movement-sensor:before {
    content: "\FFBF0";
}
.game-icon-mucous-pillar:before {
    content: "\FFBF1";
}
.game-icon-muscle-fat:before {
    content: "\FFBF2";
}
.game-icon-muscle-up:before {
    content: "\FFBF3";
}
.game-icon-mushroom-cloud:before {
    content: "\FFBF4";
}
.game-icon-mushroom-gills:before {
    content: "\FFBF5";
}
.game-icon-mushroom:before {
    content: "\FFBF6";
}
.game-icon-music-spell:before {
    content: "\FFBF7";
}
.game-icon-nailed-foot:before {
    content: "\FFBF8";
}
.game-icon-nailed-head:before {
    content: "\FFBF9";
}
.game-icon-nails:before {
    content: "\FFBFA";
}
.game-icon-needle-drill:before {
    content: "\FFBFB";
}
.game-icon-needle-jaws:before {
    content: "\FFBFC";
}
.game-icon-nested-eclipses:before {
    content: "\FFBFD";
}
.game-icon-new-shoot:before {
    content: "\FFBFE";
}
.game-icon-night-sky:before {
    content: "\FFBFF";
}
.game-icon-ninja-mask:before {
    content: "\FFC00";
}
.game-icon-noodle-ball:before {
    content: "\FFC01";
}
.game-icon-nothing-to-say:before {
    content: "\FFC02";
}
.game-icon-oak:before {
    content: "\FFC03";
}
.game-icon-oat:before {
    content: "\FFC04";
}
.game-icon-octogonal-eye:before {
    content: "\FFC05";
}
.game-icon-octoman:before {
    content: "\FFC06";
}
.game-icon-octopus:before {
    content: "\FFC07";
}
.game-icon-oily-spiral:before {
    content: "\FFC08";
}
.game-icon-omega:before {
    content: "\FFC09";
}
.game-icon-on-target:before {
    content: "\FFC0A";
}
.game-icon-one-eyed:before {
    content: "\FFC0B";
}
.game-icon-open-book:before {
    content: "\FFC0C";
}
.game-icon-open-wound:before {
    content: "\FFC0D";
}
.game-icon-opening-shell:before {
    content: "\FFC0E";
}
.game-icon-oppression:before {
    content: "\FFC0F";
}
.game-icon-orb-direction:before {
    content: "\FFC10";
}
.game-icon-orbital-rays:before {
    content: "\FFC11";
}
.game-icon-orbital:before {
    content: "\FFC12";
}
.game-icon-ouroboros:before {
    content: "\FFC13";
}
.game-icon-over-infinity:before {
    content: "\FFC14";
}
.game-icon-overdose:before {
    content: "\FFC15";
}
.game-icon-overdrive:before {
    content: "\FFC16";
}
.game-icon-owl:before {
    content: "\FFC17";
}
.game-icon-padlock:before {
    content: "\FFC18";
}
.game-icon-palm:before {
    content: "\FFC19";
}
.game-icon-paper-arrow:before {
    content: "\FFC1A";
}
.game-icon-paper-bomb:before {
    content: "\FFC1B";
}
.game-icon-paper-lantern:before {
    content: "\FFC1C";
}
.game-icon-papers:before {
    content: "\FFC1D";
}
.game-icon-parachute:before {
    content: "\FFC1E";
}
.game-icon-paranoia:before {
    content: "\FFC1F";
}
.game-icon-parmecia:before {
    content: "\FFC20";
}
.game-icon-parrot-head:before {
    content: "\FFC21";
}
.game-icon-paw-front:before {
    content: "\FFC22";
}
.game-icon-paw-heart:before {
    content: "\FFC23";
}
.game-icon-paw-print:before {
    content: "\FFC24";
}
.game-icon-paw:before {
    content: "\FFC25";
}
.game-icon-pawn:before {
    content: "\FFC26";
}
.game-icon-peaks:before {
    content: "\FFC27";
}
.game-icon-pentagram-rose:before {
    content: "\FFC28";
}
.game-icon-pentarrows-tornado:before {
    content: "\FFC29";
}
.game-icon-perfume-bottle:before {
    content: "\FFC2A";
}
.game-icon-perpendicular-rings:before {
    content: "\FFC2B";
}
.game-icon-pie-slice:before {
    content: "\FFC2C";
}
.game-icon-piece-skull:before {
    content: "\FFC2D";
}
.game-icon-pierced-body:before {
    content: "\FFC2E";
}
.game-icon-pierced-heart:before {
    content: "\FFC2F";
}
.game-icon-piercing-sword:before {
    content: "\FFC30";
}
.game-icon-pig-face:before {
    content: "\FFC31";
}
.game-icon-pill-drop:before {
    content: "\FFC32";
}
.game-icon-pill:before {
    content: "\FFC33";
}
.game-icon-pincers:before {
    content: "\FFC34";
}
.game-icon-pine-tree:before {
    content: "\FFC35";
}
.game-icon-pirate-grave:before {
    content: "\FFC36";
}
.game-icon-pirate-skull:before {
    content: "\FFC37";
}
.game-icon-pizza-cutter:before {
    content: "\FFC38";
}
.game-icon-plain-dagger:before {
    content: "\FFC39";
}
.game-icon-plasma-bolt:before {
    content: "\FFC3A";
}
.game-icon-plastron:before {
    content: "\FFC3B";
}
.game-icon-plate-claw:before {
    content: "\FFC3C";
}
.game-icon-pocket-bow:before {
    content: "\FFC3D";
}
.game-icon-pointing:before {
    content: "\FFC3E";
}
.game-icon-pointy-hat:before {
    content: "\FFC3F";
}
.game-icon-pointy-sword:before {
    content: "\FFC40";
}
.game-icon-poison-bottle:before {
    content: "\FFC41";
}
.game-icon-poison-gas:before {
    content: "\FFC42";
}
.game-icon-pokecog:before {
    content: "\FFC43";
}
.game-icon-poker-hand:before {
    content: "\FFC44";
}
.game-icon-pollen-dust:before {
    content: "\FFC45";
}
.game-icon-portal:before {
    content: "\FFC46";
}
.game-icon-portculis:before {
    content: "\FFC47";
}
.game-icon-potion-ball:before {
    content: "\FFC48";
}
.game-icon-pouring-chalice:before {
    content: "\FFC49";
}
.game-icon-powder:before {
    content: "\FFC4A";
}
.game-icon-power-lightning:before {
    content: "\FFC4B";
}
.game-icon-prayer:before {
    content: "\FFC4C";
}
.game-icon-pretty-fangs:before {
    content: "\FFC4D";
}
.game-icon-processor:before {
    content: "\FFC4E";
}
.game-icon-profit:before {
    content: "\FFC4F";
}
.game-icon-psychic-waves:before {
    content: "\FFC50";
}
.game-icon-pummeled:before {
    content: "\FFC51";
}
.game-icon-pumpkin-lantern:before {
    content: "\FFC52";
}
.game-icon-pumpkin-mask:before {
    content: "\FFC53";
}
.game-icon-punch-blast:before {
    content: "\FFC54";
}
.game-icon-punch:before {
    content: "\FFC55";
}
.game-icon-puppet:before {
    content: "\FFC56";
}
.game-icon-pyromaniac:before {
    content: "\FFC57";
}
.game-icon-quake-stomp:before {
    content: "\FFC58";
}
.game-icon-queen-crown:before {
    content: "\FFC59";
}
.game-icon-quick-slash:before {
    content: "\FFC5A";
}
.game-icon-quicksand:before {
    content: "\FFC5B";
}
.game-icon-quill-ink:before {
    content: "\FFC5C";
}
.game-icon-quill:before {
    content: "\FFC5D";
}
.game-icon-radar-dish:before {
    content: "\FFC5E";
}
.game-icon-radar-sweep:before {
    content: "\FFC5F";
}
.game-icon-radial-balance:before {
    content: "\FFC60";
}
.game-icon-radioactive:before {
    content: "\FFC61";
}
.game-icon-ragged-wound:before {
    content: "\FFC62";
}
.game-icon-rainbow-star:before {
    content: "\FFC63";
}
.game-icon-raining:before {
    content: "\FFC64";
}
.game-icon-rally-the-troops:before {
    content: "\FFC65";
}
.game-icon-ram-2:before {
    content: "\FFC66";
}
.game-icon-rapidshare-arrow:before {
    content: "\FFC67";
}
.game-icon-raven:before {
    content: "\FFC68";
}
.game-icon-ray-gun:before {
    content: "\FFC69";
}
.game-icon-razor-blade:before {
    content: "\FFC6A";
}
.game-icon-reaper-scythe:before {
    content: "\FFC6B";
}
.game-icon-rear-aura:before {
    content: "\FFC6C";
}
.game-icon-recycle:before {
    content: "\FFC6D";
}
.game-icon-relationship-bounds:before {
    content: "\FFC6E";
}
.game-icon-relic-blade:before {
    content: "\FFC6F";
}
.game-icon-resonance:before {
    content: "\FFC70";
}
.game-icon-reticule:before {
    content: "\FFC71";
}
.game-icon-return-arrow:before {
    content: "\FFC72";
}
.game-icon-ribbon:before {
    content: "\FFC73";
}
.game-icon-ribcage:before {
    content: "\FFC74";
}
.game-icon-ringed-beam:before {
    content: "\FFC75";
}
.game-icon-ringed-planet:before {
    content: "\FFC76";
}
.game-icon-ringing-bell:before {
    content: "\FFC77";
}
.game-icon-riot-shield:before {
    content: "\FFC78";
}
.game-icon-roast-chicken:before {
    content: "\FFC79";
}
.game-icon-robe:before {
    content: "\FFC7A";
}
.game-icon-robot-golem:before {
    content: "\FFC7B";
}
.game-icon-rock-2:before {
    content: "\FFC7C";
}
.game-icon-rocket-flight:before {
    content: "\FFC7D";
}
.game-icon-rocket:before {
    content: "\FFC7E";
}
.game-icon-rogue:before {
    content: "\FFC7F";
}
.game-icon-rolling-bomb:before {
    content: "\FFC80";
}
.game-icon-rolling-energy:before {
    content: "\FFC81";
}
.game-icon-root-tip:before {
    content: "\FFC82";
}
.game-icon-rosa-shield:before {
    content: "\FFC83";
}
.game-icon-rose:before {
    content: "\FFC84";
}
.game-icon-rough-wound:before {
    content: "\FFC85";
}
.game-icon-round-bottom-flask:before {
    content: "\FFC86";
}
.game-icon-round-struck:before {
    content: "\FFC87";
}
.game-icon-royal-love:before {
    content: "\FFC88";
}
.game-icon-rss-2:before {
    content: "\FFC89";
}
.game-icon-run:before {
    content: "\FFC8A";
}
.game-icon-rune-stone:before {
    content: "\FFC8B";
}
.game-icon-rune-sword:before {
    content: "\FFC8C";
}
.game-icon-saber-slash:before {
    content: "\FFC8D";
}
.game-icon-saber-tooth:before {
    content: "\FFC8E";
}
.game-icon-sabers-choc:before {
    content: "\FFC8F";
}
.game-icon-sacrificial-dagger:before {
    content: "\FFC90";
}
.game-icon-sad-crab:before {
    content: "\FFC91";
}
.game-icon-salamander:before {
    content: "\FFC92";
}
.game-icon-salt-shaker:before {
    content: "\FFC93";
}
.game-icon-sands-of-time:before {
    content: "\FFC94";
}
.game-icon-saphir:before {
    content: "\FFC95";
}
.game-icon-sattelite:before {
    content: "\FFC96";
}
.game-icon-saw-claw:before {
    content: "\FFC97";
}
.game-icon-scale-mail:before {
    content: "\FFC98";
}
.game-icon-scales:before {
    content: "\FFC99";
}
.game-icon-scallop:before {
    content: "\FFC9A";
}
.game-icon-scalpel-strike:before {
    content: "\FFC9B";
}
.game-icon-scalpel:before {
    content: "\FFC9C";
}
.game-icon-scar-wound:before {
    content: "\FFC9D";
}
.game-icon-scarab-beetle:before {
    content: "\FFC9E";
}
.game-icon-scarecrow:before {
    content: "\FFC9F";
}
.game-icon-scissors-2:before {
    content: "\FFCA0";
}
.game-icon-scorpion-tail:before {
    content: "\FFCA1";
}
.game-icon-scorpion:before {
    content: "\FFCA2";
}
.game-icon-screaming:before {
    content: "\FFCA3";
}
.game-icon-screen-impact:before {
    content: "\FFCA4";
}
.game-icon-screw-2:before {
    content: "\FFCA5";
}
.game-icon-screwdriver:before {
    content: "\FFCA6";
}
.game-icon-scroll-unfurled:before {
    content: "\FFCA7";
}
.game-icon-scythe:before {
    content: "\FFCA8";
}
.game-icon-sea-dragon:before {
    content: "\FFCA9";
}
.game-icon-sea-serpent:before {
    content: "\FFCAA";
}
.game-icon-seated-mouse:before {
    content: "\FFCAB";
}
.game-icon-self-love:before {
    content: "\FFCAC";
}
.game-icon-semi-closed-eye:before {
    content: "\FFCAD";
}
.game-icon-sensuousness:before {
    content: "\FFCAE";
}
.game-icon-sentry-gun:before {
    content: "\FFCAF";
}
.game-icon-serrated-slash:before {
    content: "\FFCB0";
}
.game-icon-sewed-shell:before {
    content: "\FFCB1";
}
.game-icon-sewing-needle:before {
    content: "\FFCB2";
}
.game-icon-shadow-follower:before {
    content: "\FFCB3";
}
.game-icon-shadow-grasp:before {
    content: "\FFCB4";
}
.game-icon-shard-sword:before {
    content: "\FFCB5";
}
.game-icon-shark-jaws:before {
    content: "\FFCB6";
}
.game-icon-sharp-crown:before {
    content: "\FFCB7";
}
.game-icon-sharp-lips:before {
    content: "\FFCB8";
}
.game-icon-sharp-smile:before {
    content: "\FFCB9";
}
.game-icon-sharped-teeth-skull:before {
    content: "\FFCBA";
}
.game-icon-shatter:before {
    content: "\FFCBB";
}
.game-icon-shattered-glass:before {
    content: "\FFCBC";
}
.game-icon-shattered-sword:before {
    content: "\FFCBD";
}
.game-icon-sheikah-eye:before {
    content: "\FFCBE";
}
.game-icon-shield-bounces:before {
    content: "\FFCBF";
}
.game-icon-shield-echoes:before {
    content: "\FFCC0";
}
.game-icon-shield-reflect:before {
    content: "\FFCC1";
}
.game-icon-shieldcomb:before {
    content: "\FFCC2";
}
.game-icon-shining-claw:before {
    content: "\FFCC3";
}
.game-icon-shining-heart:before {
    content: "\FFCC4";
}
.game-icon-shining-sword:before {
    content: "\FFCC5";
}
.game-icon-shiny-apple:before {
    content: "\FFCC6";
}
.game-icon-shiny-entrance:before {
    content: "\FFCC7";
}
.game-icon-shiny-iris:before {
    content: "\FFCC8";
}
.game-icon-shiny-omega:before {
    content: "\FFCC9";
}
.game-icon-shiny-purse:before {
    content: "\FFCCA";
}
.game-icon-shoulder-scales:before {
    content: "\FFCCB";
}
.game-icon-shouting:before {
    content: "\FFCCC";
}
.game-icon-shuriken-aperture:before {
    content: "\FFCCD";
}
.game-icon-shuriken-2:before {
    content: "\FFCCE";
}
.game-icon-shut-rose:before {
    content: "\FFCCF";
}
.game-icon-silence:before {
    content: "\FFCD0";
}
.game-icon-sing:before {
    content: "\FFCD1";
}
.game-icon-sinking-trap:before {
    content: "\FFCD2";
}
.game-icon-sinusoidal-beam:before {
    content: "\FFCD3";
}
.game-icon-six-eyes:before {
    content: "\FFCD4";
}
.game-icon-skeleton-inside:before {
    content: "\FFCD5";
}
.game-icon-skeleton-key:before {
    content: "\FFCD6";
}
.game-icon-skid-mark:before {
    content: "\FFCD7";
}
.game-icon-skull-bolt:before {
    content: "\FFCD8";
}
.game-icon-skull-crack:before {
    content: "\FFCD9";
}
.game-icon-skull-crossed-bones:before {
    content: "\FFCDA";
}
.game-icon-skull-in-jar:before {
    content: "\FFCDB";
}
.game-icon-skull-mask:before {
    content: "\FFCDC";
}
.game-icon-skull-ring:before {
    content: "\FFCDD";
}
.game-icon-skull-shield:before {
    content: "\FFCDE";
}
.game-icon-skull-signet:before {
    content: "\FFCDF";
}
.game-icon-skull-slices:before {
    content: "\FFCE0";
}
.game-icon-slap:before {
    content: "\FFCE1";
}
.game-icon-slashed-shield:before {
    content: "\FFCE2";
}
.game-icon-slavery-whip:before {
    content: "\FFCE3";
}
.game-icon-sleepy:before {
    content: "\FFCE4";
}
.game-icon-sliced-bread:before {
    content: "\FFCE5";
}
.game-icon-slicing-arrow:before {
    content: "\FFCE6";
}
.game-icon-sly:before {
    content: "\FFCE7";
}
.game-icon-small-fire:before {
    content: "\FFCE8";
}
.game-icon-smash-arrows:before {
    content: "\FFCE9";
}
.game-icon-smitten:before {
    content: "\FFCEA";
}
.game-icon-smoking-finger:before {
    content: "\FFCEB";
}
.game-icon-smoking-orb:before {
    content: "\FFCEC";
}
.game-icon-snail:before {
    content: "\FFCED";
}
.game-icon-snake-bite:before {
    content: "\FFCEE";
}
.game-icon-snake-totem:before {
    content: "\FFCEF";
}
.game-icon-snake:before {
    content: "\FFCF0";
}
.game-icon-snatch:before {
    content: "\FFCF1";
}
.game-icon-snout:before {
    content: "\FFCF2";
}
.game-icon-snow-bottle:before {
    content: "\FFCF3";
}
.game-icon-snowflake-1:before {
    content: "\FFCF4";
}
.game-icon-snowflake-2:before {
    content: "\FFCF5";
}
.game-icon-snowing:before {
    content: "\FFCF6";
}
.game-icon-snowman:before {
    content: "\FFCF7";
}
.game-icon-soap-experiment:before {
    content: "\FFCF8";
}
.game-icon-sonic-boom:before {
    content: "\FFCF9";
}
.game-icon-sonic-lightning:before {
    content: "\FFCFA";
}
.game-icon-sonic-screech:before {
    content: "\FFCFB";
}
.game-icon-sonic-shout:before {
    content: "\FFCFC";
}
.game-icon-space-suit:before {
    content: "\FFCFD";
}
.game-icon-spade-skull:before {
    content: "\FFCFE";
}
.game-icon-spade:before {
    content: "\FFCFF";
}
.game-icon-spanner:before {
    content: "\FFD00";
}
.game-icon-spark-spirit:before {
    content: "\FFD01";
}
.game-icon-sparkling-sabre:before {
    content: "\FFD02";
}
.game-icon-sparky-bomb:before {
    content: "\FFD03";
}
.game-icon-sparrow:before {
    content: "\FFD04";
}
.game-icon-spartan:before {
    content: "\FFD05";
}
.game-icon-spatter:before {
    content: "\FFD06";
}
.game-icon-spear-hook:before {
    content: "\FFD07";
}
.game-icon-spears:before {
    content: "\FFD08";
}
.game-icon-spectacle-lenses:before {
    content: "\FFD09";
}
.game-icon-spectacles:before {
    content: "\FFD0A";
}
.game-icon-spectre:before {
    content: "\FFD0B";
}
.game-icon-spider-web:before {
    content: "\FFD0C";
}
.game-icon-spiked-armor:before {
    content: "\FFD0D";
}
.game-icon-spiked-collar:before {
    content: "\FFD0E";
}
.game-icon-spiked-fence:before {
    content: "\FFD0F";
}
.game-icon-spiked-halo:before {
    content: "\FFD10";
}
.game-icon-spiked-mace:before {
    content: "\FFD11";
}
.game-icon-spiked-shell:before {
    content: "\FFD12";
}
.game-icon-spiked-snail:before {
    content: "\FFD13";
}
.game-icon-spiked-tail:before {
    content: "\FFD14";
}
.game-icon-spiked-tentacle:before {
    content: "\FFD15";
}
.game-icon-spiky-eclipse:before {
    content: "\FFD16";
}
.game-icon-spiky-explosion:before {
    content: "\FFD17";
}
.game-icon-spiky-field:before {
    content: "\FFD18";
}
.game-icon-spill:before {
    content: "\FFD19";
}
.game-icon-spinal-coil:before {
    content: "\FFD1A";
}
.game-icon-spine-arrow:before {
    content: "\FFD1B";
}
.game-icon-spinning-blades:before {
    content: "\FFD1C";
}
.game-icon-spinning-ribbons:before {
    content: "\FFD1D";
}
.game-icon-spinning-sword:before {
    content: "\FFD1E";
}
.game-icon-spiral-arrow:before {
    content: "\FFD1F";
}
.game-icon-spiral-bloom:before {
    content: "\FFD20";
}
.game-icon-spiral-bottle:before {
    content: "\FFD21";
}
.game-icon-spiral-shell:before {
    content: "\FFD22";
}
.game-icon-spiral-tentacle:before {
    content: "\FFD23";
}
.game-icon-spiral-thrust:before {
    content: "\FFD24";
}
.game-icon-splash:before {
    content: "\FFD25";
}
.game-icon-splashy-stream:before {
    content: "\FFD26";
}
.game-icon-split-body:before {
    content: "\FFD27";
}
.game-icon-split-cross:before {
    content: "\FFD28";
}
.game-icon-splurt:before {
    content: "\FFD29";
}
.game-icon-spoon:before {
    content: "\FFD2A";
}
.game-icon-spoted-flower:before {
    content: "\FFD2B";
}
.game-icon-spotted-arrowhead:before {
    content: "\FFD2C";
}
.game-icon-spotted-mushroom:before {
    content: "\FFD2D";
}
.game-icon-spotted-wound:before {
    content: "\FFD2E";
}
.game-icon-spoutnik:before {
    content: "\FFD2F";
}
.game-icon-spray:before {
    content: "\FFD30";
}
.game-icon-sprint:before {
    content: "\FFD31";
}
.game-icon-sprout-disc:before {
    content: "\FFD32";
}
.game-icon-sprout:before {
    content: "\FFD33";
}
.game-icon-spyglass:before {
    content: "\FFD34";
}
.game-icon-square-bottle:before {
    content: "\FFD35";
}
.game-icon-squib:before {
    content: "\FFD36";
}
.game-icon-squid-head:before {
    content: "\FFD37";
}
.game-icon-squid:before {
    content: "\FFD38";
}
.game-icon-stag-head:before {
    content: "\FFD39";
}
.game-icon-stalagtite:before {
    content: "\FFD3A";
}
.game-icon-standing-potion:before {
    content: "\FFD3B";
}
.game-icon-star-cycle:before {
    content: "\FFD3C";
}
.game-icon-star-prominences:before {
    content: "\FFD3D";
}
.game-icon-star-pupil:before {
    content: "\FFD3E";
}
.game-icon-star-sattelites:before {
    content: "\FFD3F";
}
.game-icon-star-skull:before {
    content: "\FFD40";
}
.game-icon-star-swirl:before {
    content: "\FFD41";
}
.game-icon-staryu:before {
    content: "\FFD42";
}
.game-icon-static-guard:before {
    content: "\FFD43";
}
.game-icon-static-waves:before {
    content: "\FFD44";
}
.game-icon-static:before {
    content: "\FFD45";
}
.game-icon-steel-claws:before {
    content: "\FFD46";
}
.game-icon-steeltoe-boots:before {
    content: "\FFD47";
}
.game-icon-steelwing-emblem:before {
    content: "\FFD48";
}
.game-icon-stick-splitting:before {
    content: "\FFD49";
}
.game-icon-sticking-plaster:before {
    content: "\FFD4A";
}
.game-icon-stigmata:before {
    content: "\FFD4B";
}
.game-icon-stiletto:before {
    content: "\FFD4C";
}
.game-icon-stitched-wound:before {
    content: "\FFD4D";
}
.game-icon-stomp-tornado:before {
    content: "\FFD4E";
}
.game-icon-stomp:before {
    content: "\FFD4F";
}
.game-icon-stone-axe:before {
    content: "\FFD50";
}
.game-icon-stone-block:before {
    content: "\FFD51";
}
.game-icon-stone-crafting:before {
    content: "\FFD52";
}
.game-icon-stone-spear:before {
    content: "\FFD53";
}
.game-icon-stone-sphere:before {
    content: "\FFD54";
}
.game-icon-stone-tablet:before {
    content: "\FFD55";
}
.game-icon-stone-throne:before {
    content: "\FFD56";
}
.game-icon-stone-tower:before {
    content: "\FFD57";
}
.game-icon-stoned-skull:before {
    content: "\FFD58";
}
.game-icon-stopwatch:before {
    content: "\FFD59";
}
.game-icon-strafe:before {
    content: "\FFD5A";
}
.game-icon-striking-arrows:before {
    content: "\FFD5B";
}
.game-icon-striking-balls:before {
    content: "\FFD5C";
}
.game-icon-striking-clamps:before {
    content: "\FFD5D";
}
.game-icon-striking-diamonds:before {
    content: "\FFD5E";
}
.game-icon-striking-splinter:before {
    content: "\FFD5F";
}
.game-icon-striped-sword:before {
    content: "\FFD60";
}
.game-icon-strong:before {
    content: "\FFD61";
}
.game-icon-suckered-tentacle:before {
    content: "\FFD62";
}
.game-icon-suits:before {
    content: "\FFD63";
}
.game-icon-sun-radiations:before {
    content: "\FFD64";
}
.game-icon-sun:before {
    content: "\FFD65";
}
.game-icon-sunbeams:before {
    content: "\FFD66";
}
.game-icon-sundial:before {
    content: "\FFD67";
}
.game-icon-sunken-eye:before {
    content: "\FFD68";
}
.game-icon-sunrise-2:before {
    content: "\FFD69";
}
.game-icon-super-mushroom:before {
    content: "\FFD6A";
}
.game-icon-supersonic-arrow:before {
    content: "\FFD6B";
}
.game-icon-supersonic-bullet:before {
    content: "\FFD6C";
}
.game-icon-surprised-skull:before {
    content: "\FFD6D";
}
.game-icon-surprised:before {
    content: "\FFD6E";
}
.game-icon-surrounded-eye:before {
    content: "\FFD6F";
}
.game-icon-surrounded-shield:before {
    content: "\FFD70";
}
.game-icon-suspicious:before {
    content: "\FFD71";
}
.game-icon-swallow-2:before {
    content: "\FFD72";
}
.game-icon-swan-breeze:before {
    content: "\FFD73";
}
.game-icon-swan:before {
    content: "\FFD74";
}
.game-icon-swap-bag:before {
    content: "\FFD75";
}
.game-icon-swirl-ring:before {
    content: "\FFD76";
}
.game-icon-swirl-string:before {
    content: "\FFD77";
}
.game-icon-sword-array:before {
    content: "\FFD78";
}
.game-icon-sword-break:before {
    content: "\FFD79";
}
.game-icon-sword-clash:before {
    content: "\FFD7A";
}
.game-icon-sword-hilt:before {
    content: "\FFD7B";
}
.game-icon-sword-in-stone:before {
    content: "\FFD7C";
}
.game-icon-sword-slice:before {
    content: "\FFD7D";
}
.game-icon-sword-smithing:before {
    content: "\FFD7E";
}
.game-icon-sword-spade:before {
    content: "\FFD7F";
}
.game-icon-sword-spin:before {
    content: "\FFD80";
}
.game-icon-sword-tie:before {
    content: "\FFD81";
}
.game-icon-sword-wound:before {
    content: "\FFD82";
}
.game-icon-swords-emblem:before {
    content: "\FFD83";
}
.game-icon-syringe:before {
    content: "\FFD84";
}
.game-icon-tank:before {
    content: "\FFD85";
}
.game-icon-target-arrows:before {
    content: "\FFD86";
}
.game-icon-target-dummy:before {
    content: "\FFD87";
}
.game-icon-target-shot:before {
    content: "\FFD88";
}
.game-icon-targeting:before {
    content: "\FFD89";
}
.game-icon-tattered-banner:before {
    content: "\FFD8A";
}
.game-icon-teapot:before {
    content: "\FFD8B";
}
.game-icon-tear-tracks:before {
    content: "\FFD8C";
}
.game-icon-tearing:before {
    content: "\FFD8D";
}
.game-icon-techno-heart:before {
    content: "\FFD8E";
}
.game-icon-telepathy:before {
    content: "\FFD8F";
}
.game-icon-teleport:before {
    content: "\FFD90";
}
.game-icon-templar-eye:before {
    content: "\FFD91";
}
.game-icon-templar-heart:before {
    content: "\FFD92";
}
.game-icon-temptation:before {
    content: "\FFD93";
}
.game-icon-tension-snowflake:before {
    content: "\FFD94";
}
.game-icon-tentacle-heart:before {
    content: "\FFD95";
}
.game-icon-tentacle-strike:before {
    content: "\FFD96";
}
.game-icon-tentacles-skull:before {
    content: "\FFD97";
}
.game-icon-tentacurl:before {
    content: "\FFD98";
}
.game-icon-terror:before {
    content: "\FFD99";
}
.game-icon-tesla-coil:before {
    content: "\FFD9A";
}
.game-icon-tesla-turret:before {
    content: "\FFD9B";
}
.game-icon-test-tubes:before {
    content: "\FFD9C";
}
.game-icon-thermometer-scale:before {
    content: "\FFD9D";
}
.game-icon-third-eye:before {
    content: "\FFD9E";
}
.game-icon-thor-fist:before {
    content: "\FFD9F";
}
.game-icon-thorn-helix:before {
    content: "\FFDA0";
}
.game-icon-thorned-arrow:before {
    content: "\FFDA1";
}
.game-icon-thorny-tentacle:before {
    content: "\FFDA2";
}
.game-icon-thorny-vine:before {
    content: "\FFDA3";
}
.game-icon-three-burning-balls:before {
    content: "\FFDA4";
}
.game-icon-three-keys:before {
    content: "\FFDA5";
}
.game-icon-three-leaves:before {
    content: "\FFDA6";
}
.game-icon-thrown-charcoal:before {
    content: "\FFDA7";
}
.game-icon-thrown-daggers:before {
    content: "\FFDA8";
}
.game-icon-thrown-knife:before {
    content: "\FFDA9";
}
.game-icon-thrown-spear:before {
    content: "\FFDAA";
}
.game-icon-thunder-blade:before {
    content: "\FFDAB";
}
.game-icon-thunder-skull:before {
    content: "\FFDAC";
}
.game-icon-thunder-struck:before {
    content: "\FFDAD";
}
.game-icon-thunderball:before {
    content: "\FFDAE";
}
.game-icon-tick:before {
    content: "\FFDAF";
}
.game-icon-tied-scroll:before {
    content: "\FFDB0";
}
.game-icon-time-bomb:before {
    content: "\FFDB1";
}
.game-icon-time-trap:before {
    content: "\FFDB2";
}
.game-icon-tinker:before {
    content: "\FFDB3";
}
.game-icon-toad-teeth:before {
    content: "\FFDB4";
}
.game-icon-tombstone:before {
    content: "\FFDB5";
}
.game-icon-tooth:before {
    content: "\FFDB6";
}
.game-icon-top-hat:before {
    content: "\FFDB7";
}
.game-icon-top-paw:before {
    content: "\FFDB8";
}
.game-icon-tornado-discs:before {
    content: "\FFDB9";
}
.game-icon-tornado:before {
    content: "\FFDBA";
}
.game-icon-totem-head:before {
    content: "\FFDBB";
}
.game-icon-totem-mask:before {
    content: "\FFDBC";
}
.game-icon-tower-fall:before {
    content: "\FFDBD";
}
.game-icon-trade:before {
    content: "\FFDBE";
}
.game-icon-transfuse:before {
    content: "\FFDBF";
}
.game-icon-transparent-tubes:before {
    content: "\FFDC0";
}
.game-icon-transportation-rings:before {
    content: "\FFDC1";
}
.game-icon-trap-mask:before {
    content: "\FFDC2";
}
.game-icon-tread:before {
    content: "\FFDC3";
}
.game-icon-treasure-map:before {
    content: "\FFDC4";
}
.game-icon-tree-branch:before {
    content: "\FFDC5";
}
.game-icon-trefoil-lily:before {
    content: "\FFDC6";
}
.game-icon-trefoil-shuriken:before {
    content: "\FFDC7";
}
.game-icon-tribal-mask:before {
    content: "\FFDC8";
}
.game-icon-trident:before {
    content: "\FFDC9";
}
.game-icon-trilobite:before {
    content: "\FFDCA";
}
.game-icon-triorb:before {
    content: "\FFDCB";
}
.game-icon-triple-beak:before {
    content: "\FFDCC";
}
.game-icon-triple-claws:before {
    content: "\FFDCD";
}
.game-icon-triple-corn:before {
    content: "\FFDCE";
}
.game-icon-triple-lock:before {
    content: "\FFDCF";
}
.game-icon-triple-needle:before {
    content: "\FFDD0";
}
.game-icon-triple-plier:before {
    content: "\FFDD1";
}
.game-icon-triple-scratches:before {
    content: "\FFDD2";
}
.game-icon-triple-shells:before {
    content: "\FFDD3";
}
.game-icon-triple-skulls:before {
    content: "\FFDD4";
}
.game-icon-triple-yin:before {
    content: "\FFDD5";
}
.game-icon-tripwire:before {
    content: "\FFDD6";
}
.game-icon-triton-head:before {
    content: "\FFDD7";
}
.game-icon-tron-arrow:before {
    content: "\FFDD8";
}
.game-icon-trophy:before {
    content: "\FFDD9";
}
.game-icon-trousers:before {
    content: "\FFDDA";
}
.game-icon-tumor:before {
    content: "\FFDDB";
}
.game-icon-tune-pitch:before {
    content: "\FFDDC";
}
.game-icon-turd:before {
    content: "\FFDDD";
}
.game-icon-turtle-shell:before {
    content: "\FFDDE";
}
.game-icon-turtle:before {
    content: "\FFDDF";
}
.game-icon-twin-shell:before {
    content: "\FFDE0";
}
.game-icon-twirl-center:before {
    content: "\FFDE1";
}
.game-icon-twirly-flower:before {
    content: "\FFDE2";
}
.game-icon-twister:before {
    content: "\FFDE3";
}
.game-icon-two-feathers:before {
    content: "\FFDE4";
}
.game-icon-two-shadows:before {
    content: "\FFDE5";
}
.game-icon-tyre:before {
    content: "\FFDE6";
}
.game-icon-ubisoft-sun:before {
    content: "\FFDE7";
}
.game-icon-ultrasound:before {
    content: "\FFDE8";
}
.game-icon-umbrella:before {
    content: "\FFDE9";
}
.game-icon-uncertainty:before {
    content: "\FFDEA";
}
.game-icon-unfriendly-fire:before {
    content: "\FFDEB";
}
.game-icon-unlit-bomb:before {
    content: "\FFDEC";
}
.game-icon-unlit-candelabra:before {
    content: "\FFDED";
}
.game-icon-unlocking:before {
    content: "\FFDEE";
}
.game-icon-unstable-orb:before {
    content: "\FFDEF";
}
.game-icon-unstable-projectile:before {
    content: "\FFDF0";
}
.game-icon-valley:before {
    content: "\FFDF1";
}
.game-icon-vanilla-flower:before {
    content: "\FFDF2";
}
.game-icon-vibrating-ball:before {
    content: "\FFDF3";
}
.game-icon-vile-fluid:before {
    content: "\FFDF4";
}
.game-icon-vine-flower:before {
    content: "\FFDF5";
}
.game-icon-vine-leaf:before {
    content: "\FFDF6";
}
.game-icon-vine-whip:before {
    content: "\FFDF7";
}
.game-icon-vintage-robot:before {
    content: "\FFDF8";
}
.game-icon-viola:before {
    content: "\FFDF9";
}
.game-icon-virus:before {
    content: "\FFDFA";
}
.game-icon-visored-helm:before {
    content: "\FFDFB";
}
.game-icon-volcano:before {
    content: "\FFDFC";
}
.game-icon-vomiting:before {
    content: "\FFDFD";
}
.game-icon-voodoo-doll:before {
    content: "\FFDFE";
}
.game-icon-vortex:before {
    content: "\FFDFF";
}
.game-icon-vulture:before {
    content: "\FFE00";
}
.game-icon-walking-boot:before {
    content: "\FFE01";
}
.game-icon-wasp-sting:before {
    content: "\FFE02";
}
.game-icon-water-bolt:before {
    content: "\FFE03";
}
.game-icon-water-splash:before {
    content: "\FFE04";
}
.game-icon-wave-crest:before {
    content: "\FFE05";
}
.game-icon-wave-strike:before {
    content: "\FFE06";
}
.game-icon-waves:before {
    content: "\FFE07";
}
.game-icon-wavy-chains:before {
    content: "\FFE08";
}
.game-icon-wavy-itinerary:before {
    content: "\FFE09";
}
.game-icon-wax-seal:before {
    content: "\FFE0A";
}
.game-icon-web-spit:before {
    content: "\FFE0B";
}
.game-icon-werewolf:before {
    content: "\FFE0C";
}
.game-icon-wheat:before {
    content: "\FFE0D";
}
.game-icon-whip:before {
    content: "\FFE0E";
}
.game-icon-whiplash:before {
    content: "\FFE0F";
}
.game-icon-whirlwind:before {
    content: "\FFE10";
}
.game-icon-white-tower:before {
    content: "\FFE11";
}
.game-icon-wide-arrow-dunk:before {
    content: "\FFE12";
}
.game-icon-wildfires:before {
    content: "\FFE13";
}
.game-icon-william-tell-skull:before {
    content: "\FFE14";
}
.game-icon-william-tell:before {
    content: "\FFE15";
}
.game-icon-wind-hole:before {
    content: "\FFE16";
}
.game-icon-wind-slap:before {
    content: "\FFE17";
}
.game-icon-windy-stripes:before {
    content: "\FFE18";
}
.game-icon-wine-glass:before {
    content: "\FFE19";
}
.game-icon-wing-cloak:before {
    content: "\FFE1A";
}
.game-icon-winged-arrow:before {
    content: "\FFE1B";
}
.game-icon-winged-emblem:before {
    content: "\FFE1C";
}
.game-icon-winged-leg:before {
    content: "\FFE1D";
}
.game-icon-winged-shield:before {
    content: "\FFE1E";
}
.game-icon-winged-sword:before {
    content: "\FFE1F";
}
.game-icon-wingfoot:before {
    content: "\FFE20";
}
.game-icon-witch-flight:before {
    content: "\FFE21";
}
.game-icon-wizard-staff:before {
    content: "\FFE22";
}
.game-icon-wolf-head:before {
    content: "\FFE23";
}
.game-icon-wolf-howl:before {
    content: "\FFE24";
}
.game-icon-wolf-trap:before {
    content: "\FFE25";
}
.game-icon-wolverine-claws-2:before {
    content: "\FFE26";
}
.game-icon-wood-axe:before {
    content: "\FFE27";
}
.game-icon-wooden-door:before {
    content: "\FFE28";
}
.game-icon-wooden-fence:before {
    content: "\FFE29";
}
.game-icon-wooden-sign:before {
    content: "\FFE2A";
}
.game-icon-world:before {
    content: "\FFE2B";
}
.game-icon-worm-mouth:before {
    content: "\FFE2C";
}
.game-icon-worried-eyes:before {
    content: "\FFE2D";
}
.game-icon-wrapped-heart:before {
    content: "\FFE2E";
}
.game-icon-wrapped-sweet:before {
    content: "\FFE2F";
}
.game-icon-wrapping-star:before {
    content: "\FFE30";
}
.game-icon-wrecking-ball:before {
    content: "\FFE31";
}
.game-icon-wyvern:before {
    content: "\FFE32";
}
.game-icon-zebra-shield:before {
    content: "\FFE33";
}
.game-icon-zeus-sword:before {
    content: "\FFE34";
}
.game-icon-zig-arrow:before {
    content: "\FFE35";
}
.game-icon-zigzag-cage:before {
    content: "\FFE36";
}
.game-icon-zigzag-leaf:before {
    content: "\FFE37";
}
.game-icon-zigzag-tune:before {
    content: "\FFE38";
}
.game-icon-amplitude:before {
    content: "\FFE39";
}
.game-icon-artificial-intelligence:before {
    content: "\FFE3A";
}
.game-icon-bomber:before {
    content: "\FFE3B";
}
.game-icon-computing:before {
    content: "\FFE3C";
}
.game-icon-diagram:before {
    content: "\FFE3D";
}
.game-icon-heavy-fighter:before {
    content: "\FFE3E";
}
.game-icon-holosphere:before {
    content: "\FFE3F";
}
.game-icon-light-fighter:before {
    content: "\FFE40";
}
.game-icon-microscope:before {
    content: "\FFE41";
}
.game-icon-power-button:before {
    content: "\FFE42";
}
.game-icon-belt:before {
    content: "\FFE43";
}
.game-icon-cloak:before {
    content: "\FFE44";
}
.game-icon-necklace:before {
    content: "\FFE45";
}
.game-icon-shirt:before {
    content: "\FFE46";
}
.game-icon-trousers-2:before {
    content: "\FFE47";
}
.game-icon-nhbk_bedroll:before {
    content: "\FFE48";
}
.game-icon-nhbk_bracelet:before {
    content: "\FFE49";
}
.game-icon-nhbk_chair:before {
    content: "\FFE4A";
}
.game-icon-nhbk_eyebrow:before {
    content: "\FFE4B";
}
.game-icon-nhbk_hair:before {
    content: "\FFE4C";
}
.game-icon-nhbk_hair2:before {
    content: "\FFE4D";
}
.game-icon-nhbk_hair3:before {
    content: "\FFE4E";
}
.game-icon-nhbk_loaf_bread:before {
    content: "\FFE4F";
}
.game-icon-nhbk_meal:before {
    content: "\FFE50";
}
.game-icon-nhbk_meal2:before {
    content: "\FFE51";
}
.game-icon-nhbk_mistletoes:before {
    content: "\FFE52";
}
.game-icon-nhbk_plate:before {
    content: "\FFE53";
}
.game-icon-nhbk_rope:before {
    content: "\FFE54";
}
.game-icon-nhbk_salad:before {
    content: "\FFE55";
}
.game-icon-nhbk_sausage:before {
    content: "\FFE56";
}
.game-icon-nhbk_saussisson:before {
    content: "\FFE57";
}
.game-icon-nhbk_soup:before {
    content: "\FFE58";
}
.game-icon-nhbk_steak:before {
    content: "\FFE59";
}
.game-icon-nhbk_string:before {
    content: "\FFE5A";
}
.game-icon-nhbk_string2:before {
    content: "\FFE5B";
}
.game-icon-nhbk_underwears:before {
    content: "\FFE5C";
}
.game-icon-nhbk_wardrobe:before {
    content: "\FFE5D";
}
.game-icon-nhbk_yarn:before {
    content: "\FFE5E";
}
.game-icon-elven-castle:before {
    content: "\FFE5F";
}
.game-icon-anti-aircraft-gun:before {
    content: "\FFE60";
}
.game-icon-shooner-sailboat:before {
    content: "\FFE61";
}
.game-icon-small-fishing-sailboat:before {
    content: "\FFE62";
}
.game-icon-battery-0:before {
    content: "\FFE63";
}
.game-icon-battery-100:before {
    content: "\FFE64";
}
.game-icon-battery-25:before {
    content: "\FFE65";
}
.game-icon-battery-50:before {
    content: "\FFE66";
}
.game-icon-battery-75:before {
    content: "\FFE67";
}
.game-icon-battery-minus:before {
    content: "\FFE68";
}
.game-icon-battery-plus:before {
    content: "\FFE69";
}
.game-icon-artillery-shell:before {
    content: "\FFE6A";
}
.game-icon-biplane:before {
    content: "\FFE6B";
}
.game-icon-bunker-assault:before {
    content: "\FFE6C";
}
.game-icon-bunker:before {
    content: "\FFE6D";
}
.game-icon-card-pickup:before {
    content: "\FFE6E";
}
.game-icon-card-play:before {
    content: "\FFE6F";
}
.game-icon-damaged-house:before {
    content: "\FFE70";
}
.game-icon-field-gun:before {
    content: "\FFE71";
}
.game-icon-lost-limb:before {
    content: "\FFE72";
}
.game-icon-military-ambulance:before {
    content: "\FFE73";
}
.game-icon-poppy:before {
    content: "\FFE74";
}
.game-icon-trench-assault:before {
    content: "\FFE75";
}
.game-icon-chocolate-bar:before {
    content: "\FFE76";
}
.game-icon-milk-carton:before {
    content: "\FFE77";
}
.game-icon-peanut:before {
    content: "\FFE78";
}
.game-icon-acid-tube:before {
    content: "\FFE79";
}
.game-icon-acid:before {
    content: "\FFE7A";
}
.game-icon-ammo-box:before {
    content: "\FFE7B";
}
.game-icon-arena:before {
    content: "\FFE7C";
}
.game-icon-battery-pack-alt:before {
    content: "\FFE7D";
}
.game-icon-battery-pack:before {
    content: "\FFE7E";
}
.game-icon-big-egg:before {
    content: "\FFE7F";
}
.game-icon-blast:before {
    content: "\FFE80";
}
.game-icon-blaster:before {
    content: "\FFE81";
}
.game-icon-burn:before {
    content: "\FFE82";
}
.game-icon-cancel:before {
    content: "\FFE83";
}
.game-icon-chaingun:before {
    content: "\FFE84";
}
.game-icon-circular-saw:before {
    content: "\FFE85";
}
.game-icon-claw:before {
    content: "\FFE86";
}
.game-icon-clover-spiked:before {
    content: "\FFE87";
}
.game-icon-clover-2:before {
    content: "\FFE88";
}
.game-icon-crush:before {
    content: "\FFE89";
}
.game-icon-death-skull:before {
    content: "\FFE8A";
}
.game-icon-doubled:before {
    content: "\FFE8B";
}
.game-icon-duel:before {
    content: "\FFE8C";
}
.game-icon-egg-pod:before {
    content: "\FFE8D";
}
.game-icon-electric:before {
    content: "\FFE8E";
}
.game-icon-evil-wings:before {
    content: "\FFE8F";
}
.game-icon-expander:before {
    content: "\FFE90";
}
.game-icon-fall-down:before {
    content: "\FFE91";
}
.game-icon-falling:before {
    content: "\FFE92";
}
.game-icon-fire:before {
    content: "\FFE93";
}
.game-icon-fireflake:before {
    content: "\FFE94";
}
.game-icon-flake:before {
    content: "\FFE95";
}
.game-icon-flamer:before {
    content: "\FFE96";
}
.game-icon-grenade-2:before {
    content: "\FFE97";
}
.game-icon-hand-2:before {
    content: "\FFE98";
}
.game-icon-head-shot:before {
    content: "\FFE99";
}
.game-icon-health-decrease:before {
    content: "\FFE9A";
}
.game-icon-health-increase:before {
    content: "\FFE9B";
}
.game-icon-health-normal:before {
    content: "\FFE9C";
}
.game-icon-helmet:before {
    content: "\FFE9D";
}
.game-icon-help:before {
    content: "\FFE9E";
}
.game-icon-hive:before {
    content: "\FFE9F";
}
.game-icon-hot-surface:before {
    content: "\FFEA0";
}
.game-icon-jet-pack:before {
    content: "\FFEA1";
}
.game-icon-kevlar:before {
    content: "\FFEA2";
}
.game-icon-key-2:before {
    content: "\FFEA3";
}
.game-icon-laser-gun:before {
    content: "\FFEA4";
}
.game-icon-lava:before {
    content: "\FFEA5";
}
.game-icon-level-four-advanced:before {
    content: "\FFEA6";
}
.game-icon-level-four:before {
    content: "\FFEA7";
}
.game-icon-level-three-advanced:before {
    content: "\FFEA8";
}
.game-icon-level-three:before {
    content: "\FFEA9";
}
.game-icon-level-two-advanced:before {
    content: "\FFEAA";
}
.game-icon-level-two:before {
    content: "\FFEAB";
}
.game-icon-lucifer-cannon:before {
    content: "\FFEAC";
}
.game-icon-mass-driver:before {
    content: "\FFEAD";
}
.game-icon-medical-pack-alt:before {
    content: "\FFEAE";
}
.game-icon-medical-pack:before {
    content: "\FFEAF";
}
.game-icon-nodular:before {
    content: "\FFEB0";
}
.game-icon-nuclear:before {
    content: "\FFEB1";
}
.game-icon-overkill:before {
    content: "\FFEB2";
}
.game-icon-overmind:before {
    content: "\FFEB3";
}
.game-icon-poison-cloud:before {
    content: "\FFEB4";
}
.game-icon-poison:before {
    content: "\FFEB5";
}
.game-icon-pounce:before {
    content: "\FFEB6";
}
.game-icon-pulse:before {
    content: "\FFEB7";
}
.game-icon-reactor:before {
    content: "\FFEB8";
}
.game-icon-regeneration:before {
    content: "\FFEB9";
}
.game-icon-revolt-2:before {
    content: "\FFEBA";
}
.game-icon-rifle:before {
    content: "\FFEBB";
}
.game-icon-select:before {
    content: "\FFEBC";
}
.game-icon-shield:before {
    content: "\FFEBD";
}
.game-icon-shotgun:before {
    content: "\FFEBE";
}
.game-icon-slow-blob:before {
    content: "\FFEBF";
}
.game-icon-snorkel:before {
    content: "\FFEC0";
}
.game-icon-spawn-node:before {
    content: "\FFEC1";
}
.game-icon-spikeball:before {
    content: "\FFEC2";
}
.game-icon-spikes-full:before {
    content: "\FFEC3";
}
.game-icon-spikes-half:before {
    content: "\FFEC4";
}
.game-icon-spikes-init:before {
    content: "\FFEC5";
}
.game-icon-spikes:before {
    content: "\FFEC6";
}
.game-icon-splash-2:before {
    content: "\FFEC7";
}
.game-icon-suicide:before {
    content: "\FFEC8";
}
.game-icon-syringe-2:before {
    content: "\FFEC9";
}
.game-icon-target-laser:before {
    content: "\FFECA";
}
.game-icon-targeted:before {
    content: "\FFECB";
}
.game-icon-telefrag:before {
    content: "\FFECC";
}
.game-icon-tesla:before {
    content: "\FFECD";
}
.game-icon-tombstone-2:before {
    content: "\FFECE";
}
.game-icon-trample:before {
    content: "\FFECF";
}
.game-icon-trigger-hurt:before {
    content: "\FFED0";
}
.game-icon-turret:before {
    content: "\FFED1";
}
.game-icon-unplugged:before {
    content: "\FFED2";
}
.game-icon-usable:before {
    content: "\FFED3";
}
.game-icon-vial:before {
    content: "\FFED4";
}
.game-icon-water-drop:before {
    content: "\FFED5";
}
.game-icon-weight-crush:before {
    content: "\FFED6";
}
.game-icon-wrench:before {
    content: "\FFED7";
}
.game-icon-achievement:before {
    content: "\FFED8";
}
.game-icon-air-force:before {
    content: "\FFED9";
}
.game-icon-airplane:before {
    content: "\FFEDA";
}
.game-icon-ak47:before {
    content: "\FFEDB";
}
.game-icon-ak47u:before {
    content: "\FFEDC";
}
.game-icon-allied-star:before {
    content: "\FFEDD";
}
.game-icon-amethyst:before {
    content: "\FFEDE";
}
.game-icon-amputation:before {
    content: "\FFEDF";
}
.game-icon-apc:before {
    content: "\FFEE0";
}
.game-icon-atom:before {
    content: "\FFEE1";
}
.game-icon-balaclava:before {
    content: "\FFEE2";
}
.game-icon-balkenkreuz:before {
    content: "\FFEE3";
}
.game-icon-ballista:before {
    content: "\FFEE4";
}
.game-icon-bat-2:before {
    content: "\FFEE5";
}
.game-icon-baton:before {
    content: "\FFEE6";
}
.game-icon-bayonet:before {
    content: "\FFEE7";
}
.game-icon-big-diamond-ring:before {
    content: "\FFEE8";
}
.game-icon-blood:before {
    content: "\FFEE9";
}
.game-icon-bowie-knife-2:before {
    content: "\FFEEA";
}
.game-icon-box-trap:before {
    content: "\FFEEB";
}
.game-icon-bracers:before {
    content: "\FFEEC";
}
.game-icon-breaking-chain:before {
    content: "\FFEED";
}
.game-icon-brodie-helmet:before {
    content: "\FFEEE";
}
.game-icon-bullseye:before {
    content: "\FFEEF";
}
.game-icon-bundle-grenade:before {
    content: "\FFEF0";
}
.game-icon-burning-skull:before {
    content: "\FFEF1";
}
.game-icon-butterfly-knife-2:before {
    content: "\FFEF2";
}
.game-icon-c96:before {
    content: "\FFEF3";
}
.game-icon-carpet-bombing:before {
    content: "\FFEF4";
}
.game-icon-cement-shoes:before {
    content: "\FFEF5";
}
.game-icon-chess-bishop:before {
    content: "\FFEF6";
}
.game-icon-chess-king:before {
    content: "\FFEF7";
}
.game-icon-chess-knight:before {
    content: "\FFEF8";
}
.game-icon-chess-pawn:before {
    content: "\FFEF9";
}
.game-icon-chess-queen:before {
    content: "\FFEFA";
}
.game-icon-chess-rook:before {
    content: "\FFEFB";
}
.game-icon-click:before {
    content: "\FFEFC";
}
.game-icon-clubs:before {
    content: "\FFEFD";
}
.game-icon-cobra-2:before {
    content: "\FFEFE";
}
.game-icon-colt-m1911:before {
    content: "\FFEFF";
}
.game-icon-combination-lock:before {
    content: "\FFF00";
}
.game-icon-console-controller:before {
    content: "\FFF01";
}
.game-icon-crescent-blade-2:before {
    content: "\FFF02";
}
.game-icon-cultist-2:before {
    content: "\FFF03";
}
.game-icon-cz-skorpion:before {
    content: "\FFF04";
}
.game-icon-d10:before {
    content: "\FFF05";
}
.game-icon-d12:before {
    content: "\FFF06";
}
.game-icon-d4:before {
    content: "\FFF07";
}
.game-icon-desert-eagle:before {
    content: "\FFF08";
}
.game-icon-diamonds:before {
    content: "\FFF09";
}
.game-icon-divided-square:before {
    content: "\FFF0A";
}
.game-icon-donkey:before {
    content: "\FFF0B";
}
.game-icon-dress:before {
    content: "\FFF0C";
}
.game-icon-drop-weapon:before {
    content: "\FFF0D";
}
.game-icon-evil-eyes:before {
    content: "\FFF0E";
}
.game-icon-f1-car:before {
    content: "\FFF0F";
}
.game-icon-famas:before {
    content: "\FFF10";
}
.game-icon-fangs:before {
    content: "\FFF11";
}
.game-icon-fat:before {
    content: "\FFF12";
}
.game-icon-fetus:before {
    content: "\FFF13";
}
.game-icon-fist-2:before {
    content: "\FFF14";
}
.game-icon-flatbed-covered:before {
    content: "\FFF15";
}
.game-icon-flatbed:before {
    content: "\FFF16";
}
.game-icon-fn-fal:before {
    content: "\FFF17";
}
.game-icon-footsteps:before {
    content: "\FFF18";
}
.game-icon-fruit-bowl:before {
    content: "\FFF19";
}
.game-icon-gas-mask-2:before {
    content: "\FFF1A";
}
.game-icon-gladius:before {
    content: "\FFF1B";
}
.game-icon-glider:before {
    content: "\FFF1C";
}
.game-icon-glock:before {
    content: "\FFF1D";
}
.game-icon-goat:before {
    content: "\FFF1E";
}
.game-icon-halt:before {
    content: "\FFF1F";
}
.game-icon-ham-shank:before {
    content: "\FFF20";
}
.game-icon-hang-glider-2:before {
    content: "\FFF21";
}
.game-icon-hasty-grave:before {
    content: "\FFF22";
}
.game-icon-headshot-2:before {
    content: "\FFF23";
}
.game-icon-hearing-disabled:before {
    content: "\FFF24";
}
.game-icon-hearts:before {
    content: "\FFF25";
}
.game-icon-hexes:before {
    content: "\FFF26";
}
.game-icon-inverted-dice-1:before {
    content: "\FFF27";
}
.game-icon-inverted-dice-2:before {
    content: "\FFF28";
}
.game-icon-inverted-dice-3:before {
    content: "\FFF29";
}
.game-icon-inverted-dice-4:before {
    content: "\FFF2A";
}
.game-icon-inverted-dice-5:before {
    content: "\FFF2B";
}
.game-icon-inverted-dice-6:before {
    content: "\FFF2C";
}
.game-icon-iron-cross:before {
    content: "\FFF2D";
}
.game-icon-jeep-2:before {
    content: "\FFF2E";
}
.game-icon-joint:before {
    content: "\FFF2F";
}
.game-icon-kevlar-vest:before {
    content: "\FFF30";
}
.game-icon-knockout:before {
    content: "\FFF31";
}
.game-icon-laser-burst:before {
    content: "\FFF32";
}
.game-icon-lee-enfield:before {
    content: "\FFF33";
}
.game-icon-logging:before {
    content: "\FFF34";
}
.game-icon-long-legged-spider:before {
    content: "\FFF35";
}
.game-icon-luger:before {
    content: "\FFF36";
}
.game-icon-m3-grease-gun:before {
    content: "\FFF37";
}
.game-icon-mac-10:before {
    content: "\FFF38";
}
.game-icon-machine-gun:before {
    content: "\FFF39";
}
.game-icon-minefield:before {
    content: "\FFF3A";
}
.game-icon-molecule-2:before {
    content: "\FFF3B";
}
.game-icon-mounted-knight:before {
    content: "\FFF3C";
}
.game-icon-mp-40:before {
    content: "\FFF3D";
}
.game-icon-mp5-2:before {
    content: "\FFF3E";
}
.game-icon-mp5k:before {
    content: "\FFF3F";
}
.game-icon-musket:before {
    content: "\FFF40";
}
.game-icon-nuclear-bomb:before {
    content: "\FFF41";
}
.game-icon-oil-drum:before {
    content: "\FFF42";
}
.game-icon-open-chest:before {
    content: "\FFF43";
}
.game-icon-open-palm:before {
    content: "\FFF44";
}
.game-icon-open-treasure-chest:before {
    content: "\FFF45";
}
.game-icon-orbit:before {
    content: "\FFF46";
}
.game-icon-p90:before {
    content: "\FFF47";
}
.game-icon-pauldrons:before {
    content: "\FFF48";
}
.game-icon-pc:before {
    content: "\FFF49";
}
.game-icon-pegasus:before {
    content: "\FFF4A";
}
.game-icon-pentacle:before {
    content: "\FFF4B";
}
.game-icon-pestle-mortar:before {
    content: "\FFF4C";
}
.game-icon-pickelhaube:before {
    content: "\FFF4D";
}
.game-icon-pig:before {
    content: "\FFF4E";
}
.game-icon-platform:before {
    content: "\FFF4F";
}
.game-icon-pocket-watch:before {
    content: "\FFF50";
}
.game-icon-pteruges:before {
    content: "\FFF51";
}
.game-icon-race-car:before {
    content: "\FFF52";
}
.game-icon-raise-skeleton:before {
    content: "\FFF53";
}
.game-icon-raise-zombie:before {
    content: "\FFF54";
}
.game-icon-rank-1:before {
    content: "\FFF55";
}
.game-icon-rank-2:before {
    content: "\FFF56";
}
.game-icon-rank-3:before {
    content: "\FFF57";
}
.game-icon-read:before {
    content: "\FFF58";
}
.game-icon-retro-controller:before {
    content: "\FFF59";
}
.game-icon-revolver-2:before {
    content: "\FFF5A";
}
.game-icon-sea-creature:before {
    content: "\FFF5B";
}
.game-icon-shield-disabled:before {
    content: "\FFF5C";
}
.game-icon-siege-ram:before {
    content: "\FFF5D";
}
.game-icon-sight-disabled:before {
    content: "\FFF5E";
}
.game-icon-siren:before {
    content: "\FFF5F";
}
.game-icon-skeleton:before {
    content: "\FFF60";
}
.game-icon-smartphone-2:before {
    content: "\FFF61";
}
.game-icon-solar-power:before {
    content: "\FFF62";
}
.game-icon-sound-waves:before {
    content: "\FFF63";
}
.game-icon-spades:before {
    content: "\FFF64";
}
.game-icon-spectre-m4:before {
    content: "\FFF65";
}
.game-icon-spiked-ball:before {
    content: "\FFF66";
}
.game-icon-spiked-bat:before {
    content: "\FFF67";
}
.game-icon-spinning-top:before {
    content: "\FFF68";
}
.game-icon-spotted-bug:before {
    content: "\FFF69";
}
.game-icon-stahlhelm:before {
    content: "\FFF6A";
}
.game-icon-steel-claws-2:before {
    content: "\FFF6B";
}
.game-icon-steel-door:before {
    content: "\FFF6C";
}
.game-icon-steyr-aug:before {
    content: "\FFF6D";
}
.game-icon-stick-grenade:before {
    content: "\FFF6E";
}
.game-icon-stiletto-2:before {
    content: "\FFF6F";
}
.game-icon-stopwatch-2:before {
    content: "\FFF70";
}
.game-icon-swirled-shell:before {
    content: "\FFF71";
}
.game-icon-switchblade:before {
    content: "\FFF72";
}
.game-icon-tablet:before {
    content: "\FFF73";
}
.game-icon-talk:before {
    content: "\FFF74";
}
.game-icon-tank-tread:before {
    content: "\FFF75";
}
.game-icon-tec-9:before {
    content: "\FFF76";
}
.game-icon-telescopic-baton:before {
    content: "\FFF77";
}
.game-icon-teller-mine:before {
    content: "\FFF78";
}
.game-icon-thompson-m1:before {
    content: "\FFF79";
}
.game-icon-thompson-m1928:before {
    content: "\FFF7A";
}
.game-icon-topaz:before {
    content: "\FFF7B";
}
.game-icon-trench-body-armor:before {
    content: "\FFF7C";
}
.game-icon-trench-knife:before {
    content: "\FFF7D";
}
.game-icon-trench-spade:before {
    content: "\FFF7E";
}
.game-icon-troll:before {
    content: "\FFF7F";
}
.game-icon-walther-ppk:before {
    content: "\FFF80";
}
.game-icon-winchester-rifle:before {
    content: "\FFF81";
}
.game-icon-wooden-helmet:before {
    content: "\FFF82";
}
.game-icon-zeppelin:before {
    content: "\FFF83";
}
.game-icon-bear-face:before {
    content: "\FFF84";
}
.game-icon-digital-trace:before {
    content: "\FFF85";
}
.game-icon-potion-of-madness:before {
    content: "\FFF86";
}
.game-icon-infinity:before {
    content: "\FFF87";
}
.game-icon-salmon:before {
    content: "\FFF88";
}
.game-icon-abstract-001:before {
    content: "\FFF89";
}
.game-icon-abstract-002:before {
    content: "\FFF8A";
}
.game-icon-abstract-003:before {
    content: "\FFF8B";
}
.game-icon-abstract-004:before {
    content: "\FFF8C";
}
.game-icon-abstract-005:before {
    content: "\FFF8D";
}
.game-icon-abstract-006:before {
    content: "\FFF8E";
}
.game-icon-abstract-007:before {
    content: "\FFF8F";
}
.game-icon-abstract-008:before {
    content: "\FFF90";
}
.game-icon-abstract-009:before {
    content: "\FFF91";
}
.game-icon-abstract-010:before {
    content: "\FFF92";
}
.game-icon-abstract-011:before {
    content: "\FFF93";
}
.game-icon-abstract-012:before {
    content: "\FFF94";
}
.game-icon-abstract-013:before {
    content: "\FFF95";
}
.game-icon-abstract-014:before {
    content: "\FFF96";
}
.game-icon-abstract-015:before {
    content: "\FFF97";
}
.game-icon-abstract-016:before {
    content: "\FFF98";
}
.game-icon-abstract-017:before {
    content: "\FFF99";
}
.game-icon-abstract-018:before {
    content: "\FFF9A";
}
.game-icon-abstract-019:before {
    content: "\FFF9B";
}
.game-icon-abstract-020:before {
    content: "\FFF9C";
}
.game-icon-abstract-021:before {
    content: "\FFF9D";
}
.game-icon-abstract-022:before {
    content: "\FFF9E";
}
.game-icon-abstract-023:before {
    content: "\FFF9F";
}
.game-icon-abstract-024:before {
    content: "\FFFA0";
}
.game-icon-abstract-025:before {
    content: "\FFFA1";
}
.game-icon-abstract-026:before {
    content: "\FFFA2";
}
.game-icon-abstract-027:before {
    content: "\FFFA3";
}
.game-icon-abstract-028:before {
    content: "\FFFA4";
}
.game-icon-abstract-029:before {
    content: "\FFFA5";
}
.game-icon-abstract-030:before {
    content: "\FFFA6";
}
.game-icon-abstract-031:before {
    content: "\FFFA7";
}
.game-icon-abstract-032:before {
    content: "\FFFA8";
}
.game-icon-abstract-033:before {
    content: "\FFFA9";
}
.game-icon-abstract-034:before {
    content: "\FFFAA";
}
.game-icon-abstract-035:before {
    content: "\FFFAB";
}
.game-icon-abstract-036:before {
    content: "\FFFAC";
}
.game-icon-abstract-037:before {
    content: "\FFFAD";
}
.game-icon-abstract-038:before {
    content: "\FFFAE";
}
.game-icon-abstract-039:before {
    content: "\FFFAF";
}
.game-icon-abstract-040:before {
    content: "\FFFB0";
}
.game-icon-abstract-041:before {
    content: "\FFFB1";
}
.game-icon-abstract-042:before {
    content: "\FFFB2";
}
.game-icon-abstract-043:before {
    content: "\FFFB3";
}
.game-icon-abstract-044:before {
    content: "\FFFB4";
}
.game-icon-abstract-045:before {
    content: "\FFFB5";
}
.game-icon-abstract-046:before {
    content: "\FFFB6";
}
.game-icon-abstract-047:before {
    content: "\FFFB7";
}
.game-icon-abstract-048:before {
    content: "\FFFB8";
}
.game-icon-abstract-049:before {
    content: "\FFFB9";
}
.game-icon-abstract-050:before {
    content: "\FFFBA";
}
.game-icon-abstract-051:before {
    content: "\FFFBB";
}
.game-icon-abstract-052:before {
    content: "\FFFBC";
}
.game-icon-abstract-053:before {
    content: "\FFFBD";
}
.game-icon-abstract-054:before {
    content: "\FFFBE";
}
.game-icon-abstract-055:before {
    content: "\FFFBF";
}
.game-icon-abstract-056:before {
    content: "\FFFC0";
}
.game-icon-abstract-057:before {
    content: "\FFFC1";
}
.game-icon-abstract-058:before {
    content: "\FFFC2";
}
.game-icon-abstract-059:before {
    content: "\FFFC3";
}
.game-icon-abstract-060:before {
    content: "\FFFC4";
}
.game-icon-abstract-061:before {
    content: "\FFFC5";
}
.game-icon-abstract-062:before {
    content: "\FFFC6";
}
.game-icon-abstract-063:before {
    content: "\FFFC7";
}
.game-icon-abstract-064:before {
    content: "\FFFC8";
}
.game-icon-abstract-065:before {
    content: "\FFFC9";
}
.game-icon-abstract-066:before {
    content: "\FFFCA";
}
.game-icon-abstract-067:before {
    content: "\FFFCB";
}
.game-icon-abstract-068:before {
    content: "\FFFCC";
}
.game-icon-abstract-069:before {
    content: "\FFFCD";
}
.game-icon-abstract-070:before {
    content: "\FFFCE";
}
.game-icon-abstract-071:before {
    content: "\FFFCF";
}
.game-icon-abstract-072:before {
    content: "\FFFD0";
}
.game-icon-abstract-073:before {
    content: "\FFFD1";
}
.game-icon-abstract-074:before {
    content: "\FFFD2";
}
.game-icon-abstract-075:before {
    content: "\FFFD3";
}
.game-icon-abstract-076:before {
    content: "\FFFD4";
}
.game-icon-abstract-077:before {
    content: "\FFFD5";
}
.game-icon-abstract-078:before {
    content: "\FFFD6";
}
.game-icon-abstract-079:before {
    content: "\FFFD7";
}
.game-icon-abstract-080:before {
    content: "\FFFD8";
}
.game-icon-abstract-081:before {
    content: "\FFFD9";
}
.game-icon-abstract-082:before {
    content: "\FFFDA";
}
.game-icon-abstract-083:before {
    content: "\FFFDB";
}
.game-icon-abstract-084:before {
    content: "\FFFDC";
}
.game-icon-abstract-085:before {
    content: "\FFFDD";
}
.game-icon-abstract-086:before {
    content: "\FFFDE";
}
.game-icon-abstract-087:before {
    content: "\FFFDF";
}
.game-icon-abstract-088:before {
    content: "\FFFE0";
}
.game-icon-abstract-089:before {
    content: "\FFFE1";
}
.game-icon-abstract-090:before {
    content: "\FFFE2";
}
.game-icon-abstract-091:before {
    content: "\FFFE3";
}
.game-icon-abstract-092:before {
    content: "\FFFE4";
}
.game-icon-abstract-093:before {
    content: "\FFFE5";
}
.game-icon-abstract-094:before {
    content: "\FFFE6";
}
.game-icon-abstract-095:before {
    content: "\FFFE7";
}
.game-icon-abstract-096:before {
    content: "\FFFE8";
}
.game-icon-abstract-097:before {
    content: "\FFFE9";
}
.game-icon-abstract-098:before {
    content: "\FFFEA";
}
.game-icon-abstract-099:before {
    content: "\FFFEB";
}
.game-icon-abstract-100:before {
    content: "\FFFEC";
}
.game-icon-abstract-101:before {
    content: "\FFFED";
}
.game-icon-abstract-102:before {
    content: "\FFFEE";
}
.game-icon-abstract-103:before {
    content: "\FFFEF";
}
.game-icon-abstract-104:before {
    content: "\FFFF0";
}
.game-icon-abstract-105:before {
    content: "\FFFF1";
}
.game-icon-abstract-106:before {
    content: "\FFFF2";
}
.game-icon-abstract-107:before {
    content: "\FFFF3";
}
.game-icon-abstract-108:before {
    content: "\FFFF4";
}
.game-icon-abstract-109:before {
    content: "\FFFF5";
}
.game-icon-abstract-110:before {
    content: "\FFFF6";
}
.game-icon-abstract-111:before {
    content: "\FFFF7";
}
.game-icon-abstract-112:before {
    content: "\FFFF8";
}
.game-icon-abstract-113:before {
    content: "\FFFF9";
}
.game-icon-abstract-114:before {
    content: "\FFFFA";
}
.game-icon-abstract-115:before {
    content: "\FFFFB";
}
.game-icon-abstract-116:before {
    content: "\FFFFC";
}
.game-icon-abstract-117:before {
    content: "\FFFFD";
}
.game-icon-abstract-118:before {
    content: "\FFFFE";
}
.game-icon-abstract-119:before {
    content: "\FFFFF";
}
.game-icon-abstract-120:before {
    content: "\100000";
}
.game-icon-abstract-121:before {
    content: "\100001";
}
.game-icon-black-book:before {
    content: "\100002";
}
.game-icon-chain-lightning:before {
    content: "\100003";
}
.game-icon-chain-mail:before {
    content: "\100004";
}
.game-icon-gold-bar:before {
    content: "\100005";
}
.game-icon-orb-wand:before {
    content: "\100006";
}
.game-icon-round-shield:before {
    content: "\100007";
}
.game-icon-white-book:before {
    content: "\100008";
}
.game-icon-skull-with-syringe:before {
    content: "\100009";
}
.game-icon-violin:before {
    content: "\10000A";
}
.game-icon-heart-minus:before {
    content: "\10000B";
}
.game-icon-heart-plus:before {
    content: "\10000C";
}