@use '@angular/material' as mat;

@mixin item-theme($theme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .sticky-category-list {
        background-color: mat.get-color-from-palette($background, background);
    }
    .main-row:hover {
        background-color: mat.get-color-from-palette($background, hover);
    }
}
