@use '@angular/material' as mat;

// Plus imports for other components in your app.
@import './app/shared/_value-editor-theme';
@import './app/shared/_common-nav-theme';
@import './app/shared/_full-screen-dialog-theme';
@import './app/_app.theme';
@import './app/character/character.theme';
@import './app/group/fighter-panel.theme';
@import './app/useful-data/useful-data-theme';
@import './app/item-template/item-template.theme';
@import './app/group/group-therme';
@import './app/map/map-theme';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.all-component-typographies();
@include mat.core();

// Define the default theme
$nhbk-primary: mat.define-palette(mat.$indigo-palette);
$nhbk-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$nhbk-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$nhbk-theme: mat.define-light-theme((
    color: (
        primary: $nhbk-primary,
        accent: $nhbk-accent,
        warn: $nhbk-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($nhbk-theme);
@include global-theme($nhbk-theme);

@mixin custom-components-theme($theme) {
    @include value-selector-theme($theme);
    @include character-theme($theme);
    @include useful-data-theme($theme);
    @include item-theme($theme);
    @include group-theme($theme);
    @include fighter-panel-theme($theme);
    @include map-theme($theme);
    @include common-nav-theme($theme);
    @include full-screen-dialog-theme($theme);
}

@include custom-components-theme($nhbk-theme);

@mixin add-theme($isDark, $primary, $accent, $warn, $name) {
    $theme-primary: mat.define-palette($primary);
    $theme-accent: mat.define-palette($accent, A200, A100, A400);
    $theme-warn: mat.define-palette($warn);

    @if ($isDark) {
        $dark-theme: mat.define-dark-theme((
            color: (
                primary: $theme-primary,
                accent: $theme-accent,
                warn: $theme-warn
            ),
            typography: mat.define-typography-config(),
            density: 0,
        ));

        .#{$name} {
            @include mat.all-component-colors($dark-theme);
            @include global-theme($dark-theme);
            @include custom-components-theme($dark-theme);
        }
    } @else {

        $light-theme: mat.define-light-theme((
            color: (
                primary: $theme-primary,
                accent: $theme-accent,
                warn: $theme-warn
            ),
            typography: mat.define-typography-config(),
            density: 0,
        ));

        .#{$name} {
            @include mat.all-component-colors($light-theme);
            @include global-theme($light-theme);
            @include custom-components-theme($light-theme);
        }
    }
}

@include add-theme(true, mat.$blue-grey-palette, mat.$amber-palette, mat.$deep-orange-palette, 'nhbk-dark-theme');
@include add-theme(true, mat.$green-palette, mat.$teal-palette, mat.$deep-orange-palette, 'nhbk-dark2-theme');

@include add-theme(false, mat.$teal-palette, mat.$orange-palette, mat.$deep-orange-palette, 'nhbk-light2-theme');
