@use '@angular/material' as mat;

@mixin full-screen-dialog-theme($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .nhbk-full-screen-dialog {
        color: mat.get-color-from-palette($foreground, 'text');
        .mat-mdc-dialog-container .mdc-dialog__surface {
            background-color: mat.get-color-from-palette($background, 'background');
        }
    }
}
