@use '@angular/material' as mat;

@mixin common-nav-theme($theme) {
    $background: map-get($theme, background);

    common-nav {
        .gm-toggle {
            &:hover, &:focus {
                background: mat.get-color-from-palette($background, 'hover');
            }
        }
    }
}
