@use '@angular/material' as mat;

@mixin fighter-panel-theme($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .fighter-card {
        background: mat.get-color-from-palette($background, card);
        color: mat.get-color-from-palette($foreground, text);
    }
}
