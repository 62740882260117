@use '@angular/material' as mat;

$config: mat.define-typography-config();

@mixin map-theme($theme) {
    $background: map-get($theme, background);

    .map-marker-tooltip {
        font-size: mat.font-size($config, body-1);
        font-family: mat.font-family($config);
    }

    .map-link {
        .name {
            &:hover, &:focus {
                background: mat.get-color-from-palette($background, 'hover');
            }
        }
    }
}
